<template>
<div class="recruited-state">
    <div class="recruited-btns">
        <button type="button" class="button-default pendingBtn" :class="{ active: this.jobApplication.Status === 2, enabled: this.isRecruitmentStepLocked }" @click="changeStatus('pending')" @mouseover="previewStateChange('pending')" @mouseleave="refreshState"><v-icon name="hourglass-half"/></button>
        <button type="button" class="button-default recruitedBtn" :class="{ active: this.jobApplication.Status === 3, enabled: !this.isRecruitmentStepLocked }" @click="changeStatus('recruited')" @mouseover="previewStateChange('recruited')" @mouseleave="refreshState"><v-icon name="check"/></button>
        <button type="button" class="button-default notRecruitedBtn" :class="{ active: this.jobApplication.Status === 4, enabled: !this.isRecruitmentStepLocked }" @click="changeStatus('notRecruited')" @mouseover="previewStateChange('notRecruited')" @mouseleave="refreshState"><v-icon name="times"/></button>
    </div>
    <div id="recruited-label" :class="{ recruited: this.jobApplication.Status === 3, notRecruited: this.jobApplication.Status === 4 }">{{ recruitementStateLabel}}</div>
    <transition name="modal-fade">
        <ModalConfirmation v-show="isModalCloseApplicationConfirmVisible"
        @close="closeModal('closeApplication')" @confirm="updateStatus"
        :title="'Voulez-vous vraiment cloturer cette candidature ?'"
        :message="confirmCloseApplicationMessage"
        :width="420" :height="230" />
    </transition>
    <transition name="modal-fade">
        <ModalConfirmation v-show="isModalReopenApplicationConfirmVisible"
        @close="closeModal('reopenApplication')" @confirm="updateStatus"
        :title="'Voulez-vous vraiment réouvrir cette candidature ?'"
        :message="confirmReopenApplicationMessage"
        :width="420" :height="230" />
    </transition>
</div>
</template>
<script>
import ModalConfirmation from '@/components/modals/ModalConfirmation'
import JobApplication from '@/models/JobApplication'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'RecruitmentStatus',
  data () {
    return {
      recruitementStateLabel: '',
      currentRecruitementState: 0,
      isModalCloseApplicationConfirmVisible: false,
      isModalReopenApplicationConfirmVisible: false,
      currentChangeStatusAction: ''
    }
  },
  props: {
    jobApplication: JobApplication
  },
  components: {
    ModalConfirmation
  },
  watch: {
    'jobApplication.Status' () {
      this.changeStatusLabel(this.jobApplication.Status)
    }
  },
  computed: {
    ...mapGetters([
      'isRecruitmentStepLocked'
    ]),
    confirmCloseApplicationMessage () {
      return 'Si vous cloturez la candidature et que vous la voulez la rouvrir plus tard, vous perdrez l\'étape de recrutement en cours, les tags associés, ainsi que l\'historique des commentaires et des activités.'
    },
    confirmReopenApplicationMessage () {
      return 'Si vous réouvrez la candidature, vous perdrez l\'étape de recrutement, les tags associés, ainsi que l\'historique des commentaires et des activités.'
    }
  },
  methods: {
    showModal (type) {
      if (type === 'closeApplication') {
        this.isModalCloseApplicationConfirmVisible = true
      } else if (type === 'reopenApplication') {
        this.isModalReopenApplicationConfirmVisible = true
      }
    },
    closeModal (type) {
      if (type === 'closeApplication') {
        this.isModalCloseApplicationConfirmVisible = false
      } else if (type === 'reopenApplication') {
        this.isModalReopenApplicationConfirmVisible = false
      }
    },
    updateStatus () {
      if (this.currentChangeStatusAction !== undefined) {
        this.$emit('updateStatus', this.currentChangeStatusAction)
        this.closeModal('closeApplication')
        this.closeModal('reopenApplication')
        this.currentChangeStatusAction = ''
      }
    },
    changeStatus (action) {
      if ((action === 'pending' && this.jobApplication.Status !== 2) || (action !== 'pending' && !this.isRecruitmentStepLocked)) {
        this.currentChangeStatusAction = action
        if (action === 'pending') {
          this.showModal('reopenApplication')
        } else {
          this.showModal('closeApplication')
        }
      }
    },
    previewStateChange (status) {
      if (status === 'pending' && this.jobApplication.Status !== 2) {
        this.recruitementStateLabel = 'Rouvrir'
      } else if (status === 'recruited' && this.jobApplication.Status !== 3 && !this.isRecruitmentStepLocked) {
        this.recruitementStateLabel = 'Recruter'
      } else if (status === 'notRecruited' && this.jobApplication.Status !== 4 && !this.isRecruitmentStepLocked) {
        this.recruitementStateLabel = 'Rejeter'
      }
    },
    changeStatusLabel (status) {
      if (status === 1) {
        this.recruitementStateLabel = 'Rouvert'
      } else if (status === 2) {
        this.recruitementStateLabel = 'Ouvert'
      } else if (status === 3) {
        this.recruitementStateLabel = 'Recruté'
      } else if (status === 4) {
        this.recruitementStateLabel = 'Non Recruté'
      }
    },
    refreshState () {
      this.changeStatusLabel(this.jobApplication.Status)
    }
  },
  mounted () {
    this.refreshState()
  }
}
</script>
