<template>
  <div>
      <v-icon v-if="jobApplication.IsMultiApplicationDuplicate" @click.stop="showModal(jobApplication)" name="exclamation-triangle"/>
      <span v-else-if="jobApplication.DuplicatesCount > 0" class="strong">
        {{ jobApplication.DuplicatesCount }}
      </span>
  </div>
</template>

<script>
import EventBus from '@/types/EventBus'
import { JobApplication } from '@/models/JobApplication'

export default {
  name: 'DuplicatesColumnInfos',
  props: {
    jobApplication: { type: JobApplication, required: true }
  },
  methods: {
    showModal (clickedJobApplication) {
      EventBus.$emit('showDeduplicateModal', clickedJobApplication)
    }
  }
}
</script>
