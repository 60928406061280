export class DisplaySettings {
    public IsFiltersPanelDisplayed: boolean
    public IsJobApplicationSidebarVisible: boolean
    public IsRecruitmentStepLocked: boolean
    public reloadJobApplicationsCounter: boolean
    public IsStatisticsFiltersPanelDisplayed: boolean

    constructor () {
      this.IsFiltersPanelDisplayed = false
      this.IsJobApplicationSidebarVisible = false
      this.IsRecruitmentStepLocked = false
      this.reloadJobApplicationsCounter = true
      this.IsStatisticsFiltersPanelDisplayed = false
    }
}
