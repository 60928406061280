export class MessageModel {
    public Id: number
    public LegacyCorrelationId: number
    public Title: string
    public Sender: string
    public Subject: string
    public Body: string
    public Type: number

    constructor () {
      this.Id = -1
      this.LegacyCorrelationId = 0
      this.Title = ''
      this.Sender = ''
      this.Subject = ''
      this.Body = ''
      this.Type = 0
    }

    initialize (messageModelResult: any) {
      this.Id = messageModelResult.id
      this.LegacyCorrelationId = messageModelResult.legacyCorrelationId
      this.Title = messageModelResult.title
      this.Sender = messageModelResult.sender
      this.Subject = messageModelResult.subject
      this.Body = messageModelResult.body
      this.Type = messageModelResult.type
      return this
    }
}
