<template>
  <div id="global_search">
          <v-icon name="search" class="search-icon"/>
          <input type="text" class="input-text-default" v-model="searchQuery" @input="debounceInput" placeholder="Entrez votre recherche (ex. : référence, localisation, ...)" required/>
          <v-icon name="times" class="deleteSearchIcon" :class="{ hidden: this.searchQuery.length === 0 }" @click="clearSearchQuery"/>
        </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { JobApplicationFilter } from '@/models/JobApplicationFilter'

export default {
  name: 'JobApplicationSearch',
  data () {
    return {
      searchQuery: '',
      debounceTimeout: null
    }
  },
  computed: {
    ...mapGetters(['filters'])
  },
  methods: {
    ...mapActions(['filterJobApplications']),
    debounceInput () { // TODO : mécanisme de debounce à mettre dans un mixin
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.filterOnSearchQuery()
      }, 500)
    },
    filterOnSearchQuery () {
      const changedFilters = this.filters
      changedFilters.Global = this.searchQuery
      this.filterJobApplications(changedFilters)
    },
    clearSearchQuery () {
      this.searchQuery = ''
      this.filterOnSearchQuery()
    }
  },
  watch: {
    filters (value) {
      this.searchQuery = value.Global
    }
  }
}
</script>
