<template>
  <section class="modal modal-edit-comment">
    <div class="modal-content">
      <v-icon class="icon-close" name="times-circle" @click="close"/>
      <h1>{{ this.title }}</h1>
      <CommentsInput :initialComment="comment.Content" @commentInputButtonWasClicked="handleSubmitEditedComment" @commentInput="handleInput"/>
      <p v-if="isCommentEmpty" class="error-message">Le commentaire ne peut pas être vide !</p>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { JobApplicationComment } from '@/models/JobApplicationComment'
import CommentsInput from '@/components/jobApplication/CommentsInput'
export default {
  name: 'ModalEditComment',
  props: {
    title: String,
    comment: JobApplicationComment
  },
  data () {
    return {
      isCommentEmpty: false
    }
  },
  components: {
    CommentsInput
  },
  methods: {
    ...mapActions([
      'modifyComment'
    ]),
    close () {
      this.$emit('close')
    },
    handleInput (newComment) {
      // Mettre à jour isCommentEmpty lorsque l'utilisateur commence à taper
      this.isCommentEmpty = newComment === ''
    },
    handleSubmitEditedComment (newComment, event) {
      if ((event && event.key === 'Enter' && !event.shiftKey) || (event && event.type === 'click')) {
        if (newComment.trim() !== '') {
          this.comment.Content = newComment
          this.modifyComment(this.comment)
          this.close()
        } else {
        // Afficher le message d'erreur
          this.isCommentEmpty = true
        }
      }
    }
  }
}
</script>
