<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content" :style="this.modalSize">
      <h1>{{ this.title }}</h1>
      <p v-if="this.message">{{ this.message }}</p>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @mousedown="deleteStakeholder">Supprimer</button>
      </div>
    </div>
  </section>
</template>

<script>
import { Stakeholder } from '@/models/Stakeholder'
import { mapActions } from 'vuex'
export default {
  name: 'ModalDeleteStakeholder',
  props: {
    width: Number,
    height: Number,
    stakeholder: Stakeholder
  },
  data () {
    return {
      message: 'Voulez-vous vraiment supprimer cet opérationnel ?',
      title: 'Supprimer un opérationnel'
    }
  },
  computed: {
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  methods: {
    ...mapActions([
      'deleteSelectedStakeholder'
    ]),
    deleteStakeholder () {
      this.deleteSelectedStakeholder(this.stakeholder.Id)
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
