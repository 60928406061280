<template>
  <footer class="footer">
    <img class="logo" src="@/assets/images/logo-talentplug.svg" alt="Talentplug">
    <span>&#169; {{new Date().getFullYear()}} - Tous droits réservés -</span>
    <a :href="generalSubscriptionConditionsUrl" target="_blank">Conditions générales d'abonnement</a>
    <span class="version">v{{ this.version }}</span>
  </footer>
</template>

<script>

export default {
  name: 'MasterFooter',
  data () {
    return {
      version: this.$appSettings.version,
      broadcastPlatformUrl: this.$appSettings.broadcastPlatformUrl
    }
  },
  computed: {
    generalSubscriptionConditionsUrl () {
      return this.broadcastPlatformUrl + '/recruiter/admin/about.aspx'
    }
  }
}
</script>
