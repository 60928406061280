import _Vue from 'vue'

function parse (value: string, defaultValue: any) {
  if (typeof value === 'undefined') {
    return defaultValue
  }
  switch (typeof defaultValue) {
    case 'boolean' :
      return !!JSON.parse(value)
    case 'number' :
      return JSON.parse(value)
    default :
      return value
  }
}

const appSettings = {
  jamApiBaseUrl: parse(process.env.VUE_APP_JAM_API_ROOT, 'https://localhost:44300/api'),
  emailServiceApiBaseUrl: parse(process.env.VUE_APP_EMAIL_API_ROOT, 'https://localhost:44366/api'),
  custApiBaseUrl: parse(process.env.VUE_APP_CUST_API_ROOT, 'https://localhost:44390/api'),
  jobApiBaseUrl: parse(process.env.VUE_APP_JOB_API_ROOT, 'https://localhost:44381/api'),
  talentApplyApiBaseUrl: parse(process.env.VUE_APP_TALENTAPPLY_API_ROOT, 'https://localhost:10441'),
  broadcastPlatformUrl: parse(process.env.VUE_APP_BROADCAST_PLATFORM_URL, 'https://preprod-app.mytalentplug.com'),
  locale: parse(process.env.VUE_APP_LOCALE, 'fr-FR'),
  version: parse(process.env.VUE_APP_VERSION, '21.01.0')
}

const appSettingsPlugin = {
  install (Vue: typeof _Vue, options?: any): void {
    Vue.appSettings = appSettings
    Vue.prototype.$appSettings = appSettings
  }
}

export {
  appSettings
}

export default appSettingsPlugin
