<template>
  <section class="modal modal-activities" @click.self="close">
    <div class="modal-content">
      <v-icon class="icon-close" name="times-circle" @click="close"/>
      <h1>{{ this.title }}</h1>
      <div class="content">
        <ul class="activities-list-scrollable">
          <li v-for="activity in displayedActivities" :key="activity.Id">
            <div class="activity-info">{{ activity.UserName }}, le {{ activity.Date | moment('LL') }} à {{ activity.Date | moment('LT') }}</div>
            <div>{{ activity.Name }}</div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalActivities',
  props: {
    title: String
  },
  computed: {
    ...mapGetters([
      'currentJobApplication'
    ]),
    displayedActivities () {
      const activities = this.currentJobApplication.Activities.slice()
      activities.sort(function (a, b) {
        return new Date(a.Date) - new Date(b.Date)
      })
      activities.reverse()
      return activities
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
