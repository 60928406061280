<template>
  <main class="masterBody"> <!-- TODO : utile de faire un master body ? La balise main ne devrait pas être reportée comme balise racine de chaque composant correspondant aux vue ? -->
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'MasterBody'
}
</script>
