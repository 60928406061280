import Vue from 'vue'
import Axios from 'axios'
import appSettings from '@/plugins/appSettings'
import { JobApplication } from '@/models/JobApplication'
import { RecruitmentStep } from '@/models/RecruitmentStep'

Vue.use(appSettings)
const ROUTE = `${Vue.appSettings.jamApiBaseUrl}/RecruitmentProcess`

export default {
  open (id: any) {
    return Axios.put(`${ROUTE}/Open/${id}`)
      .then(response => new JobApplication().initialize(response.data.jobApplication))
  },
  reopen (id: any) {
    return Axios.put(`${ROUTE}/Reopen/${id}`)
  },
  hire (id: any) {
    return Axios.put(`${ROUTE}/Hire/${id}`)
  },
  reject (id: any) {
    return Axios.put(`${ROUTE}/Reject/${id}`)
  },
  moveToNextRecruitmentStep (id: any, step: RecruitmentStep) {
    const params = {
      Id: step.Id,
      Name: step.Name,
      Order: step.Order
    }
    return Axios.put(`${ROUTE}/MoveToNextRecruitmentStep/${id}`, params)
  }
}
