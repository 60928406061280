<template>
  <div class="jobApplicationQuickView">
    <v-icon class="eye" name="eye" />
    <div class="quickview">
      <div class="quickview-header">
        <span :class="{ 'recruitment-state-in-progress': this.jobApplicationIsInProgress, 'recruitment-state-accepted': this.jobApplicationIsHired, 'recruitment-state-rejected': this.jobApplicationIsRejected }">{{ recruitementStateLabel }}</span>
        <div class="job-title">{{ jobApplication.JobOffer.JobTitle }}</div>
      </div>
      <div class="quickview-content">
        <div class="tags-section">
          <span class="tags-header">Tags :</span> {{ jobApplication.Tags.slice(0, 10).join(", ") }} <br/>
        </div>
        <div class="comments-section">
          <div class="comments-header">Commentaires :</div>
          <CommentsList :comments="jobApplication.Comments" :commentsToDisplay="5" :showCommentActions="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentsList from '@/components/jobApplication/CommentsList.vue'
import { JobApplication } from '@/models/JobApplication'

export default {
  name: 'JobApplicationQuickView',
  props: {
    jobApplication: { type: JobApplication, required: true }
  },
  components: {
    CommentsList
  },
  computed: {
    jobApplicationIsInProgress () {
      return this.jobApplication.Status === 0 || this.jobApplication.Status === 1 || this.jobApplication.Status === 2
    },
    jobApplicationIsHired () {
      return this.jobApplication.Status === 3
    },
    jobApplicationIsRejected () {
      return this.jobApplication.Status === 4 || this.jobApplication.Status === 5
    },
    recruitementStateLabel () {
      if (this.jobApplicationIsInProgress) {
        return 'Candidature en cours'
      } else if (this.jobApplicationIsHired) {
        return 'Candidature acceptée'
      } else if (this.jobApplicationIsRejected) {
        return 'Candidature rejetée'
      }
      return 'Statut inconnu'
    }
  }
}
</script>
