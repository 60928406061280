<template>
  <div class="inputAutocomplete" :class="className">
    <input class="input-text-default" type="text"
      v-model="inputValue"
      @keyup="filterAutoCompleteValues"
      @change="filterAutoCompleteValues"
      @focusout="clear"
      @focusin="filterAutoCompleteValues"
      :placeholder="placeholder" />
    <button type="button" class="button-filter-small" @mousedown="selectValue"><v-icon name="plus-circle" /></button>
    <div class="autocomplete-items-container">
      <div class="autocomplete-items" v-if="this.autocompleteData.length > 0">
        <div v-for="(item, index) in autocompleteData" :key="index">
          <p @mousedown="selectValue(event, item)">{{ item }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputAutocomplete',
  props: {
    autoCompleteValues: Array,
    placeholder: String,
    className: String,
    minimumCharsBeforeAutoComplete: { type: Number, default: 2 }
  },
  data () {
    return {
      inputValue: '',
      autocompleteData: []
    }
  },
  methods: {
    // TODO : afficher un message non trouvé si pas de match ?
    filterAutoCompleteValues (event) {
      this.autocompleteData = []
      if (this.autoCompleteValues && event.key !== 'Enter' && String(this.inputValue.trim()).length >= this.minimumCharsBeforeAutoComplete) {
        this.autocompleteData = this.autoCompleteValues.filter((value) => String(value).trim().toLowerCase().includes(this.inputValue.trim().toLowerCase()))
      }
      if (event.key === 'Enter') {
        this.selectValue(event)
      }
      if (event.key === 'Escape') {
        this.clear()
        this.close(event)
      }
    },
    clear () {
      this.autocompleteData = [] // TODO : necessaire ?
      this.inputValue = ''
    },
    selectValue (event, item) {
      if (item) {
        this.inputValue = item
      }
      this.$emit('valueSelected', this.inputValue.trim())
      this.clear()
      this.close(event)
    },
    close (event) {
      if (event) {
        event.target.value = ''
        event.target.blur()
      }
    }
  }
}
</script>
