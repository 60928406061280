export class JobApplicationStatus {
    public Id: number
    public Name: string
    public Label: string

    constructor () {
      this.Id = -1
      this.Name = ''
      this.Label = ''
    }

    initialize (status: any) {
      this.Id = status.id
      this.Name = status.name
      this.Label = status.label
      return this
    }
}
