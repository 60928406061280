<template>
  <section id="job_application_recruitment_steps" class="panel">
    <h1>Étape de recrutement</h1>
    <div class="step-picker" :class="{ isLocked: this.isRecruitmentStepLocked }">
      <li v-for="step in recruitmentSteps" :key="step.Id"
      @click="updateRecruitmentStep(step)"
      :class="{ 'done': step.Id === currentRecruitmentStepId, 'todo': step.Id !== currentRecruitmentStepId }">
        <v-icon name="check-circle"/>
        {{ step.Name }}
      </li>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RecruitmentSteps',
  props: {
    currentRecruitmentStepId: String
  },
  methods: {
    updateRecruitmentStep (step) {
      if (!this.isRecruitmentStepLocked && step.Id !== this.currentRecruitmentStepId) {
        this.$emit('updateRecruitmentStep', step)
      }
    }
  },
  computed: {
    ...mapGetters([
      'recruitmentSteps',
      'isRecruitmentStepLocked'
    ])
  }
}
</script>
