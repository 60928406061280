<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import Layout from '@/layouts/Layout.vue'
import router from '@/router'
import Axios from 'axios'
import { loginPageUrlProd, loginPageUrlPprod, loginPageUrlLocal } from '@/mixins/constants'
import { mapActions } from 'vuex'

export default {
  components: {
    Layout
  },
  methods: {
    ...mapActions(['initialization'])
  },
  created () {
    this.initialization()
    Axios.interceptors.response.use(function (response) {
      // TODO : remplacer ces premières lignes de code par une configuration des entetes cache control dans la configuration nginx
      const lastFullRefreshDate = localStorage.getItem('lastFullRefreshDate')
      const todayDate = new Date()
      if (lastFullRefreshDate === null || todayDate.toDateString() !== lastFullRefreshDate) {
        localStorage.setItem('lastFullRefreshDate', todayDate.toDateString())
        window.location.reload()
      }

      return response
    }, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.response.status === 401) {
          // TODO: En faire un plugin pour la réutilisation
          if (window.location.href.includes('https://tam.mytalentplug.com')) {
            window.location.href = loginPageUrlProd
          } else if (window.location.href.includes('localhost')) {
            window.location.href = loginPageUrlLocal
          } else {
            window.location.href = loginPageUrlPprod
          }
        }
        if (err.response.status === 403) {
          router.push('Home')
        }
        throw err
      })
    })
  }
}
</script>
