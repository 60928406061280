export class TamUser {
    public Id: string
    public CompanyId: number
    public Login: string
    public Fullname: string
    public FirstName: string
    public LastName: string
    public Email: string
    public Roles: Array<string>

    constructor () {
      this.Id = ''
      this.CompanyId = -1
      this.Login = ''
      this.Fullname = ''
      this.FirstName = ''
      this.LastName = ''
      this.Email = ''
      this.Roles = []
    }

    initialize (jsonJwtPayload: any) {
      this.Id = jsonJwtPayload.id
      this.CompanyId = jsonJwtPayload.CompanyId
      this.Login = jsonJwtPayload.nickname
      this.Fullname = jsonJwtPayload.name
      this.FirstName = jsonJwtPayload.given_name
      this.LastName = jsonJwtPayload.family_name
      this.Email = jsonJwtPayload.email
      this.Roles = jsonJwtPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      return this
    }
}
