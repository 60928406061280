export class JobApplicationRange {
  public MinimumBound: number
  public MaximumBound: number

  constructor () {
    this.MinimumBound = 0
    this.MaximumBound = 100
  }

  initialize (min: number, max: number) {
    this.MinimumBound = min
    this.MaximumBound = max
    return this
  }
}
