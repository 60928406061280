<template>
  <section class="modal modal-deduplicate-applications">
    <!-- Nominal case -->
    <div class="modal-content" v-if="!notADuplicateError && !singleApplicationIsSelectedError">
      <h1>{{ title }}</h1>
      <div class="loader" v-if="isFetchingMultiApplicationDuplicates">
        <v-icon name="sync-alt" class="spinner"/>
      </div>
      <div class="content" v-else>
        <p class="desc">Veuillez sélectionner la candidature à garder, les autres seront supprimées</p>
        <table class="table">
          <tbody>
            <tr v-for="application in displayedJobApplications" :key="application.id">
              <td><input type="radio" :id="application.id" :value="application.id" v-model="choosenApplicationId"></td>
              <td>{{ application.applicantName }}</td>
              <td>{{ application.date }}</td>
              <td>{{ application.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @click="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @click="deduplicateJobApplications">Appliquer</button>
      </div>
    </div>

    <!-- JobApplication is no longer a duplicate -->
    <div class="modal-content" v-else-if="notADuplicateError">
      <h1>{{ errorTitle }}</h1>
      <div class="content">
        <p class="desc">{{ notADuplicateErrorMessage }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @click="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @click="refreshJobApplications">Rafraîchir</button>
      </div>
    </div>

    <!-- Only one jobApplication selected -->
    <div class="modal-content" v-else-if="singleApplicationIsSelectedError">
      <h1>{{ errorTitle }}</h1>
      <div class="content">
        <p class="desc">{{ singleApplicationIsSelectedErrorMessage }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @click="close">OK</button>
      </div>
    </div>
  </section>
</template>
<script>

import { JobApplication } from '@/models/JobApplication'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalDeduplicateApplications',
  components: {

  },
  data () {
    return {
      isMultiApplicationDuplicateProcess: false,
      errorTitle: 'Sélection incorrecte',
      notADuplicateError: false,
      notADuplicateErrorMessage: 'Suite à l\'une de vos actions, cette candidature n\'est plus en doublon. Pour ne plus afficher l\'icône de doublons sur cette ligne, vous pouvez rafraîchir la page.',
      singleApplicationIsSelectedError: false,
      singleApplicationIsSelectedErrorMessage: 'Vous devez sélectionner au moins deux candidatures à dédoublonner.',
      applicationsToDeduplicate: [],
      choosenApplicationId: null,
      isFetchingMultiApplicationDuplicates: false
    }
  },
  props: {
    title: String,
    clickedJobApplication: JobApplication
  },
  computed: {
    ...mapGetters([
      'jobApplications',
      'selectedJobApplicationsIds',
      'statuses',
      'recruitmentSteps'
    ]),
    displayedJobApplications () {
      return this.applicationsToDeduplicate.map(ja => {
        return {
          id: ja.Id,
          date: this.$moment(ja.Date).format('DD/MM/YYYY'),
          applicantName: ja.Applicant?.Fullname || ja.Fullname,
          status: ja.Status === 1 || ja.Status === 2 ? this.recruitmentSteps.find(s => s.Id === ja.RecruitmentStepId).Name : this.statuses.find(s => s.Id === ja.Status).Label
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'loadJobApplications',
      'getMultiApplicationDuplicates',
      'deleteJobApplications',
      'addToJobApplicationDuplicatesCount'
    ]),
    deduplicateJobApplications () {
      if (this.choosenApplicationId === null) {
        return
      }
      const jobApplicationsToDelete = this.applicationsToDeduplicate.filter(a => a.Id !== this.choosenApplicationId)
      const idsToDelete = jobApplicationsToDelete.map(ja => ja.Id)

      this.deleteJobApplications(idsToDelete).then(async () => {
        const totalDuplicatesCounter = jobApplicationsToDelete.length + jobApplicationsToDelete.reduce((accumulator, ja) => accumulator + ja.DuplicatesCount, 0)
        this.addToJobApplicationDuplicatesCount({ jobApplicationId: this.choosenApplicationId, nbToAdd: totalDuplicatesCounter })

        const choosenJobApplication = this.jobApplications.find(ja => ja.Id === this.choosenApplicationId)
        if (choosenJobApplication === null) {
          return
        }
        choosenJobApplication.DuplicatesCount += totalDuplicatesCounter

        if (this.isMultiApplicationDuplicateProcess) {
          choosenJobApplication.IsMultiApplicationDuplicate = false
        }
      }).finally(() => {
        this.close()
      })
    },
    refreshJobApplications () {
      this.loadJobApplications()
      this.close()
    },
    close () {
      this.$emit('close')
    }
  },
  async beforeMount () {
    // Coming from sidebar
    if (this.clickedJobApplication === null) {
      this.isMultiApplicationDuplicateProcess = false
      if (!this.selectedJobApplicationsIds || this.selectedJobApplicationsIds.length <= 1) {
        this.singleApplicationIsSelectedError = true
        return
      }

      this.selectedJobApplicationsIds.forEach(id => {
        this.applicationsToDeduplicate.push(this.jobApplications.find(ja => ja.Id === id))
      })
      this.choosenApplicationId = this.applicationsToDeduplicate[0].Id
      return
    }

    // Coming from multi application duplicate button
    this.isMultiApplicationDuplicateProcess = true
    this.choosenApplicationId = this.clickedJobApplication.Id
    this.isFetchingMultiApplicationDuplicates = true
    this.applicationsToDeduplicate = await this.getMultiApplicationDuplicates({ jobOfferId: this.clickedJobApplication.JobOffer.Id, applicantEmail: this.clickedJobApplication.Applicant.Email })
    this.isFetchingMultiApplicationDuplicates = false

    if (!this.applicationsToDeduplicate || this.applicationsToDeduplicate.length <= 1) {
      this.notADuplicateError = true
    }
  }
}
</script>
