<template>
  <section id="job_applications_view">
    <JobApplicationsActionBar />
    <JobApplicationFilter />
    <div id="job_applications_container">
      <Sidebar ref="jobApplicationsSidebar"
        :elementToPushId="'job_applications_content'"
        :width="180"
        :isCollapsedOnStart="false"
        :isHiddenOnStart="!isJobApplicationSidebarVisible"
        :links="sideBarLinks"
        :startingStickyElementIds="['master_header', 'job_applications_action_bar', 'advanced_filter_panel', 'selected_filters_bar']"
      />
      <div id="job_applications_content">
        <v-icon v-if="isJobApplicationsLoading" name="sync-alt" class="spinner"/>
        <div v-else-if="jobApplications.length">
          <JobApplicationListView/>
        </div>
        <div v-else class="no-result">Pas de résultat</div>
      </div>
    </div>
    <FloatingActionButton
      iconName="arrow-up"
      bgColor="#071521"
      :hidden="!showFloatingButton"
      @onFloatingActionButtonClick="scrollToTop"
    ></FloatingActionButton>
  </section>
</template>
<script>
import JobApplicationsActionBar from '@/components/jobApplications/JobApplicationsActionBar.vue'
import JobApplicationFilter from '@/components/jobApplications/filters/JobApplicationFilter.vue'
import Sidebar from '@/components/global/Sidebar.vue'
import JobApplicationListView from '@/views/partials/JobApplicationListView.vue'
import FloatingActionButton from '@/components/_generic/FloatingActionButton.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JobApplications',
  data () {
    return {
      isJobApplicationsLoading: true,
      showFloatingButton: false,
      sideBarLinks: [
        { id: 1, iconName: 'envelope', text: 'Contacter', method: 'sendEmail' }, // TODO : initialiser cet objet dans un obj ts de manière externe, en utilisant des constantes
        { id: 2, iconName: 'edit', text: 'Modifier', method: 'edit' },
        { id: 3, iconName: 'share', text: 'Transférer', method: 'transfer' },
        { id: 4, iconName: 'trash-alt', text: 'Supprimer', method: 'delete' },
        { id: 5, iconName: 'file-export', text: 'Exporter', method: 'export' },
        { id: 6, iconName: 'tag', text: 'Tags', method: 'multiJobApplicationTag' },
        { id: 7, iconName: 'user-times', text: 'Doublons', method: 'deduplicateApplications' }]
    }
  },
  computed: {
    ...mapGetters([
      'isJobApplicationSidebarVisible',
      'jobApplications',
      'filters',
      'jobApplicationsLoaderCounter'
    ])
  },
  methods: {
    ...mapActions([
      'loadJobApplications',
      'filterJobApplications',
      'getTalentApplyConfiguration'
    ]),
    filterOnJobOfferPublicationId (jobOfferPublicationId) {
      const changedFilters = this.filters
      changedFilters.JobOfferPublicationId = jobOfferPublicationId
      this.filterJobApplications(changedFilters)
    },
    filterOnJobOfferId (jobOfferId) {
      const changedFilters = this.filters
      changedFilters.JobOfferId = jobOfferId
      this.filterJobApplications(changedFilters)
    },
    filterOnTag (tag) {
      const changedFilters = this.filters
      changedFilters.Tags = [tag]
      this.filterJobApplications(changedFilters)
    },
    scrollToTop () {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
    handleScroll () {
      this.showFloatingButton = document.documentElement.scrollTop > 0
    }
  },
  watch: {
    isJobApplicationSidebarVisible (isVisible) {
      this.$refs.jobApplicationsSidebar.showSlider(isVisible)
    },
    jobApplicationsLoaderCounter (count) {
      this.isJobApplicationsLoading = count !== 0
    }
  },
  components: {
    JobApplicationsActionBar,
    JobApplicationFilter,
    JobApplicationListView,
    Sidebar,
    FloatingActionButton
  },
  async created () {
    if (this.$route.params.jobOfferId !== undefined) {
      this.filterOnJobOfferId(this.$route.params.jobOfferId)
    } else if (this.$route.params.jobOfferPublicationId !== undefined) {
      this.filterOnJobOfferPublicationId(this.$route.params.jobOfferPublicationId)
    } else if (this.$route.params.tag !== undefined) {
      this.filterOnTag(this.$route.params.tag)
    } else {
      this.loadJobApplications()
    }
    await this.getTalentApplyConfiguration()
  },
  mounted () {
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
