<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content" :style="this.modalSize">
      <h1>{{ this.title }}</h1>
      <input class="input-text-default" type="text" v-model="newName" placeholder="Nom">
      <p v-if="messageNameError" class="error-message">{{messageNameError}}</p>
      <input class="input-text-default" type="text" v-model="newEmail" placeholder="Email">
      <p v-if="messageEmailError" class="error-message">{{messageEmailError}}</p>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @mousedown="saveStakeholder">Valider</button>
      </div>
    </div>
  </section>
</template>

<script>
import { Stakeholder } from '@/models/Stakeholder'
import { mapActions } from 'vuex'
export default {
  name: 'ModalUpsertStakeholder',
  data () {
    return {
      newStakeholder: new Stakeholder(),
      newName: '',
      newEmail: '',
      isCreation: true,
      messageEmailError: '',
      messageNameError: ''
    }
  },
  props: {
    width: Number,
    height: Number,
    stakeholder: Stakeholder
  },
  mounted () {
    if (this.stakeholder !== undefined && this.stakeholder !== null) {
      this.newName = this.stakeholder.Name
      this.newEmail = this.stakeholder.Email
      this.isCreation = false
    } else {
      this.newName = ''
      this.newEmail = ''
      this.isCreation = true
    }
  },
  computed: {
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    },
    title () {
      if (this.isCreation) {
        return 'Nouvel opérationnel'
      } else {
        return 'Edition de l\'opérationnel ' + this.newName
      }
    }
  },
  methods: {
    ...mapActions([
      'updateStakeholder',
      'addStakeholder'
    ]),
    saveStakeholder () {
      if (this.validStakeholder()) {
        this.newStakeholder.Name = this.newName.trim()
        this.newStakeholder.Email = this.newEmail.trim()
        if (this.isCreation) {
          this.addStakeholder(this.newStakeholder)
        } else {
          this.newStakeholder.Id = this.stakeholder.Id
          this.updateStakeholder(this.newStakeholder)
        }
        this.close()
      }
    },
    close () {
      this.messageNameError = ''
      this.messageEmailError = ''
      this.$emit('close')
    },
    validEmail (email) {
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regexEmail.test(email)
    },
    validStakeholder () {
      this.messageNameError = ''
      this.messageEmailError = ''
      if (!this.newName) {
        this.messageNameError = 'Le nom est obligatoire.'
      }
      if (!this.newEmail) {
        this.messageEmailError = 'L\'adresse email est obligatoire'
      } else if (!this.validEmail(this.newEmail)) {
        this.messageEmailError = 'L\'adresse email n\'est pas valide.'
      }
      return !this.messageNameError && !this.messageEmailError
    }
  },
  watch: {
    selectedStakeholder (stakeholder) {
      this.newStakeholder = stakeholder
    }
  }
}
</script>
