export class JobApplicationFilterReferential {
    public ApplicantFullnames: string[]
    public Sources: string[]
    public MinimumDate: Date
    public MaximumDate: Date
    public JobOfferReferences: string[]
    public JobTitles: string[]
    public Tags: string[]
    public JobLocations: string[]

    constructor () {
      this.ApplicantFullnames = new Array<string>()
      this.MinimumDate = new Date(1900, 1, 1)
      this.MaximumDate = new Date()
      this.JobOfferReferences = new Array<string>()
      this.JobTitles = new Array<string>()
      this.Sources = new Array<string>()
      this.Tags = new Array<string>()
      this.JobLocations = new Array<string>()
    }

    initialize (data: any) {
      this.ApplicantFullnames = data.applicantFullnames
      this.MinimumDate = data.minimumDate
      this.MaximumDate = data.maximumDate
      this.JobOfferReferences = data.jobOfferReferences
      this.JobTitles = data.jobTitles
      this.Sources = data.sources
      this.Tags = data.tags
      this.JobLocations = data.jobLocations
      return this
    }
}
