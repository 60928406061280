import { JobApplicationColumn } from '@/models/JobApplicationColumn'

export class JobApplicationHeader {
    public Columns: JobApplicationColumn[]

    constructor () {
      this.Columns = [
        new JobApplicationColumn('applicantName', 1, 'Nom'),
        new JobApplicationColumn('fiability', 2, 'Scoring'),
        new JobApplicationColumn('ATSPostingStatus', 3, 'Transfert ATS'),
        new JobApplicationColumn('date', 4, 'Date'),
        new JobApplicationColumn('status', 5, 'Statut / Etape'),
        new JobApplicationColumn('jobTitle', 6, 'Poste'),
        new JobApplicationColumn('source', 7, 'Source'),
        new JobApplicationColumn('location', 8, 'Localisation'),
        new JobApplicationColumn('rating', 9, 'Evaluation'),
        new JobApplicationColumn('duplicates', 10, 'Nb Doublons'),
        new JobApplicationColumn('quickview', 11, ''),
        new JobApplicationColumn('attachments', 12, 'P.J.')
      ]
    }
}
