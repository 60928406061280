<template>
    <section id="jobApplicationFilter">
      <SelectedStatisticsFilters />
      <transition name="filters-fade">
        <AdvancedStatisticsFilters  v-if="isStatisticsFiltersPanelDisplayed" />
      </transition>
    </section>
</template>

<script>
import AdvancedStatisticsFilters from '@/components/statistics/filters/AdvancedStatisticsFilters.vue'
import SelectedStatisticsFilters from '@/components/statistics/filters/SelectedStatisticsFilters.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'StatisticsFilters',
  components: {
    AdvancedStatisticsFilters,
    SelectedStatisticsFilters
  },
  computed: {
    ...mapGetters(['isStatisticsFiltersPanelDisplayed'])
  }
}
</script>
