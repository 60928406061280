<template>
    <section class="modal">
      <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
      <div class="modal-content modalMessageModel">
        <h1 v-if="isCreation">Création d'un modèle de message</h1>
        <h1 v-else>Modification d'un modèle de message</h1>
        <input v-if="isFromCustom" class="input-text-default" type="text" v-model="newTitle" placeholder="Nom">
        <p v-if="!isTitleValid" class="error-message">Le nom du modèle est obligatoire</p>
        <input v-if="!isFromCustom" class="input-text-default" type="text" v-model="newSender" placeholder="Nom de l'expéditeur">
        <p v-if="!isSenderValid" class="error-message">Le nom de l'expéditeur est obligatoire</p>
        <input class="input-text-default" type="text" v-model="newSubject" placeholder="Sujet">
        <p v-if="!isSubjectValid" class="error-message">Le sujet de l'email est obligatoire</p>
        <jodit-editor v-model="newBody" placeholder="Message" height= "450"/>
        <p v-if="!isBodyValid" class="error-message">Le message de l'email est obligatoire</p>
        <div class="modal-buttons">
          <button type="button" class="button-default button-confirm" v-if="isDeleteButtonVisible" @mousedown="deleteModel">Supprimer</button>
          <button type="button" class="button-default button-right-side" @mousedown="close">Annuler</button>
          <button type="button" class="button-default button-confirm" @mousedown="saveModel">Valider</button>
        </div>
      </div>
      <transition name="modal-fade">
        <ModalConfirmation v-show="isConfirmationVisible" title="Confirmation de suppression" message="Voulez vous confirmer la suppression de ce modèle ?" :width="500" :height="200" @confirm="validateConfirmationDelete" @close="closeConfirmationDelete"/>
      </transition>
    </section>
</template>
<script>
import { mapActions } from 'vuex'
import { MessageModel } from '@/models/MessageModel'
import ModalConfirmation from '@/components/modals/ModalConfirmation'
import { JoditEditor } from 'jodit-vue'

export default {
  name: 'ModalMessageModel',
  components: {
    ModalConfirmation,
    JoditEditor
  },
  data () {
    return {
      isTitleValid: true,
      isSenderValid: true,
      isSubjectValid: true,
      isBodyValid: true,
      newTitle: '',
      newSender: '',
      newSubject: '',
      newBody: '',
      isConfirmationVisible: false,
      isCreation: true,
      messageModelToSend: new MessageModel()
    }
  },
  props: {
    type: Number,
    messageModel: MessageModel,
    isFromCustom: Boolean
  },
  mounted () {
    if (this.messageModel !== undefined && this.messageModel !== null && this.messageModel.Id !== -1) {
      this.newTitle = this.messageModel.Title
      this.newSender = this.messageModel.Sender
      this.newSubject = this.messageModel.Subject
      this.newBody = this.messageModel.Body
      this.isCreation = false
    } else {
      this.isCreation = true
    }
  },
  computed: {
    isDeleteButtonVisible () {
      return !this.isCreation
    }
  },
  methods: {
    ...mapActions([
      'editCustomMessageModel',
      'addCustomMessageModel',
      'saveNotificationMessageModel',
      'deleteCustomMessageModel',
      'deleteNotificationMessageModel'
    ]),
    saveModel () {
      // Validation des champs
      if (((this.isFromCustom && this.newTitle) || (!this.isFromCustom && this.newSender)) && this.newSubject && this.newBody) {
        if (this.messageModel !== undefined) {
          this.messageModelToSend.Id = this.messageModel.Id
        }
        this.messageModelToSend.Title = this.newTitle
        this.messageModelToSend.Sender = this.newSender
        this.messageModelToSend.Subject = this.newSubject
        this.messageModelToSend.Body = this.newBody
        this.messageModelToSend.Type = this.type
        if (this.isFromCustom) {
          if (this.messageModel !== undefined) {
            this.editCustomMessageModel(this.messageModelToSend)
          } else {
            this.addCustomMessageModel(this.messageModelToSend)
          }
        } else {
          this.saveNotificationMessageModel(this.messageModelToSend)
        }
        this.close()
      } else {
        if (this.isFromCustom) {
          if (this.newTitle) {
            this.isTitleValid = true
          } else {
            this.isTitleValid = false
          }
        }
        if (!this.isFromCustom) {
          if (this.newSender) {
            this.isSenderValid = true
          } else {
            this.isSenderValid = false
          }
        }
        if (this.newSubject) {
          this.isSubjectValid = true
        } else {
          this.isSubjectValid = false
        }
        if (this.newBody) {
          this.isBodyValid = true
        } else {
          this.isBodyValid = false
        }
      }
    },
    deleteModel () {
      this.isConfirmationVisible = true
    },
    close () {
      this.$emit('close')
      this.newTitle = ''
      this.newSender = ''
      this.newSubject = ''
      this.newBody = ''
      this.isSenderValid = true
      this.isSubjectValid = true
      this.isBodyValid = true
      this.isCreation = true
    },
    validateConfirmationDelete () {
      if (this.messageModel !== undefined) {
        if (this.isFromCustom) {
          this.deleteCustomMessageModel(this.messageModel.Id)
        } else {
          this.deleteNotificationMessageModel(this.type)
        }
      }
      this.closeConfirmationDelete()
      this.close()
    },
    closeConfirmationDelete () {
      this.isConfirmationVisible = false
    }
  }
}
</script>
