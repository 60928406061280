<template>
  <aside id="selected_filters_bar" style="position: relative;">
    <ul class="chips-default" v-if="activeFilters && activeFilters.length > 0">
      <li v-for="(filter, index) in activeFilters" :key="index">
        <Chip :name="filter.Name" :displayName="filter.DisplayName" :value="filter.Value" @deleteChip="removeFilter"/>
      </li>
    </ul>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Chip from '@/components/global/Chip'

export default {
  name: 'SelectedStatisticsFilters',
  components: {
    Chip
  },
  computed: {
    ...mapGetters(['statisticsFilter', 'statisticsCompaniesUserFilter']),
    activeFilters () {
      const chipFilters = []
      if (this.statisticsFilter.FromDate) {
        chipFilters.push({ Name: 'FromDate', DisplayName: 'À partir du', Value: this.$moment(this.statisticsFilter.FromDate).format('LL') })
      }
      if (this.statisticsFilter.ToDate) {
        chipFilters.push({ Name: 'ToDate', DisplayName: 'Jusqu\'au', Value: this.$moment(this.statisticsFilter.ToDate).format('LL') })
      }
      if (this.statisticsFilter.CompanyId) {
        const company = this.statisticsCompaniesUserFilter.Companies.find(c => c.Id === this.statisticsFilter.CompanyId)
        chipFilters.push({ Name: 'Company', DisplayName: 'Entreprise', Value: company.Name })
      }
      return chipFilters
    }
  },
  methods: {
    ...mapActions(['filterStatistics']),
    removeFilter (filter) {
      const changedFilters = this.statisticsFilter
      switch (filter.name) {
        case 'FromDate':
          changedFilters.FromDate = null
          break
        case 'ToDate':
          changedFilters.ToDate = null
          break
        case 'Company':
          changedFilters.CompanyId = null
          break
      }
      this.filterStatistics(changedFilters)
    }
  }
}
</script>
