<template>
    <section class="modal">
        <div class="modal-content" :style="this.modalSize">
            <h1>{{ this.title }}</h1>
            <p v-if="this.message">{{ this.message }}</p>
            <div class="modal-buttons">
                <button type="button" class="button-default" @mousedown="close">Annuler</button>
                <button type="button" class="button-default button-confirm" @mousedown="deleteComment">Supprimer</button>
            </div>
        </div>
    </section>
</template>

<script>
import { JobApplicationComment } from '@/models/JobApplicationComment'
import { mapActions } from 'vuex'
export default {
  name: 'ModalDeleteComment',
  props: {
    width: Number,
    height: Number,
    jobApplicationComment: JobApplicationComment
  },
  data () {
    return {
      message: 'Voulez-vous vraiment supprimer ce commentaire ?',
      title: 'Supprimer un commentaire'
    }
  },
  computed: {
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  methods: {
    ...mapActions([
      'deleteSelectedComment'
    ]),
    deleteComment () {
      this.deleteSelectedComment(this.jobApplicationComment.Id)
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
