export class JobApplicationColumn {
    public Name: string
    public Order: number
    public Label: string

    constructor (name: string, order: number, label: string) {
      this.Name = name
      this.Order = order
      this.Label = label
    }
}
