<template>
    <section id="stakeholders" >
      <div class="headTitle">
        <h1 class="title" tabindex="-1">Gestion des opérationnels</h1>
        <button class= "button-default" @click="showUpsertModal(undefined)"><v-icon name="plus"/></button>
      </div>
      <table class="clickable-table">
          <thead>
          <tr>
              <th>Nom</th>
              <th>Email</th>
              <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="stakeholer in stakeholders" :key="stakeholer.Id">
              <td>{{stakeholer.Name}}</td>
              <td>{{stakeholer.Email}}</td>
              <td class="action">
                  <v-icon class="icon" @click="showUpsertModal(stakeholer)" name="user-edit"/>
                  <v-icon class="icon" @click="showDeleteModal(stakeholer)" name="trash" />
              </td>
          </tr>
          </tbody>
      </table>
      <div class="no-result" v-if="stakeholders.length === 0">Pas de résultat</div>
      <transition name="modal-fade">
        <ModalDeleteStakeholder v-if="isModalDeleteVisible"
        @close="closeDeleteModal" :width="450" :height="200" :stakeholder="selectedStakeholder"/>
      </transition>
      <transition name="modal-fade">
        <ModalUpsertStakeholder v-if="isModalUpsertVisible"
        @close="closeUpsertModal" :width="500" :height="300" :stakeholder="selectedStakeholder"/>
      </transition>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalDeleteStakeholder from '@/components/modals/ModalDeleteStakeholder'
import ModalUpsertStakeholder from '@/components/modals/ModalUpsertStakeholder'
import { Stakeholder } from '@/models/Stakeholder'
export default {
  name: 'Stakeholders',
  components: {
    ModalDeleteStakeholder,
    ModalUpsertStakeholder
  },
  data () {
    return {
      isModalDeleteVisible: false,
      isModalUpsertVisible: false,
      selectedStakeholder: Stakeholder
    }
  },
  computed: {
    ...mapGetters([
      'stakeholders'
    ])
  },
  mounted () {
    this.selectedMessageModel = this.stakeholders
  },
  methods: {
    ...mapActions([
      'deleteCustomMessageModel'
    ]),
    showDeleteModal (selectedStakeholder) {
      this.selectedStakeholder = selectedStakeholder
      this.isModalDeleteVisible = true
    },
    closeDeleteModal () {
      this.isModalDeleteVisible = false
    },
    showUpsertModal (selectedStakeholder) {
      this.selectedStakeholder = selectedStakeholder
      this.isModalUpsertVisible = true
    },
    closeUpsertModal () {
      this.isModalUpsertVisible = false
    },
    validateConfirmationDelete () {
      this.deleteCustomMessageModel(this.selectedMessageModel.Id)
      this.isModalDeleteVisible = false
    }
  }
}
</script>
