<template>
  <section id="statistics_action_bar" >
    <div class="filters">
      <button class="button-default toggle-filter-panel-btn" @click="toggleStatisticsFiltersPanelDisplay"><v-icon v-if="this.isStatisticsFiltersPanelDisplayed" name="minus-circle" /> <v-icon v-else name="plus-circle" /> Filtres</button>
      <button class="button-default remove-all-filters-btn" @click="resetFilters" v-if="this.statisticsFilter.IsActive()"><v-icon name="times-circle" /> Supprimer les filtres</button>
    </div>
    <div id="actions">
      <div id="dropdown_icon">
        <button id= "statistics_big_button" @click="showSelectMenu" @blur="closeSelectMenu"><v-icon name="eye"/></button>
        <div id="dropdown_content" v-show="showSelectBoard">
          <button class="action-button" :class="{'action-button-selected': displayedBoard === 'global'}" @mousedown="selectBoard('global')">Globale</button>
          <button class="action-button" :class="{'action-button-selected': displayedBoard === 'statuses'}" @mousedown="selectBoard('statuses')">Par statut</button>
          <button class="action-button" :class="{'action-button-selected': displayedBoard === 'recruitmentSteps'}" @mousedown="selectBoard('recruitmentSteps')">Par étape</button>
        </div>
      </div>
      <button id= "statistics_big_button" @click="exportStatistics"><v-icon name="file-export"/></button>
      <button id= "statistics_big_button" @click="refreshStatistics"><v-icon name="sync-alt"/></button>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StatisticsActionBar',
  data () {
    return {
      displayedBoard: 'global',
      showSelectBoard: false
    }
  },
  methods: {
    ...mapActions(['filterStatistics']),
    selectBoard (board) {
      this.displayedBoard = board
      this.$emit('selectBoard', board)
    },
    showSelectMenu () {
      this.showSelectBoard = !this.showSelectBoard
    },
    closeSelectMenu () {
      this.showSelectBoard = false
    },
    refreshStatistics () {
      const filter = this.statisticsFilter
      this.filterStatistics(filter)
    },
    resetFilters () {
      const filters = this.statisticsFilter
      filters.FromDate = null
      filters.ToDate = null
      filters.CompanyId = null
      this.filterStatistics(filters)
    },
    exportStatistics () {
      switch (this.displayedBoard) {
        case 'global' : this.$emit('exportGlobalStatistics')
          break
        case 'statuses' : this.$emit('exportStatusesStatistics')
          break
        case 'recruitmentSteps' : this.$emit('exportRecruitmentStepsStatistics')
          break
        default : this.$emit('exportGlobalStatistics')
          break
      }
    },
    toggleStatisticsFiltersPanelDisplay () {
      this.$store.dispatch('toggleStatisticsFiltersPanelDisplay')
    }
  },
  computed: {
    ...mapGetters(['isStatisticsFiltersPanelDisplayed', 'statisticsFilter']),
    locale () {
      return this.$appSettings.locale
    }
  }
}
</script>
