<template>
<header class="master-header" id="master_header">
    <router-link to="/" class="header-logo-link">
      <span>Talent Application Management</span>
      <div class="logo-container">
        <span>by</span>
        <img class="logo" src="@/assets/images/logo-talentplug.svg" alt="Talentplug">
      </div>
    </router-link>
    <div class="logout">
      <span>Bonjour {{loginUser}}</span>
      <button @click="logOut"><v-icon name="power-off"/></button>
    </div>
</header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MasterHeader',
  computed: {
    ...mapGetters(['currentUser']),
    // TODO : Créer un composant pour le profil de l'utilisateur
    loginUser () {
      return this.currentUser.Fullname
    }
  },
  methods: {
    ...mapActions(['logOut'])
  }
}
</script>
