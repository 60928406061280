<template>
  <section id="statistics_view">
    <StatisticsActionBar @selectBoard="selectBoard"
      @exportGlobalStatistics="exportGlobalStatistics"
      @exportStatusesStatistics="exportStatusesStatistics"
      @exportRecruitmentStepsStatistics="exportRecruitmentStepsStatistics"/>
    <StatisticsFilters />
    <div id="statistics_boards">
      <GlobalStatistics v-show="isStatisticsJobApplicationVisible" @gotCSVData="generateFileFromCSVData"/>
      <StatisticsByStatus v-show="isStatisticsByStatusVisible" @gotCSVData="generateFileFromCSVData"/>
      <StatisticsByRecruitmentSteps v-show="isStatisticsByRecruitmentStepsVisible" @gotCSVData="generateFileFromCSVData"/>
    </div>
  </section>
</template>

<script>
import StatisticsActionBar from '@/components/statistics/StatisticsActionBar.vue'
import StatisticsFilters from '@/components/statistics/filters/StatisticsFilters.vue'
import GlobalStatistics from '@/components/statistics/GlobalStatistics.vue'
import StatisticsByStatus from '@/components/statistics/StatisticsByStatus.vue'
import StatisticsByRecruitmentSteps from '@/components/statistics/StatisticsByRecruitmentSteps.vue'
import EventBus from '@/types/EventBus'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Statistics',
  components: {
    StatisticsActionBar,
    GlobalStatistics,
    StatisticsByStatus,
    StatisticsByRecruitmentSteps,
    StatisticsFilters
  },
  data () {
    return {
      isStatisticsJobApplicationVisible: true,
      isStatisticsByStatusVisible: false,
      isStatisticsByRecruitmentStepsVisible: false
    }
  },
  methods: {
    ...mapActions(['filterStatistics']),
    selectBoard (board) {
      switch (board) {
        case 'global' :
          this.isStatisticsJobApplicationVisible = true
          this.isStatisticsByStatusVisible = false
          this.isStatisticsByRecruitmentStepsVisible = false
          break
        case 'statuses' :
          this.isStatisticsJobApplicationVisible = false
          this.isStatisticsByStatusVisible = true
          this.isStatisticsByRecruitmentStepsVisible = false
          break
        case 'recruitmentSteps' :
          this.isStatisticsJobApplicationVisible = false
          this.isStatisticsByStatusVisible = false
          this.isStatisticsByRecruitmentStepsVisible = true
          break
      }
    },
    exportGlobalStatistics () {
      EventBus.$emit('getGlobalStatisticsData')
    },
    exportStatusesStatistics () {
      EventBus.$emit('getStatusesStatisticsData')
    },
    exportRecruitmentStepsStatistics () {
      EventBus.$emit('getRecruitmentStepsStatisticsData')
    },
    getCsvDateLine () {
      const fromDate = this.statistics.FromDate
      const toDate = this.statistics.ToDate

      let csv = '\n\nDepuis le;'
      if (fromDate !== null) {
        csv += this.$moment(fromDate).format('YYYY-MM-DD')
      }
      csv += ';Jusqu\'au;'
      if (toDate !== null) {
        csv += this.$moment(toDate).format('YYYY-MM-DD')
      }
      csv += '\n'
      return csv
    },
    getFileName () {
      if (this.isStatisticsByStatusVisible) {
        return 'StatusesStatisticsExport.csv'
      } else if (this.isStatisticsByRecruitmentStepsVisible) {
        return 'RecruitmentStepsStatisticsExport.csv'
      } else {
        return 'GlobalStatisticsExport.csv'
      }
    },
    generateFileFromCSVData (csvData) {
      csvData += this.getCsvDateLine()
      const fileLink = document.createElement('a')
      fileLink.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvData)
      fileLink.target = '_blank'
      fileLink.download = this.getFileName()
      fileLink.click()
    }
  },
  computed: {
    ...mapGetters(['statistics', 'isStatisticsFiltersPanelDisplayed', 'statisticsFilter'])
  },
  mounted () {
    const filters = this.statisticsFilter
    filters.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    this.filterStatistics(filters)
  }
}
</script>
