<template>
    <section id="cleaningApplications">
      <h1 class="title">Gestion du nettoyage des candidatures</h1>
      <div class="cleaningApplicationsRow">
        <label class="cleaningApplicationsTitle">La suppression automatique des candidatures est configurée à : {{defaultCleaningApplications}} mois </label>
        <v-icon name="pencil-alt" @click="showUpdatetModal()"/>
      </div>
       <transition name="modal-fade">
        <ModalEditCleaningApplications v-if="isModalUpdateVisible"
        @close="closeUpdateModal" :width="530" :height="350" :defaultCleaningApplications="defaultCleaningApplications"/>
      </transition>
    </section>
</template>

<script>
import ModalEditCleaningApplications from '@/components/modals/ModalEditCleaningApplications'
import { mapGetters } from 'vuex'
export default {
  name: 'CleaningApplications',
  components: {
    ModalEditCleaningApplications
  },
  data () {
    return {
      isModalUpdateVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'defaultCleaningApplications'
    ])
  },
  methods: {
    showUpdatetModal () {
      this.isModalUpdateVisible = true
    },
    closeUpdateModal () {
      this.isModalUpdateVisible = false
    }
  }
}
</script>
