export class StatisticsCompany {
    public Id: number
    public Name: string

    constructor () {
      this.Id = -1
      this.Name = ''
    }

    initialize (company: any) {
      this.Id = company.id
      this.Name = company.name
      return this
    }
}
