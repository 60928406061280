export class JobApplicationComment {
  public Id: string
  public Content: string
  public Date: string
  public UserName: string

  constructor () {
    this.Id = ''
    this.Content = ''
    this.Date = ''
    this.UserName = ''
  }

  initialize (jobApplicationCommentJsonResult: any) {
    this.Id = jobApplicationCommentJsonResult.id
    this.Content = jobApplicationCommentJsonResult.content
    this.Date = jobApplicationCommentJsonResult.date
    this.UserName = jobApplicationCommentJsonResult.userName
    return this
  }
}
