export class StatisticsFilter {
    public FromDate: Date | null
    public ToDate: Date | null
    public CompanyId: number | null

    constructor () {
      this.FromDate = null
      this.ToDate = null
      this.CompanyId = null
    }

    public IsActive (): boolean {
      return this.FromDate !== null ||
                this.ToDate !== null || this.CompanyId !== null
    };
}
