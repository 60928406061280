<template>
  <div class="atsPostingStatus">
      <v-icon v-if="jobApplicationIsSent" name="check" class="sent" key="sent-status"/>
      <v-icon v-else name="times-circle" class="notSent" key="not-sent-status"
        v-tooltip.bottom="message"/>

      <v-icon v-if="!jobApplicationIsSent" name="redo"
        :class="publishToATSAuthorized && targetFiabilityIsReached ? 'redo' : 'redo disabled'"
        v-tooltip.bottom="targetFiabilityIsReached ? 'Envoyer vers l\'ATS' : 'Le score est insuffisant'"
        @click.stop="targetFiabilityIsReached && debouncedPublishJobApplicationToATS()"/>
  </div>
</template>

<script>
import { JobApplication } from '@/models/JobApplication'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ATSPostingStatus',
  props: {
    jobApplication: { type: JobApplication, required: true }
  },
  data: function () {
    return {
      publishToATSAuthorized: true,
      jobApplicationIsSent: this.jobApplication.TalentApply.IsSent,
      message: this.jobApplication.TalentApply.Message,
      jobApplicationFiability: this.jobApplication.TalentApply.Fiability
    }
  },
  computed: {
    ...mapGetters([
      'talentApplyConfiguration'
    ]),
    targetFiabilityIsReached () {
      return this.jobApplicationFiability >= this.talentApplyConfiguration.FiabilityThreshold
    }
  },
  methods: {
    ...mapActions([
      'publishJobApplicationToATS'
    ]),
    debouncedPublishJobApplicationToATS () {
      if (!this.timeout) {
        this.publishToATSAuthorized = false
        this.publishJobApplicationToATS(this.jobApplication).then(isSent => {
          this.$emit('ats-posting-status-change', isSent)
          this.jobApplicationIsSent = isSent
        })

        this.timeout = setTimeout(() => {
          this.timeout = null
          this.publishToATSAuthorized = true
        }, 5000)
      }
    }
  }
}
</script>
