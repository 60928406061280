<template>
  <section id="job_application_list_view">
    <table class="clickable-table">
    <thead>
      <tr>
        <th>
          <input type="checkbox" v-model="isAllJobApplicationsSelected">
        </th>
        <th v-for="column in displayedListHeader.Columns" :key="column.Name" @click="sortBy(column.Name)">
            {{ column.Label }}
            <span v-if="column.Name !== 'quickview'" class="arrow" :class="sortOrders[column.Name] > 0 ? 'asc' : 'dsc'"></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <JobApplicationRow v-for="jobApplication in displayedJobApplications"
        :key="jobApplication.Id"
        :jobApplicationId="jobApplication.Id"
        :headerColumns="displayedListHeader.Columns"
        />
    </tbody>
  </table>
  </section>
</template>

<script>
import JobApplicationRow from '@/components/jobApplications/JobApplicationRow.vue'
import { mapGetters, mapActions } from 'vuex'
import { JobApplicationSort } from '@/models/JobApplicationSort'
import { JobApplicationPage } from '@/models/JobApplicationPage'

export default {
  name: 'ListView',
  data () {
    return {
      paging: new JobApplicationPage(),
      isBusy: false
    }
  },
  computed: {
    ...mapGetters([
      'jobApplications',
      'recruitmentSteps',
      'statuses',
      'selectedJobApplicationsIds',
      'sorts',
      'listHeader',
      'isTalentApplyEnabled'
    ]),
    displayedJobApplications () {
      return this.jobApplications.slice(0, ((this.paging.Index + 1) * this.paging.JobApplicationsNumber))
    },
    displayedListHeader () {
      if (!this.isTalentApplyEnabled) {
        const displayedListHeader = JSON.parse(JSON.stringify(this.listHeader))
        const fiabilityColumnIndex = displayedListHeader.Columns.findIndex(c => c.Name === 'fiability')

        if (fiabilityColumnIndex !== -1) {
          displayedListHeader.Columns.splice(fiabilityColumnIndex, 1)
        }

        const atsPostingStatusColumnIndex = displayedListHeader.Columns.findIndex(c => c.Name === 'ATSPostingStatus')
        if (atsPostingStatusColumnIndex !== -1) {
          displayedListHeader.Columns.splice(atsPostingStatusColumnIndex, 1)
        }

        return displayedListHeader
      }
      return this.listHeader
    },
    sortOrders () {
      const sortOrders = {}
      this.displayedListHeader.Columns.forEach((column) => {
        switch (column) {
          case 'applicantName':
            sortOrders[column.name] = this.sorts.ApplicantFullname
            break
          case 'date':
            sortOrders[column.name] = this.sorts.Date
            break
          case 'status':
            sortOrders[column.name] = this.sorts.Status
            break
          case 'jobTitle':
            sortOrders[column.name] = this.sorts.JobTitle
            break
          case 'source':
            sortOrders[column.name] = this.sorts.Source
            break
          case 'location':
            sortOrders[column.name] = this.sorts.Location
            break
          case 'rating':
            sortOrders[column.name] = this.sorts.Rating
            break
          case 'ATSPostingStatus':
            sortOrders[column.name] = this.sorts.ATSPostingStatus
            break
          case 'fiability':
            sortOrders[column.name] = this.sorts.Fiability
            break
          default:
            sortOrders[column.name] = 0
            break
        }
      })
      return sortOrders
    },
    isAllJobApplicationsSelected: {
      get () {
        return this.displayedJobApplications.length === this.selectedJobApplicationsIds.length
      },
      set (value) {
        if (value) {
          this.selectAllJobApplications()
        } else {
          this.unselectAllJobApplications()
        }
        this.setJobApplicationsSidebarVisibility()
      }
    }
  },
  methods: {
    ...mapActions([
      'loadNextJobApplications',
      'sortJobApplications',
      'unselectAllJobApplications',
      'selectAllJobApplications',
      'setJobApplicationsSidebarVisibility'
    ]),
    handleScroll () {
      // const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
      const bottomOfWindow = document.documentElement.scrollTop + document.documentElement.offsetHeight >= document.documentElement.scrollHeight - (window.innerHeight / 5)
      // console.log('progress : ' + (document.documentElement.scrollTop + document.documentElement.offsetHeight) + ' / ' + (document.documentElement.scrollHeight - (window.innerHeight / 5)))
      if (bottomOfWindow && !this.isBusy) {
        this.isBusy = true
        this.displayNextJobApplication()
        this.isBusy = false
      }
    },
    displayNextJobApplication () {
      if (this.jobApplications.length !== this.displayedJobApplications.length && this.jobApplications.length - this.displayedJobApplications.length < 50) {
        this.loadNextJobApplications() // TODO : attendre le résultat
      }
      this.paging.Index += 1
    },
    sortBy (column) {
      const changedSort = new JobApplicationSort()
      changedSort.Date = 0
      switch (column) {
        case 'applicantName':
          changedSort.ApplicantFullname = this.getOrder(this.sorts.ApplicantFullname)
          break
        case 'date':
          changedSort.Date = this.getOrder(this.sorts.Date)
          break
        case 'status':
          changedSort.Status = this.getOrder(this.sorts.Status)
          break
        case 'jobTitle':
          changedSort.JobTitle = this.getOrder(this.sorts.JobTitle)
          break
        case 'source':
          changedSort.Source = this.getOrder(this.sorts.Source)
          break
        case 'location':
          changedSort.Location = this.getOrder(this.sorts.Location)
          break
        case 'rating':
          changedSort.Rating = this.getOrder(this.sorts.Rating)
          break
        case 'ATSPostingStatus':
          changedSort.ATSPostingStatus = this.getOrder(this.sorts.ATSPostingStatus)
          break
        case 'fiability':
          changedSort.Fiability = this.getOrder(this.sorts.Fiability)
          break
        case 'attachments':
          // TODO : rendre inerte le tri sur les pièces jointes
          break
        default:
          changedSort.Date = -1
          break
      }
      this.sortJobApplications(changedSort)
    },
    getOrder (sortValue) {
      if (sortValue) {
        return sortValue * -1
      }
      return 1
    }
  },
  mounted () {
    document.addEventListener('scroll', this.handleScroll)
    this.displayNextJobApplication()
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  components: {
    JobApplicationRow
  }
}
</script>
