<template>
    <section id="notification">
      <div class="on-off-radio-buttons-container">
          <label class="radio-buttons-title">{{title}}</label>
          <button v-show="isEditable" @click="openModalNotificationModel()"><v-icon name="pencil-alt"/></button>
          <div class="radio-buttons">
              <span class="radio-button">
                  <input type="radio" v-model="isNotificationActive" :id="idActive" :value="true" :disabled="!isMessageModelExist">
                  <label :for="idActive" :disabled="!isMessageModelExist">Activée</label>
              </span>
              <span class="radio-button">
                  <input type="radio" v-model="isNotificationActive" :id="idInactive" :value="false" :disabled="!isMessageModelExist">
                  <label :for="idInactive" :disabled="!isMessageModelExist">Désactivée</label>
              </span>
          </div>
      </div>
    </section>
</template>
<script>
import { MessageModel } from '@/models/MessageModel'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Notification',
  data () {
    return {
      title: '',
      messageModelActive: MessageModel,
      isNotificationActive: false,
      isMessageModelExist: false,
      idActive: this.messageModelType + '_Active',
      idInactive: this.messageModelType + '_Inactive'
    }
  },
  props: {
    messageModelType: Number,
    isEditable: Boolean
  },
  computed: {
    ...mapGetters([
      'jobApplicantAcknowledgmentMessageModel',
      'positiveAnswerMessageModel',
      'negativeAnswerMessageModel',
      'tamConfiguration'
    ])
  },
  mounted () {
    this.initTitle()
    this.refresh()
  },
  watch: {
    tamConfiguration () {
      this.refresh()
    },
    isNotificationActive (value) {
      switch (this.messageModelType) {
        case -1 :
          this.setNewApplicationNotificationSetting(value)
          break
        case 0 :
          this.setApplicantAcknowledgment(value)
          break
        case 4 :
          this.setPositiveAnswer(value)
          break
        case 3 :
          this.setNegativeAnswer(value)
          break
      }
    }
  },
  methods: {
    ...mapActions([
      'setNewApplicationNotificationSetting',
      'setApplicantAcknowledgment',
      'setPositiveAnswer',
      'setNegativeAnswer'
    ]),
    openModalNotificationModel () {
      this.$emit('openModal', this.messageModelType, this.messageModelActive)
    },
    refresh () {
      switch (this.messageModelType) {
        case -1 :
          this.isNotificationActive = this.tamConfiguration.IsNewJobApplicationNotificationEnabled
          this.isMessageModelExist = true
          break
        case 0 :
          this.messageModelActive = this.jobApplicantAcknowledgmentMessageModel
          if (this.messageModelActive.Id < 0) {
            this.isMessageModelExist = false
            this.isNotificationActive = false
          } else {
            this.isMessageModelExist = true
            this.isNotificationActive = this.tamConfiguration.IsApplicationAcknowledgmentEnabled
          }
          break
        case 4 :
          this.messageModelActive = this.positiveAnswerMessageModel
          if (this.messageModelActive.Id < 0) {
            this.isMessageModelExist = false
            this.isNotificationActive = false
          } else {
            this.isMessageModelExist = true
            this.isNotificationActive = this.tamConfiguration.IsPositiveAnswerNotificationEnabled
          }
          break
        case 3 :
          this.messageModelActive = this.negativeAnswerMessageModel
          if (this.messageModelActive.Id < 0) {
            this.isMessageModelExist = false
            this.isNotificationActive = false
          } else {
            this.isMessageModelExist = true
            this.isNotificationActive = this.tamConfiguration.IsNegativeAnswerNotificationEnabled
          }
          break
      }
    },
    initTitle () {
      switch (this.messageModelType) {
        case -1 :
          this.title = 'Notification d\'une nouvelle candidature'
          break
        case 0 :
          this.title = 'Accusés de réception aux candidats'
          break
        case 4 :
          this.title = 'Réponse positive à une candidature'
          break
        case 3 :
          this.title = 'Réponse négative à une candidature'
          break
      }
    }
  }
}
</script>
