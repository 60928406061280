export class JobApplicationSort {
    public ApplicantFullname: number
    public Date: number
    public Status: number
    public JobTitle: number
    public Source: number
    public Location: number
    public Rating: number
    public Relevance: number
    public ATSPostingStatus: number
    public Fiability: number

    constructor () {
      this.ApplicantFullname = 0
      this.Date = -1
      this.Status = 0
      this.JobTitle = 0
      this.Source = 0
      this.Location = 0
      this.Rating = 0
      this.Relevance = 0
      this.ATSPostingStatus = 0
      this.Fiability = 0
    }
}
