export class JobApplicationTalentApply {
    public Fiability: number
    public IsSent: boolean
    public LastSentDate: Date
    public Message: string

    constructor () {
      this.Fiability = 0
      this.IsSent = false
      this.LastSentDate = new Date()
      this.Message = ''
    }

    initialize (jobApplicationTalentApplyJsonResult: any) {
      this.Fiability = jobApplicationTalentApplyJsonResult.fiability || 0
      this.IsSent = jobApplicationTalentApplyJsonResult.isSent
      this.LastSentDate = jobApplicationTalentApplyJsonResult.lastSentDate
      this.Message = jobApplicationTalentApplyJsonResult.message
      return this
    }
}
