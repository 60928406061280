export class JobApplicationCount {
    public Total: number
    public Unread: number
    public Open: number
    public Hired: number
    public Rejected: number
    public Selected: number
    public Archived: number

    constructor () {
      this.Total = 0
      this.Unread = 0
      this.Open = 0
      this.Hired = 0
      this.Rejected = 0
      this.Selected = 0
      this.Archived = 0
    }

    initialize (data: any) {
      this.Total = data.total
      this.Unread = data.unread
      this.Open = data.opened
      this.Hired = data.hired
      this.Rejected = data.rejected
      this.Archived = data.archived
      return this
    }
}
