<template>
  <div class="slider">
    <!-- Sidebar -->
    <div class="navMenu" :style="[sliderDirection, currentWidth]">
      <!-- Toggle collapse icon -->
      <template v-if="links.length">
        <ul>
          <li v-for="link in links" :key="link.id" :style="link.styles">
            <button class="slider-link" type="button" @click="triggerMethod(link.method)"><v-icon :name="link.iconName"/><span :class="{ collapsed: isCollapsed }"> {{ link.text }}</span></button>
          </li>
        </ul>
      </template>
      <slot v-else />
      <button class="toggle-collapse-btn" @click="toggleCollapse" :style="[toggleSizeBtnWidth]"><v-icon name="arrows-alt-h"/></button>
    </div>
    <!-- TODO : nettoyer -->
    <!-- Toggle display icon -->
    <!-- <div class="navIcon" v-if="!isAlwaysDisplayed" :style="[iconDirection, toggleSliderBtnOffset]">
      <button type="button" @click.prevent="toggleSlider">
        <v-icon name="cog" />
      </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'slider',
  props: { // TODO : DT - Nettoyer props utiles + commenter props utiles
    width: {
      type: Number,
      required: false,
      default: 200
    },
    collapsedWidth: {
      type: Number,
      required: false,
      default: 50
    },
    direction: {
      type: String,
      required: false,
      default: 'left',
      validator: (value) => ['left', 'right'].indexOf(value) > -1
    },
    links: {
      type: Array,
      required: false,
      default: () => ([])
    },
    elementToPushId: {
      type: String,
      required: true
    },
    isAlwaysDisplayed: {
      type: Boolean,
      required: false,
      default: false
    },
    isHiddenOnStart: {
      type: Boolean,
      required: false,
      default: false
    },
    isCollapsedOnStart: {
      type: Boolean,
      required: false,
      default: false
    },
    isSticked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currentWidth: {
        width: 0
      },
      toggleSliderBtnOffset: {
        marginLeft: 0
      },
      toggleSizeBtnWidth: {
        width: 0
      },
      isOpen: true,
      isCollapsed: false
    }
  },
  computed: {
    sliderDirection () {
      return this.direction === 'right'
        ? { right: 0 }
        : { left: 0 }
    },
    iconDirection () {
      return { float: this.direction }
    },
    elementToPush () {
      return document.getElementById(this.elementToPushId)
    },
    currentPixelWidth () {
      const currentWidth = this.isCollapsed ? this.collapsedWidth : this.width
      return `${currentWidth}px`
    },
    currentMargin () {
      return this.isSticked ? this.currentPixelWidth : '0'
    }
  },
  mounted () {
    if (!this.elementToPush) {
      console.warn('[Slider] Element to push is not defined')
    }

    if (this.isCollapsedOnStart) {
      this.collapse()
      this.isCollapsed = true
    } else {
      this.expand()
      this.isCollapsed = false
    }

    if (this.isAlwaysDisplayed) {
      this.open()
      this.isOpen = true
    } else {
      if (this.isHiddenOnStart) {
        this.close()
        this.isOpen = false
      } else {
        this.open()
        this.isOpen = true
      }
    }
  },
  methods: {
    open () {
      this.currentWidth = { width: this.currentPixelWidth }
      this.toggleSliderBtnOffset = { left: this.currentPixelWidth }
      this.toggleSizeBtnWidth = { width: this.currentPixelWidth }
      if (this.elementToPush) {
        const marginDirection = `margin-${this.direction}`
        this.elementToPush.style.transition = `${marginDirection} .2s ease-in-out`
        this.elementToPush.style.marginLeft = this.currentMargin
      }
      this.isOpen = true
    },
    close () {
      this.currentWidth = { width: 0 }
      this.toggleSliderBtnOffset = { left: 0 }
      this.toggleSizeBtnWidth = { width: 0 }

      if (this.elementToPush) {
        const marginDirection = `margin-${this.direction}`
        this.elementToPush.style.transition = `${marginDirection} .2s ease-in-out`
        this.elementToPush.style.marginLeft = '0'
        this.elementToPush.style.marginRight = '0'
      }
      this.isOpen = false
    },
    toggleSlider () {
      if (this.currentWidth.width === 0) {
        this.open()
      } else {
        this.close()
      }
    },
    collapse () {
      const collapsedWidth = `${this.collapsedWidth}px`
      this.currentWidth = { width: collapsedWidth }
      this.toggleSliderBtnOffset = { left: collapsedWidth }
      this.toggleSizeBtnWidth = { width: collapsedWidth }
    },
    expand () {
      const expandWidth = `${this.width}px`
      this.currentWidth = { width: expandWidth }
      this.toggleSliderBtnOffset = { left: expandWidth }
      this.toggleSizeBtnWidth = { width: expandWidth }
    },
    toggleCollapse () {
      if (!this.isCollapsed) {
        this.collapse()
      } else {
        this.expand()
      }

      this.isCollapsed = !this.isCollapsed

      if (this.elementToPush) {
        const marginDirection = `margin-${this.direction}`
        this.elementToPush.style.transition = `${marginDirection} .2s ease-in-out`
        this.elementToPush.style.marginLeft = this.currentMargin
      }
    },
    triggerMethod (methodName) {
      if (methodName) {
        this.$emit('triggerMethod', methodName)
      }
    }
  },
  watch: {
    isSticked (value) {
      this.elementToPush.style.transition = 'none'
      if (this.isOpen) {
        if (value) {
          this.elementToPush.style.marginLeft = this.currentMargin
        } else {
          this.elementToPush.style.marginLeft = '0'
        }
      } else {
        this.elementToPush.style.marginLeft = '0'
      }
    }
  }
}
</script>
