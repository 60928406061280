<template>
  <section id="duplicates_admin">
    <h1 class="title" tabindex="-1">Gérer les doublons</h1>
    <div class="on-off-radio-buttons-container">
      <div class="labels">
        <label class="strong">Doublons involontaires</label>
        <label class="italic">Suppression automatique des candidatures identifiées comme doublons « involontaires » (même poste, même adresse mail, même jour)</label>
      </div>
      <div class="radio-buttons">
        <span class="radio-button">
            <input type="radio" v-model="isErrorDuplicatesEnabled" :value="true" @change="setIsErrorDuplicatesEnabled">
            <label>Activé</label>
        </span>
        <span class="radio-button">
            <input type="radio" v-model="isErrorDuplicatesEnabled" :value="false" @change="setIsErrorDuplicatesEnabled">
            <label>Désactivé</label>
        </span>
      </div>
    </div>

    <div class="on-off-radio-buttons-container">
      <div class="labels">
        <label class="strong">Doublons volontaires</label>
        <label class="italic">Identification visuelle des candidats ayant postulé plusieurs fois sur un même poste (même offre, même adresse mail) </label>
      </div>
      <div class="radio-buttons">
        <span class="radio-button">
            <input type="radio" v-model="isMultiApplicationDuplicatesEnabled" :value="true" @change="setIsMultiApplicationDuplicatesEnabled">
            <label>Activé</label>
        </span>
        <span class="radio-button">
            <input type="radio" v-model="isMultiApplicationDuplicatesEnabled" :value="false" @change="setIsMultiApplicationDuplicatesEnabled">
            <label>Désactivé</label>
        </span>
      </div>
    </div>
  </section>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DuplicatesAdmin',
  data () {
    return {
      isErrorDuplicatesEnabled: Boolean,
      isMultiApplicationDuplicatesEnabled: Boolean
    }
  },
  computed: {
    ...mapGetters([
      'tamConfiguration'
    ])
  },
  watch: {
    tamConfiguration () {
      this.initRadioButtons()
    }
  },
  methods: {
    ...mapActions([
      'setErrorDuplicatesSetting',
      'setMultiApplicationDuplicatesSetting'
    ]),
    initRadioButtons () {
      this.isErrorDuplicatesEnabled = this.tamConfiguration.IsErrorDuplicatesEnabled
      this.isMultiApplicationDuplicatesEnabled = this.tamConfiguration.IsMultiApplicationDuplicatesEnabled
    },
    setIsErrorDuplicatesEnabled (event) {
      this.setErrorDuplicatesSetting(event.target.value)
    },
    setIsMultiApplicationDuplicatesEnabled (event) {
      this.setMultiApplicationDuplicatesSetting(event.target.value)
    }
  }
}
</script>
