<template>
  <section id="user_profile_view">
    <p>User profile</p>
  </section>
</template>

<script>
export default {
  name: 'UserProfile'
}
</script>
