import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import store from './store'
import appSettings from '@/plugins/appSettings'

// Global styles
import '@/assets/styles/scss/_root.scss'

// Font awesome icons
import 'vue-awesome/icons'
import VueIcon from 'vue-awesome/components/Icon.vue'

// Vue moment
import VueMoment from 'vue-moment'
import moment from 'moment'
import 'moment/locale/fr'

import VCalendar from 'v-calendar'

import VTooltip from 'v-tooltip'

import VueCookies from 'vue-cookies'
import { loginPageUrlProd, loginPageUrlPprod, loginPageUrlLocal } from './mixins/constants'
import { getJwtPayload } from './mixins/tokenHelper'
import { TamUser } from './models/tamUser'

Vue.use(VueCookies)

const talentPlugCookieName = 'TalentPlugCookie'

// Add automatically the token from the cookie
Vue.prototype.$http = Axios

if (Vue.$cookies.isKey(talentPlugCookieName)) {
  const cookie = Vue.$cookies.get(talentPlugCookieName)
  const token = cookie.access_token
  if (token) {
    Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${token}`
    const jsonJwtPayload = getJwtPayload(token)
    const user = new TamUser().initialize(jsonJwtPayload)
    store.dispatch('setCurrentUser', user)
  }
} else {
  // TODO: En faire un plugin pour la réutilisation
  if (window.location.href.includes('https://tam.mytalentplug.com')) {
    window.location.href = loginPageUrlProd
  } else if (window.location.href.includes('localhost')) {
    window.location.href = loginPageUrlLocal
  } else {
    window.location.href = loginPageUrlPprod
  }
}

Vue.use(appSettings)
Vue.use(VueMoment, { moment })
Vue.use(VTooltip)
Vue.use(VCalendar, {
  locales: {
    'fr-FR': {
      firstDayOfWeek: 2,
      masks: {
        title: 'MMMM YYYY',
        weekdays: 'WW',
        L: 'DD/MM/YYYY'
      }
    }
  }
})
Vue.component('v-icon', VueIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
