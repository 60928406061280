import { JobApplicationComment } from '@/models/JobApplicationComment'
import { JobApplicationActivity } from '@/models/JobApplicationActivity'
import { JobApplicationAttachment } from '@/models/JobApplicationAttachment'
import { Applicant } from '@/models/Applicant'
import { JobOffer } from '@/models/JobOffer'
import { RecruitmentStep } from './RecruitmentStep'
import { JobApplicationTalentApply } from '@/models/JobApplicationTalentApply'

export class JobApplication {
  public Activities: JobApplicationActivity[]
  public Applicant: Applicant
  public JobOffer: JobOffer
  public Attachments: JobApplicationAttachment[]
  public Comments: JobApplicationComment[]
  public Date: Date
  public Id: string
  public Rating: number
  public RecruitmentStepId: string
  public Source: string
  public Status: number
  public Tags: string[]
  public IsSelected: boolean
  public DuplicatesCount: number
  public IsMultiApplicationDuplicate: boolean
  public TalentApply: JobApplicationTalentApply

  constructor () {
    this.Activities = new Array<JobApplicationActivity>()
    this.Applicant = new Applicant()
    this.JobOffer = new JobOffer()
    this.Attachments = new Array<JobApplicationAttachment>()
    this.Comments = new Array<JobApplicationComment>()
    this.Date = new Date()
    this.Id = ''
    this.Rating = -1
    this.RecruitmentStepId = ''
    this.Source = ''
    this.Status = -1
    this.Tags = new Array<string>()
    this.IsSelected = false
    this.DuplicatesCount = -1
    this.IsMultiApplicationDuplicate = false
    this.TalentApply = new JobApplicationTalentApply()
  }

  initialize (jobApplicationJsonResult: any) {
    this.Activities = jobApplicationJsonResult.activities.map((a: any) => new JobApplicationActivity().initialize(a))
    this.Applicant = new Applicant().initialize(jobApplicationJsonResult.applicant)
    this.JobOffer = new JobOffer().initialize(jobApplicationJsonResult.jobOffer)
    this.Attachments = jobApplicationJsonResult.attachments.map((a: any) => new JobApplicationAttachment().initialize(a))
    this.Comments = jobApplicationJsonResult.comments.map((c: any) => new JobApplicationComment().initialize(c))
    this.Date = jobApplicationJsonResult.date
    this.Id = jobApplicationJsonResult.id
    this.Rating = jobApplicationJsonResult.rating
    this.RecruitmentStepId = jobApplicationJsonResult.recruitmentStepId
    this.Source = jobApplicationJsonResult.source
    this.Status = jobApplicationJsonResult.status
    this.Tags = jobApplicationJsonResult.tags
    this.DuplicatesCount = jobApplicationJsonResult.duplicatesCount
    this.IsMultiApplicationDuplicate = jobApplicationJsonResult.isMultiApplicationDuplicate
    this.TalentApply = new JobApplicationTalentApply().initialize(jobApplicationJsonResult.talentApply || {})
    return this
  }

  toCsv (recruitmentStepsReferential: Array<RecruitmentStep>, separator: string | null): string {
    if (recruitmentStepsReferential === null) {
      recruitmentStepsReferential = []
    }

    if (separator == null) {
      separator = ';'
    }
    return this.Applicant.toCsv(separator) + separator +
    this.Date.toString() + separator +
    this.JobOffer.JobTitle + separator +
    this.JobOffer.Reference + separator +
    this.JobOffer.JobLocation + separator +
    this.Source + separator +
    this.Status + separator +
    (recruitmentStepsReferential.find(rs => rs.Id === this.RecruitmentStepId) || {}).Name + separator +
    (this.Rating || '') + separator +
    this.Tags.join('|') + separator
  }
}
