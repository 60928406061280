<template>
  <div class="jobApplicationAttachments panel">
    <div v-if="attachments.length > 0">
      <div class="lateral-container">
        <div class="onglets">
        <span v-for="(attachment, index) in attachments" :key="attachment.Id" :title="attachment.FileName"
          :class="{ 'actif': activeTabIndex === index }"
          @click="selectTab(index)">
          {{ buildAttachmentName(attachment) }}
        </span>
      </div>
      <div class="plus-icon" @click="openModalNewAttachment">
        <span><v-icon name="plus"/></span>
      </div>
      </div>
      <div class="viewer">
        <div v-if="isViewableAttachment">
          <div v-if="!isDocumentLoaded" class="loadingVue">
            <span>Chargement...</span>
            <span class="loadingIcon"><v-icon name="spinner" /></span>
          </div>
          <embed v-resize="resizeToA4" id="embedFileViewer" ref="embedFileViewer" :src="attachmentBlobUrl" :type="activeAttachment.ContentType" @load="isLoaded" />
        </div>
        <div v-else>
          <p>Cette pièce jointe ne peut pas être visionnée</p>
          <a v-if="activeAttachment" class="downloadAttachment" :href="attachmentBlobUrl" download>
            <div>
              <v-icon class="icon" name="file-download" title="Télécharger"/>
            </div>
            <div>
              {{ activeAttachment.FileName }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Il n'y a pas de pièce jointe pour cette candidature</p>
    </div>
    <transition name="modal-fade">
      <ModalNewAttachment v-if="isModalNewAttachmentVisible"
      :title="'Ajouter un fichier à la candidature'"
      :jobApplicationId="jobApplicationId"
      @close="closeModalNewAttachment()"
      @added-attachments="closeModalNewAttachment"/>
    </transition>
  </div>
</template>

<script>
import ResizeDirective from '@/directives/resize.ts'
import { mapActions, mapGetters } from 'vuex'
import ModalNewAttachment from '@/components/modals/ModalNewAttachment'

export default {
  name: 'JobApplicationAttachments',
  data () {
    return {
      activeTabIndex: 0,
      isDocumentLoaded: false,
      isModalNewAttachmentVisible: false
    }
  },
  directives: {
    resize: ResizeDirective
  },
  props: {
    attachments: Array, // TODO : rajouter le type TS
    jobApplicationId: String
  },
  components: {
    ModalNewAttachment
  },
  watch: {
    'attachments' () {
      this.generateAttachmentBlobUrls()
    }
  },
  methods: {
    ...mapActions(['generateAttachmentBlobUrl']),
    selectTab (index) {
      this.activeTabIndex = index
      this.isDocumentLoaded = false
    },
    isLoaded () {
      this.isDocumentLoaded = true
      this.resizeToA4()
    },
    onViewerResize () {
      this.resizeToA4()
    },
    resizeToA4 () {
      if (this.$refs.embedFileViewer) {
        const width = this.$refs.embedFileViewer.offsetWidth
        if (width > 0) {
          this.$refs.embedFileViewer.style.height = (width * 1.415126050420168) + 'px'// A4 ratio
        }
      }
    },
    buildAttachmentName (attachment) {
      if (attachment.FileName.length > 35) {
        const fileName = attachment.FileName.replace('.'.concat(attachment.FileExtension), '')
        return fileName.slice(0, 25).concat('[...]', '.', attachment.FileExtension)
      } else {
        return attachment.FileName
      }
    },
    generateAttachmentBlobUrls () {
      if (this.attachments.length > 0) {
        this.attachments.forEach(attachment => {
          this.generateAttachmentBlobUrl(attachment)
        })
      }
    },
    openModalNewAttachment () {
      this.isModalNewAttachmentVisible = true
    },
    closeModalNewAttachment () {
      this.isModalNewAttachmentVisible = false
    }
  },
  computed: {
    ...mapGetters(['attachmentBlobUrlDictionary']),
    activeAttachment () {
      return this.attachments[this.activeTabIndex]
    },
    attachmentBlobUrl () {
      const blobUrl = this.attachmentBlobUrlDictionary.find(x => x.AttachmentId === this.activeAttachment.Id)
      if (blobUrl) {
        return blobUrl.BlobUrl
      }
      return ''
    },
    isViewableAttachment () {
      const viewableContentTypes = ['application/pdf', 'text/html', 'image/jpeg', 'image/png']
      return viewableContentTypes.find(x => x === this.activeAttachment.ContentType) !== undefined
    }
  },
  beforeMount () {
    this.generateAttachmentBlobUrls()
  }
}
</script>
