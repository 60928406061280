<template>
    <section id="global_statistics" >
        <h1 class="title" tabindex="-1">Candidatures reçues</h1>
        <table class="table">
            <thead>
                <tr>
                <td>Source</td>
                <td>Quantité</td>
                <td>Pourcentage</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(source, index) in sortedSources" :key="index">
                  <td>{{source.Source}}</td>
                  <td>{{source.Count}}</td>
                  <td>{{source.Percentage}}%</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td>Total</td>
                <td>{{getTotal()}}</td>
                <td>100%</td>
                </tr>
            </tfoot>
        </table>
    </section>
</template>

<script>

import EventBus from '@/types/EventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'GlobalStatistics',
  methods: {
    getCount (source) {
      const activeJobApplications = this.statistics.Global.find(c => c.Source === source)?.Count ?? 0
      const deletedJobApplications = this.statistics.Deleted.find(c => c.Source === source)?.Count ?? 0
      return activeJobApplications + deletedJobApplications
    },
    getPercentage (source) {
      return Math.round((this.getCount(source) / this.getTotal()) * 100)
    },
    getTotal () {
      let total = 0
      this.statistics.Global.forEach(element => {
        total += element.Count
      })
      this.statistics.Deleted.forEach(element => {
        total += element.Count
      })
      return total
    },
    getGlobalStatisticsData () {
      let csv = 'Source;Quantité\n'
      this.sortedSources.forEach(s => {
        csv += s.Source + ';'
        csv += s.Count + ' (' + s.Percentage + '%);'
        csv += '\n'
      })
      csv += 'Total;'
      csv += this.getTotal() + ';'
      csv += '\n'
      this.$emit('gotCSVData', csv)
    }
  },
  computed: {
    ...mapGetters(['statistics']),
    sortedSources () {
      const globalStatistics = []
      this.statistics.Sources.forEach(s => globalStatistics.push({ Source: s, Count: this.getCount(s), Percentage: this.getPercentage(s) }))
      globalStatistics.sort((a, b) => b.Count - a.Count)
      return globalStatistics
    }
  },
  mounted () {
    EventBus.$on('getGlobalStatisticsData', this.getGlobalStatisticsData)
  }
}
</script>
