export class AttachmentsInformation {
  public FileName: string
  public File: Uint8Array | null

  constructor () {
    this.FileName = ''
    this.File = null
  }

  initialize (attachmentsInformationJsonResult: any) {
    this.FileName = attachmentsInformationJsonResult.fileName
    this.File = new Uint8Array(attachmentsInformationJsonResult.file.data)
    return this
  }
}
