import { JobApplicationCountBySource } from '@/models/JobApplicationCountBySource'

export class JobApplicationStatistics {
    public FromDate: Date|null
    public ToDate: Date|null
    public Sources: string[]
    public Global: JobApplicationCountBySource[]
    public Unread: JobApplicationCountBySource[]
    public Open: JobApplicationCountBySource[]
    public Hired: JobApplicationCountBySource[]
    public Rejected: JobApplicationCountBySource[]
    public Deleted: JobApplicationCountBySource[]
    public RecruitmentSteps: JobApplicationCountBySource[]

    constructor () {
      this.FromDate = null
      this.ToDate = null
      this.Sources = []
      this.Global = []
      this.Unread = []
      this.Open = []
      this.Hired = []
      this.Rejected = []
      this.Deleted = []
      this.RecruitmentSteps = []
    }

    initialize (data: any) {
      this.FromDate = data.fromDate
      this.ToDate = data.toDate
      this.Sources = data.sources
      this.Global = data.global.map((a: any) => new JobApplicationCountBySource().initialize(a))
      this.Unread = data.unread.map((a: any) => new JobApplicationCountBySource().initialize(a))
      this.Open = data.open.map((a: any) => new JobApplicationCountBySource().initialize(a))
      this.Hired = data.hired.map((a: any) => new JobApplicationCountBySource().initialize(a))
      this.Rejected = data.rejected.map((a: any) => new JobApplicationCountBySource().initialize(a))
      this.Deleted = data.deleted.map((a: any) => new JobApplicationCountBySource().initialize(a))
      this.RecruitmentSteps = data.recruitmentSteps.map((a: any) => new JobApplicationCountBySource().initialize(a))
      return this
    }
}
