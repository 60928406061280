<template>
    <section id="statistics_status" >
        <h1 class="title" tabindex="-1">Candidatures reçues par statut</h1>
        <table class="table">
            <thead>
                <tr>
                    <td rowspan="2">Source</td>
                    <td colspan="2" class="align-center">Candidatures non lues</td>
                    <td colspan="2" class="align-center">Candidatures en cours</td>
                    <td colspan="2" class="align-center">Candidatures recrutées</td>
                    <td colspan="2" class="align-center">Candidatures non recrutées</td>
                    <td colspan="2" class="align-center"><div>Candidatures supprimées</div>(doublons inclus)</td>
                </tr>
                <tr>
                    <td class="align-right">Qté</td>
                    <td class="align-left">%</td>
                    <td class="align-right">Qté</td>
                    <td class="align-left">%</td>
                    <td class="align-right">Qté</td>
                    <td class="align-left">%</td>
                    <td class="align-right">Qté</td>
                    <td class="align-left">%</td>
                    <td class="align-right">Qté</td>
                    <td class="align-left">%</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(source, index) in sortedSources" :key="index">
                  <td>{{source.Source}}</td>
                  <td class="align-right">{{source.UnreadCount}}</td>
                  <td class="align-left">{{source.UnreadPercentage}}%</td>
                  <td class="align-right">{{source.OpenCount}}</td>
                  <td class="align-left">{{source.OpenPercentage}}%</td>
                  <td class="align-right">{{source.HiredCount}}</td>
                  <td class="align-left">{{source.HiredPercentage}}%</td>
                  <td class="align-right">{{source.RejectedCount}}</td>
                  <td class="align-left">{{source.RejectedPercentage}}%</td>
                  <td class="align-right">{{source.DeletedCount}}</td>
                  <td class="align-left">{{source.DeletedPercentage}}%</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>Total</td>
                    <td class="align-right">{{getTotal('unread')}}</td>
                    <td class="align-left">100%</td>
                    <td class="align-right">{{getTotal('open')}}</td>
                    <td class="align-left">100%</td>
                    <td class="align-right">{{getTotal('hired')}}</td>
                    <td class="align-left">100%</td>
                    <td class="align-right">{{getTotal('rejected')}}</td>
                    <td class="align-left">100%</td>
                    <td class="align-right">{{getTotal('deleted')}}</td>
                    <td class="align-left">100%</td>
                </tr>
            </tfoot>
        </table>
    </section>
</template>
<script>
import EventBus from '@/types/EventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'StatisticsByStatus',
  methods: {
    getCount (source, status) {
      switch (status) {
        case 'unread':
          return this.statistics.Unread.find(c => c.Source === source)?.Count ?? 0
        case 'open':
          return this.statistics.Open.find(c => c.Source === source)?.Count ?? 0
        case 'hired':
          return this.statistics.Hired.find(c => c.Source === source)?.Count ?? 0
        case 'rejected':
          return this.statistics.Rejected.find(c => c.Source === source)?.Count ?? 0
        case 'deleted':
          return this.statistics.Deleted.find(c => c.Source === source)?.Count ?? 0
        default:
          return 0
      }
    },
    getPercentage (source, status) {
      return Math.round((this.getCount(source, status) / (this.getTotal(status) === 0 ? this.getTotal() : this.getTotal(status))) * 100)
    },
    getTotal (status) {
      let total = 0
      switch (status) {
        case 'unread':
          this.statistics.Unread.forEach(element => {
            total += element.Count
          })
          break
        case 'open':
          this.statistics.Open.forEach(element => {
            total += element.Count
          })
          break
        case 'hired':
          this.statistics.Hired.forEach(element => {
            total += element.Count
          })
          break
        case 'rejected':
          this.statistics.Rejected.forEach(element => {
            total += element.Count
          })
          break
        case 'deleted':
          this.statistics.Deleted.forEach(element => {
            total += element.Count
          })
          break
        default:
          this.statistics.Global.forEach(element => {
            total += element.Count
          })
          this.statistics.Deleted.forEach(element => {
            total += element.Count
          })
          break
      }
      return total
    },
    getStatusesStatisticsData () {
      let csv = 'Source;Non lue;En cours;Recruté;Non recruté;Supprimé\n'
      this.sortedSources.forEach(s => {
        csv += s.Source + ';'
        csv += s.UnreadCount + ' (' + s.UnreadPercentage + '%);'
        csv += s.OpenCount + ' (' + s.OpenPercentage + '%);'
        csv += s.HiredCount + ' (' + s.HiredPercentage + '%);'
        csv += s.RejectedCount + ' (' + s.RejectedPercentage + '%);'
        csv += s.DeletedCount + ' (' + s.DeletedPercentage + '%)'
        csv += '\n'
      })
      csv += 'Total;'
      csv += this.getTotal('unread') + ';'
      csv += this.getTotal('open') + ';'
      csv += this.getTotal('hired') + ';'
      csv += this.getTotal('rejected') + ';'
      csv += this.getTotal('deleted') + ';'
      csv += '\n'
      this.$emit('gotCSVData', csv)
    }
  },
  computed: {
    ...mapGetters(['statistics']),
    sortedSources () {
      const statusesStatistics = []
      this.statistics.Sources.forEach(s => statusesStatistics.push(
        {
          Source: s,
          UnreadCount: this.getCount(s, 'unread'),
          OpenCount: this.getCount(s, 'open'),
          HiredCount: this.getCount(s, 'hired'),
          RejectedCount: this.getCount(s, 'rejected'),
          DeletedCount: this.getCount(s, 'deleted'),
          UnreadPercentage: this.getPercentage(s, 'unread'),
          OpenPercentage: this.getPercentage(s, 'open'),
          HiredPercentage: this.getPercentage(s, 'hired'),
          RejectedPercentage: this.getPercentage(s, 'rejected'),
          DeletedPercentage: this.getPercentage(s, 'deleted')
        }))
      statusesStatistics.sort((a, b) => b.HiredCount - a.HiredCount)
      return statusesStatistics
    }
  },
  mounted () {
    EventBus.$on('getStatusesStatisticsData', this.getStatusesStatisticsData)
  }
}
</script>
