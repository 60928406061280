<template>
  <section class="modal modal-multi-job-application-tag">
    <div class="modal-content">
      <h1>{{ this.title }}</h1>
      <div class="content">
        <InputAutoComplete
        :autoCompleteValues='filterReferential.Tags'
        :minimumCharsBeforeAutoComplete="0"
        className='filter-input'
        placeholder='Ajouter un tag ...'
        @valueSelected='askForConfirmation'
        />
        <p v-show="isInputErrorVisible" class="error-message">
          {{ inputErrorMessage }}
        </p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
      </div>
    </div>
    <ModalConfirmation v-show="isConfirmationVisible" title="Confirmation d'ajout" :message="confirmationMessage" :width="500" :height="200" @confirm="validateConfirmation" @close="closeConfirmation"/>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getTagsToAdd } from '@/mixins/tagsHelper'
import InputAutoComplete from '@/components/_generic/InputAutocomplete'
import ModalConfirmation from '@/components/modals/ModalConfirmation'

export default {
  name: 'ModalMultiJobApplicationTag',
  components: {
    InputAutoComplete,
    ModalConfirmation
  },
  data () {
    return {
      isConfirmationVisible: false,
      confirmationMessage: '',
      tagsToAdd: [],
      isInputErrorVisible: false,
      inputErrorMessage: 'La saisie est invalide',
      inputErrorMessageDelay: 5000
    }
  },
  props: {
    title: String
  },
  computed: {
    ...mapGetters([
      'filterReferential',
      'selectedJobApplicationsIds'
    ])

  },
  methods: {
    ...mapActions([
      'addTagsToJobApplications'
    ]),
    askForConfirmation (rawInput) {
      this.tagsToAdd = getTagsToAdd(rawInput)

      if (this.tagsToAdd.length < 1) {
        this.showInputErrorMessage()
        return
      }
      this.confirmationMessage = this.tagsToAdd.length > 1
        ? `Voulez vous ajouter aux candidatures séléctionnées les tags "${this.tagsToAdd.join('", "')}" ?`
        : `Voulez vous ajouter aux candidatures séléctionnées le tag "${this.tagsToAdd[0]}" ?`
      this.isConfirmationVisible = true
      this.isInputErrorVisible = false
    },
    validateConfirmation () {
      this.addTagsToJobApplications({ jobApplicationIds: this.selectedJobApplicationsIds, tags: this.tagsToAdd })
      this.closeConfirmation()
      this.close()
    },
    closeConfirmation () {
      this.isConfirmationVisible = false
    },
    showInputErrorMessage () {
      this.isInputErrorVisible = true
      setTimeout(() => { this.isInputErrorVisible = false }, this.inputErrorMessageDelay)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
