<template>
    <section id="jobApplicationFilter">
      <SelectedFilters />
      <transition name="filters-fade">
        <AdvancedFilter v-if="isFiltersPanelDisplayed" />
      </transition>
    </section>
</template>

<script>
import SelectedFilters from '@/components/jobApplications/filters/SelectedFilters.vue'
import AdvancedFilter from '@/components/jobApplications/filters/AdvancedFilter.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'JobApplicationFilter',
  computed: {
    ...mapGetters([
      'isFiltersPanelDisplayed'
    ])
  },
  components: {
    SelectedFilters,
    AdvancedFilter
  }
}
</script>
