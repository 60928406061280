<template>
  <div class="talentApplyFiabilityComponent">
    <p>{{ fiability }}%
      <v-icon v-if="!jobApplicationIsSent && !targetFiabilityIsReached" name="redo"
        class="redo"
        :class="recalculateFiabilityAuthorized ? 'redo' : 'redo disabled'"
        v-tooltip.bottom="recalculateFiabilityAuthorized ? 'Relancer un calcul du score' : ''"
        @click.stop="debouncedCalculateFiability"/>
    </p>
  </div>
</template>

<script>
import { JobApplication } from '@/models/JobApplication'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TalentApplyFiabilityComponent',
  props: {
    jobApplication: { type: JobApplication, required: true }
  },
  data: function () {
    return {
      recalculateFiabilityAuthorized: true,
      fiability: this.jobApplication.TalentApply.Fiability,
      jobApplicationIsSent: this.jobApplication.TalentApply.IsSent
    }
  },
  computed: {
    ...mapGetters([
      'talentApplyConfiguration'
    ]),
    targetFiabilityIsReached () {
      return this.jobApplication.TalentApply.Fiability >= this.talentApplyConfiguration.FiabilityThreshold
    }
  },
  methods: {
    ...mapActions([
      'calculateTalentApplyJobApplicationFiability'
    ]),
    async debouncedCalculateFiability () {
      if (!this.timeout) {
        this.recalculateFiabilityAuthorized = false
        const fiability = await this.calculateTalentApplyJobApplicationFiability(this.jobApplication)
        this.$emit('fiability-change', fiability)
        this.fiability = fiability

        this.timeout = setTimeout(() => {
          this.timeout = null
          this.recalculateFiabilityAuthorized = true
        }, 5000)
      }
    }
  }
}
</script>
