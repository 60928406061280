<template>
  <div class="attachmentButton">
    <p v-if="!this.attachment"></p>
    <a v-else-if="this.isPdf" @click="openAttachment"><v-icon class="pdf" name="file-pdf" /></a>
    <a v-else-if="this.isDocument" @click="downloadAttachment" download><v-icon class="doc" name="file-word" /></a>
    <a v-else-if="this.isImage" @click="openAttachment"><v-icon class="images" name="file-image" /></a>
    <a v-else @click="downloadAttachment" download><v-icon class="autres" name="file-alt" /></a>
  </div>
</template>
<script>
import JobApplicationAttachment from '@/models/JobApplicationAttachment'
import { mapActions } from 'vuex'

export default {
  name: 'AttachmentButton',
  props: {
    attachment: JobApplicationAttachment,
    jobApplicationId: String
  },
  methods: {
    ...mapActions(['generateAttachmentBlobUrl', 'openJobApplicationOnResumeDownload']),
    openAttachment (e) {
      e.stopPropagation()
      this.openJobApplicationOnResumeDownload(this.jobApplicationId)
      this.generateAttachmentBlobUrl(this.attachment).then(attachmentBlobUrl => {
        window.open(attachmentBlobUrl.BlobUrl)
      })
    },
    downloadAttachment (e) {
      e.stopPropagation()
      this.openJobApplicationOnResumeDownload(this.jobApplicationId)
      this.generateAttachmentBlobUrl(this.attachment).then(attachmentBlobUrl => {
        const fileLink = document.createElement('a')
        fileLink.href = attachmentBlobUrl.BlobUrl
        fileLink.setAttribute('download', this.attachment.FileName)
        fileLink.click()
      })
    }
  },
  computed: {
    isPdf () {
      return this.attachment.ContentType === 'application/pdf'
    },
    isDocument () {
      const documentContentTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf']
      return documentContentTypes.find(x => x === this.attachment.ContentType) !== undefined
    },
    isImage () {
      const imageContentTypes = ['image/png', 'image/jpeg']
      return imageContentTypes.find(x => x === this.attachment.ContentType) !== undefined
    }
  }
}
</script>
