<template>
    <section id="notifications">
      <Notification :messageModelType="-1" :isEditable="false" @openModal="openModalNotificationModel"/>
      <Notification :messageModelType="0" :isEditable="true" @openModal="openModalNotificationModel"/>
      <Notification :messageModelType="4" :isEditable="true" @openModal="openModalNotificationModel"/>
      <Notification :messageModelType="3" :isEditable="true" @openModal="openModalNotificationModel"/>
      <transition name="modal-fade">
        <ModalMessageModel v-if="isModalNotificationModelVisible" :type="messageModelActiveType" :messageModel="messageModelActive" :isFromCustom="false" @close="closeModal()"/>
      </transition>
    </section>
</template>
<script> // TODO:  changer les boutons radio en toggle
import Notification from '@/components/Administration/Notification.vue'
import ModalMessageModel from '@/components/modals/ModalMessageModel'
import MessageModel from '@/models/MessageModel.ts'
export default {
  name: 'Notifications',
  components: {
    Notification,
    ModalMessageModel
  },
  data () {
    return {
      isModalNotificationModelVisible: false,
      messageModelActiveType: 0,
      messageModelActive: MessageModel
    }
  },
  methods: {
    openModalNotificationModel (messageModelType, messageModel) {
      this.messageModelActiveType = messageModelType
      this.messageModelActive = messageModel
      this.isModalNotificationModelVisible = true
    },
    closeModal () {
      this.isModalNotificationModelVisible = false
      this.messageModelActive = undefined
    }
  }
}
</script>
