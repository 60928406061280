import Vue from 'vue'
import Axios from 'axios'
import appSettings from '@/plugins/appSettings'
import { JobApplicationFilterReferential } from '@/models/JobApplicationFilterReferential'
import { JobApplicationStatus } from '@/models/JobApplicationStatus'

Vue.use(appSettings)
const ROUTE = `${Vue.appSettings.jamApiBaseUrl}/referential`

export default {
  getJobApplicationFilterReferential () {
    return Axios.get(`${ROUTE}/JobApplicationFilter`)
      .then(response => new JobApplicationFilterReferential().initialize(response.data.referential))
  },
  getJobApplicationStatuses () {
    return Axios.get(`${ROUTE}/Statuses`)
      .then(response => response.data.statuses.map((s: any) => new JobApplicationStatus().initialize(s)))
  }
}
