<template>
  <div class="rating">
    <div ref="ratingEl" class="vue-stars" :class="{ readonly: readonly , notouch: notouch}">
      <input :id="name + '0'" :checked="rating === 0" :name="name" type="radio" value="0" />
      <template v-for="x in 5">
        <label :key="'k' + x" :for="name + x">
        <span class="active">
        <slot name="activeLabel"><v-icon name="star" /></slot>
        </span>
        <span class="inactive">
        <slot name="inactiveLabel"><v-icon name="star" /></slot>
        </span>
        </label>
          <input
          :id="name + x"
          :key="'i' + x"
          :checked="rating === x"
          :name="name"
          :disabled="readonly"
          :value="x"
          type="radio"
          @change="updateInput($event.target.value)"
          />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Rating',
  props: {
    rating: { type: Number, required: false, default: 0 },
    readonly: { type: Boolean, required: false, default: false },
    name: { type: String, required: false }
  },
  computed: {
    notouch () {
      return typeof document !== 'undefined' && !('ontouchstart' in document.documentElement)
    }
  },
  methods: {
    updateInput (count) {
      if (this.rating !== count) {
        this.$emit('updateRating', count)
      }
    }
  }
}
</script>
