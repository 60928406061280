<template>
  <div class="main-section">
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
      :style="isDragging && 'border-color: green;'"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.docx"
      />

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">Lâchez pour ajouter vos fichiers ici.</div>
        <div v-else>Déposez les fichiers ici ou <u>Cliquez ici</u> pour télécharger.</div>
      </label>

      <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
           <p>
              {{ file.name }}
            </p>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Supprimer"
            >
              <b>×</b>
            </button>
          </div>
        </div>
      </div>
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      <div class="error-message" v-if="maxSizeErrorMessage">{{ maxSizeErrorMessage }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DropFile',
  watch: {
    files: {
      handler (newFiles) {
        this.$emit('files-list-was-updated', newFiles)
      },
      deep: true
    }
  },
  data () {
    return {
      isDragging: false,
      files: [],
      errorMessage: '',
      maxSizeErrorMessage: ''
    }
  },
  computed: {
    ...mapGetters([
      'currentJobApplication'
    ])
  },
  methods: {
    ...mapActions([
      'addNewAttachments'
    ]),
    onChange () {
      const incomingFiles = Array.from(this.$refs.file.files)
      const validExtensions = ['.pdf', '.docx']
      const maxFileSize = 8 * 1024 * 1024 // 8 Mo en octets

      // Filtrer les fichiers pour inclure uniquement ceux avec les extensions valides
      const validFiles = incomingFiles.filter(file => {
        const extension = file.name.split('.').pop().toLowerCase()
        return validExtensions.includes('.' + extension)
      })

      // Filtrer les fichiers pour inclure uniquement ceux avec les extensions non valides
      const invalidFiles = incomingFiles.filter(file => {
        const extension = file.name.split('.').pop().toLowerCase()
        return !validExtensions.includes('.' + extension)
      })

      if (invalidFiles.length > 0) {
        this.errorMessage = 'Certains fichiers sélectionnés ne sont pas autorisés. Veuillez sélectionner uniquement des fichiers avec les extensions .pdf ou .docx.'
      } else {
        this.errorMessage = ''
      }

      // Vérifier la taille des fichiers
      const oversizedFiles = validFiles.filter(file => file.size > maxFileSize)
      if (oversizedFiles.length > 0) {
        this.maxSizeErrorMessage = 'Certains fichiers sélectionnés dépassent la taille maximale autorisée (8 Mo). Veuillez sélectionner des fichiers plus petits.'
        this.$emit('max-size-error', true) // Émettre un événement pour informer le parent
        return
      } else {
        this.maxSizeErrorMessage = '' // Réinitialiser le message d'erreur si la taille est acceptable
        this.$emit('max-size-error', false) // Émettre un événement pour informer le parent
      }

      // Vérifier les doublons uniquement parmi les nouveaux fichiers ajoutés lors de cette sélection
      const newFiles = validFiles.filter(newFile =>
        !this.files.some(existingFile =>
          newFile.name === existingFile.name && newFile.size === existingFile.size
        )
      )
      // Ajouter les nouveaux fichiers à la liste existante au lieu de les remplacer
      this.files = [...this.files, ...newFiles]
      // Vérifier si des fichiers en double ont été ajoutés
      if (newFiles.length !== validFiles.length) {
        this.showMessage = true
        this.errorMessage = 'Le nouvel ajout contient un/des fichier(s) qui existe(nt) déjà.'
      }
    },
    dragover (e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave () {
      this.isDragging = false
    },
    drop (e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
    remove (index) {
    // Supprimer le fichier de la liste files
      this.files.splice(index, 1)
    }
  }
}
</script>
