<template>
    <section id="talentApplyAdmin" >
      <h1 class="title">TalentApply</h1>
      <span>TalentApply est <b v-if="isTalentApplyEnabled">activé</b><b v-else>désactivé</b>.</span>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TalentApplyAdmin',
  computed: {
    ...mapGetters([
      'isTalentApplyEnabled'
    ])
  }
}
</script>
