export class JobOffer {
    public Id: string
    public Reference: string
    public JobTitle: string
    public JobLocation: string

    constructor () {
      this.Id = ''
      this.Reference = ''
      this.JobTitle = ''
      this.JobLocation = ''
    }

    initialize (jobOfferJsonResult: any) {
      this.Id = jobOfferJsonResult.id
      this.Reference = jobOfferJsonResult.reference
      this.JobTitle = jobOfferJsonResult.jobTitle
      this.JobLocation = jobOfferJsonResult.jobLocation
      return this
    }
}
