<template>
  <div class="chip" @click="onChipClick">
    <span class="display-name" v-if="displayName">{{ displayName }} : </span><span>{{ value }}</span>
    <span class="closebtn" @click.stop="deleteChip()"><v-icon name="times-circle"></v-icon></span>
   </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    name: { type: String, required: false },
    displayName: { type: String, required: false },
    value: String
  },
  methods: {
    deleteChip () {
      if (this.name != null) {
        this.$emit('deleteChip', { name: this.name, value: this.value })
      } else {
        this.$emit('deleteChip', this.value)
      }
    },
    onChipClick () {
      this.$emit('onChipClick')
    }
  }
}
</script>
