<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content" :style="this.modalSize">
      <h1>{{ this.title }}</h1>
      <div class="content">
        <input v-model="receivers" class="input-text-default" type="text" @blur="validEmail" placeholder="Adresse du destinataire">
        <p v-if="!isReceiverValid" class="error-message">L'adresse email est obligatoire</p>
        <input v-model="messageObject" class="input-text-default" type="text" placeholder="Sujet">
        <p v-if="!isObjectValid" class="error-message">Le sujet est obligatoire</p>
        <jodit-editor v-model="messageContent" placeholder="Message" height= "400"/>
        <p v-if="!isMessageValid" class="error-message">Un message est obligatoire</p>
        <div class="attachmentList">
          <div class="attachment" v-for="(attachment,index) in this.attachmentList" :key="index" :title="attachment.FileName">
            <span class="attachmentButton icon" v-if="isPdf(attachment.ContentType)">
              <v-icon class="pdf" name="file-pdf" />
            </span>
            <span class="attachmentButton icon" v-else-if="isDocument(attachment.ContentType)">
              <v-icon class="doc" name="file-word" />
            </span>
            <span class="attachmentButton icon" v-else-if="isImage(attachment.ContentType)">
              <v-icon class="images" name="file-image" />
            </span>
            <span class="attachmentButton icon" v-else-if="isArchive(attachment.ContentType)">
              <v-icon class="archive" name="file-archive" />
            </span>
            <span class="attachmentButton icon" v-else>
              <v-icon class="autres" name="file-alt" />
            </span>
            <p>{{buildAttachmentName(attachment)}}</p>
          </div>
        </div>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @mousedown="transferMessage">Envoyer</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { JoditEditor } from 'jodit-vue'
import { JobApplicationAttachment } from '@/models/JobApplicationAttachment'
export default {
  name: 'ModalTransfer',
  components: {
    JoditEditor
  },
  data () {
    return {
      messageObject: '',
      messageContent: '',
      receivers: '',
      isReceiverValid: true,
      isObjectValid: true,
      isMessageValid: true,
      attachmentList: [],
      messageText: ''
    }
  },
  props: {
    title: String,
    width: Number,
    height: Number
  },
  computed: {
    ...mapGetters([
      'currentJobApplication',
      'jobApplications',
      'selectedJobApplicationsIds'
    ]),
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  watch: {
    currentJobApplication () {
      this.attachmentList = this.currentJobApplication.Attachments
    },
    messageContent () {
      this.messageText = this.messageContent
    }
  },
  mounted () {
    if (this.currentJobApplication.Id !== '') {
      this.jobApplicationTransferMessage()
    } else if (this.selectedJobApplicationsIds.length > 0) {
      this.jobApplicationsTransferMessage()
    }
  },
  methods: {
    ...mapActions([
      'transferJobApplication'
    ]),
    transferMessage () {
      const emailReceiversSanitized = this.validEmail()
      if (this.receivers && this.messageObject && this.messageContent) {
        const message = {
          receivers: emailReceiversSanitized,
          messageObject: this.messageObject,
          messageContent: this.messageContent,
          jobApplicationIds: this.selectedJobApplicationsIds
        }
        this.transferJobApplication(message)
        this.close()
      } else {
        this.isReceiverValid = emailReceiversSanitized
        this.isObjectValid = this.messageObject
        this.isMessageValid = this.messageContent
      }
    },
    jobApplicationTransferMessage () {
      // TODO : Messages en dur à déplacer
      this.attachmentList = this.currentJobApplication.Attachments
      const hello = '<p>Bonjour,</p><p> Veuillez trouver ci-joint la candidature suivante :</p><p>'
      const applicant = this.currentJobApplication.Applicant.Fullname
      const jobTitle = this.currentJobApplication.JobOffer.JobTitle
      const regards = '</p><p>Cordialement</p>'
      this.messageContent = hello.concat('    - ', applicant, ' - ', jobTitle, regards)
      this.messageObject = 'Transfert de candidat depuis votre gestion de candidature Talentplug'
    },
    jobApplicationsTransferMessage () {
      // TODO : Messages en dur à déplacer
      this.messageContent = '<p>Bonjour,</p><p> Veuillez trouver ci-joint les candidatures suivantes :</p>'
      const regards = '<p>Cordialement</p>'
      this.messageObject = 'Transfert des candidats depuis votre gestion de candidature Talentplug'
      this.selectedJobApplicationsIds.forEach(id => {
        const jobApplication = this.jobApplications.find(ja => ja.Id === id)
        this.messageContent = this.messageContent.concat('<p>    - ', jobApplication.Applicant.Fullname, ' - ', jobApplication.JobOffer.JobTitle, '</p>')
        if (jobApplication.Attachments.length > 0) {
          const archiveAttachment = new JobApplicationAttachment()
          archiveAttachment.FileName = jobApplication.Applicant.Fullname
          archiveAttachment.ContentType = 'application/zip'
          this.attachmentList.push(archiveAttachment)
        }
      })
      this.messageContent = this.messageContent.concat(regards)
    },
    close () {
      this.$emit('close')
      this.messageObject = ''
      this.messageContent = ''
      this.receivers = ''
      this.isReceiverValid = true
      this.isObjectValid = true
      this.isMessageValid = true
      this.attachmentList = []
    },
    sanitizeEmail () {
      return this.receivers.split(';').map(receiver => receiver.replace(/[^!-~]+/g, ''))
    },
    validEmail () {
      // TODO : Regex à paramétrer
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return this.sanitizeEmail().map(emailReceiver => regexEmail.test(emailReceiver) ? emailReceiver : null).filter(emailReceiver => emailReceiver !== null)
    },
    isPdf (contentType) {
      return contentType === 'application/pdf'
    },
    isDocument (contentType) {
      return contentType === 'application/msword' ||
        contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        contentType === 'application/rtf'
    },
    isImage (contentType) {
      return contentType === 'application/msword' ||
        contentType === 'image/png' ||
        contentType === 'image/jpeg'
    },
    isArchive (contentType) {
      return contentType === 'application/zip'
    },
    buildAttachmentName (attachment) {
      if (attachment.FileName.length > 13) {
        const fileName = attachment.FileName.slice(0, 13)
        return fileName.concat('...')
      } else {
        return attachment.FileName
      }
    }
  }
}
</script>
