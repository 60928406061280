<template>
  <div v-if="jobApplication.Id" class="panel cartouche">
    <div class="primary-data">
      <div class="name">
        <span v-if="!isModifyingNames">
          <h1 v-tooltip.top-center="applicantFullName">{{ applicantFullName }}</h1>
          <v-icon class="iconModify" name="pencil-alt" title="Edit" @click="openInputModifyingNames"/>
        </span>
        <span v-if="isModifyingNames" class="applicant-name-input">
          <input class="input-text-default first-name-input" ref="firstNameInput" placeholder="Prénom" type="text" v-model="applicantFirstName" @keyup.enter="showModal('names')" @blur="closeInputModifyingNames">
          <input class="input-text-default" ref="lastNameInput" placeholder="Nom" type="text" v-model="applicantLastName" @keyup.enter="showModal('names')" @blur="closeInputModifyingNames">
          <button class="button-default" @mousedown="showModal('names')"><v-icon name="check" /></button>
        </span>
        <Rating id="rating" @updateRating="updateRating" :rating="Number(jobApplication.Rating)" :readonly="false" name="cartouche" />
      </div>
      <div>a répondu à votre offre</div>
      <span class="job-title"><v-icon class="icon" name="briefcase" title="Poste"/> {{ jobApplication.JobOffer.JobTitle }}</span>
    </div>
    <div class="detail">
      <span class="detail-item"><v-icon class="icon" name="clock" title="Date"/> {{ jobApplicationDate }}</span>
      <span class="detail-item"><v-icon class="icon" name="clipboard" title="Reference"/> {{ jobApplication.JobOffer.Reference || 'Référence manquante' }}</span>
      <span class="detail-item"><v-icon class="icon" name="external-link-alt"  title="Source"/> {{ jobApplication.Source }}</span>
      <div class="email detail-item">
        <v-icon class="icon" name="envelope"  title="Email"/>
        <span v-show="!isModifyingMail">
          {{ applicantEmail }}
          <v-icon class="iconModify" name="pencil-alt" title="Edit" @click="openInputModifyingEmail"/>
        </span>
        <span class="email-input" v-show="isModifyingMail">
          <input class="input-text-default input-text-default-small" type="text" ref="email" v-model="applicantEmail" @keyup.enter="showModal('email')" @blur="closeInputModifyingEmail">
          <button class="button-default button-default-small" @mousedown="showModal('email')"><v-icon name="check" /></button>
        </span>
      </div>
    </div>
    <RecruitmentStatus :jobApplication="jobApplication" @updateStatus = "updateStatus"/>
    <transition name="modal-fade">
      <ModalConfirmation v-show="isModalNameUpdateConfirmVisible"
      @close="closeModal('names')" @confirm="updateApplicantNames"
      :title="'Modifier le nom du candidat'"
      :message="confirmNameMessage"
      :width="380" :height="170" />
    </transition>
    <transition name="modal-fade">
      <ModalConfirmation v-show="isModalEmailUpdateConfirmVisible"
      @close="closeModal('email')" @confirm="updateApplicantEmail"
      :title="'Modifier l\'email du candidat'"
      :message="confirmEmailMessage"
      :width="380" :height="170" />
    </transition>
  </div>
</template>

<script>
import Rating from '@/components/global/Rating.vue'
import ModalConfirmation from '@/components/modals/ModalConfirmation'
import RecruitmentStatus from '@/components/jobApplication/RecruitmentStatus'
import JobApplication from '@/models/JobApplication'
import { mapGetters } from 'vuex'

export default {
  name: 'Cartouche',
  data () {
    return {
      jobApplicationDate: '',
      applicantFullName: '',
      applicantInitialFirstName: '',
      applicantInitialLastName: '',
      applicantFirstName: '',
      applicantLastName: '',
      applicantEmail: '',
      isModifyingNames: false,
      isModifyingMail: false,
      isModalNameUpdateConfirmVisible: false,
      isModalEmailUpdateConfirmVisible: false,
      isModalCloseApplicationConfirmVisible: false,
      isModalReopenApplicationConfirmVisible: false,
      currentChangeStatusAction: ''
    }
  },
  components: {
    Rating,
    ModalConfirmation,
    RecruitmentStatus
  },
  props: {
    jobApplication: JobApplication
  },
  mounted () {
    const [computedFirstName, ...rest] = this.jobApplication.Applicant.Fullname.trim().split(' ')
    const computedLastName = rest.join(' ')

    this.jobApplicationDate = this.$moment(this.jobApplication.Date).format('LL')
    this.applicantFullName = this.jobApplication.Applicant.Fullname
    this.applicantInitialFirstName = this.jobApplication.Applicant.FirstName == null || this.jobApplication.Applicant.FirstName === '' ? computedFirstName : this.jobApplication.Applicant.FirstName
    this.applicantInitialLastName = this.jobApplication.Applicant.LastName == null || this.jobApplication.Applicant.LastName === '' ? computedLastName : this.jobApplication.Applicant.LastName
    this.applicantFirstName = this.applicantInitialFirstName
    this.applicantLastName = this.applicantInitialLastName
    this.applicantEmail = this.jobApplication.Applicant.Email
  },
  watch: {
    'jobApplication.Applicant.Fullname' () {
      this.applicantFullName = this.jobApplication.Applicant.Fullname
    },
    'jobApplication.Applicant.FirstName' () {
      this.applicantInitialFirstName = this.jobApplication.Applicant.FirstName
      this.applicantFirstName = this.jobApplication.Applicant.FirstName
    },
    'jobApplication.Applicant.LastName' () {
      this.applicantInitialLastName = this.jobApplication.Applicant.LastName
      this.applicantLastName = this.jobApplication.Applicant.LastName
    },
    'jobApplication.Applicant.Email' () {
      this.applicantEmail = this.jobApplication.Applicant.Email
    },
    'jobApplication.Date' () {
      this.jobApplicationDate = this.$moment(this.jobApplication.Date).format('LL')
    }
  },
  computed: {
    ...mapGetters([
      'isRecruitmentStepLocked'
    ]),
    confirmNameMessage () {
      return 'Êtes-vous sur de vouloir mettre à jour le nom du candidat par ' + this.applicantFirstName + ' ' + this.applicantLastName + ' ?'
    },
    confirmEmailMessage () {
      return 'Êtes-vous sur de vouloir mettre à jour l\'adresse email du candidat par ' + this.applicantEmail + ' ?'
    }
  },
  methods: {
    showModal (type) {
      if (type === 'names') {
        this.isModalNameUpdateConfirmVisible = true
      } else if (type === 'email') {
        this.isModalEmailUpdateConfirmVisible = true
      }
    },
    closeModal (type) {
      if (type === 'names') {
        this.isModalNameUpdateConfirmVisible = false
        this.closeInputModifyingNames()
      } else if (type === 'email') {
        this.isModalEmailUpdateConfirmVisible = false
        this.closeInputModifyingEmail()
      }
    },
    updateRating (rating) {
      this.$emit('updateRating', rating)
    },
    openInputModifyingNames () {
      this.isModifyingNames = true
      this.$nextTick(() => this.$refs.firstNameInput.focus())
    },
    openInputModifyingEmail () {
      this.isModifyingMail = true
      this.$nextTick(() => this.$refs.email.focus())
    },
    closeInputModifyingNames (e) {
      if (e && (e.relatedTarget === this.$refs.firstNameInput || e.relatedTarget === this.$refs.lastNameInput)) {
        return
      }
      if (!this.isModalNameUpdateConfirmVisible) {
        if (this.applicantInitialLastName !== this.applicantLastName.trim() || this.applicantInitialFirstName !== this.applicantFirstName.trim()) {
          this.applicantLastName = this.applicantInitialLastName
          this.applicantFirstName = this.applicantInitialFirstName
        }
        this.isModifyingNames = false
      }
    },
    closeInputModifyingEmail () {
      if (!this.isModalEmailUpdateConfirmVisible) {
        if (this.jobApplication.Applicant.Email !== this.applicantEmail) {
          this.applicantEmail = this.jobApplication.Applicant.Email
        }
        this.isModifyingMail = false
      }
    },
    updateApplicantNames () {
      if (this.jobApplication.Applicant.FirstName !== this.applicantFirstName.trim() || this.jobApplication.Applicant.LastName !== this.applicantLastName.trim()) {
        this.$emit('updateApplicantNames', { jobApplicationId: this.jobApplication.Id, firstName: this.applicantFirstName.trim(), lastName: this.applicantLastName.trim() })
      }
      this.isModifyingNames = false
      this.closeModal('names')
    },
    updateApplicantEmail () {
      if (this.jobApplication.Applicant.Email !== this.applicantEmail.trim()) {
        this.$emit('updateApplicantEmail', { jobApplicationId: this.jobApplication.Id, email: this.applicantEmail.trim() })
      }
      this.isModifyingMail = false
      this.closeModal('email')
    },
    updateStatus (action) {
      this.$emit('updateStatus', action)
    }
  }
}
</script>
