<template>
  <section id="job_application_talent_apply" class="panel">
    <h1>TalentApply</h1>
    <li>
      <div class="field">Scoring : </div>
      <TalentApplyFiabilityComponent :jobApplication="jobApplication"
        @fiability-change="FiabilityChange"
        :key="fiabilityKey" />
    </li>
    <li>
      <div class="field">Transfert ATS : </div>
      <ATSPostingStatus :jobApplication="jobApplication"
        @ats-posting-status-change="ATSPostingStatusChange"
        :key="atsPostingStatusKey" />
    </li>
  </section>
</template>

<script>
import { JobApplication } from '@/models/JobApplication'
import TalentApplyFiabilityComponent from '@/components/jobApplication/TalentApplyFiabilityComponent.vue'
import ATSPostingStatus from '@/components/jobApplication/ATSPostingStatus.vue'

export default {
  name: 'TalentApplyCard',
  data: function () {
    return {
      fiabilityKey: 0,
      atsPostingStatusKey: 0
    }
  },
  props: {
    jobApplication: { type: JobApplication, required: true }
  },
  components: {
    TalentApplyFiabilityComponent,
    ATSPostingStatus
  },
  methods: {
    ATSPostingStatusChange (status) {
      this.jobApplication.TalentApply.IsSent = status
      this.fiabilityKey++
    },
    FiabilityChange (fiability) {
      this.jobApplication.TalentApply.Fiability = fiability
      this.atsPostingStatusKey++
    }
  }
}
</script>
