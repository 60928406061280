<template>
  <section class="modal modal-comments" @click.self="close">
    <div class="modal-content">
      <v-icon class="icon-close" name="times-circle" @click="close"/>
      <h1>{{ this.title }}</h1>
      <div class="content">
        <ul class="comment-list-scrollable" v-if="displayedComments.length > 0">
          <li v-for="comment in displayedComments" :key="comment.Id">
            <div class="comment-info">{{ comment.UserName }}, le {{ comment.Date | moment('LL') }} à {{ comment.Date | moment('LT') }}</div>
            <div class="comments-container">
              <div class="comment-element">{{ comment.Content }}</div>
            </div>
          </li>
        </ul>
        <p v-else>Pas de commentaire</p>
        <CommentsInput ref="commentsInput" @commentInputButtonWasClicked="handleSubmitNewComment" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CommentsInput from '@/components/jobApplication/CommentsInput'
export default {
  name: 'ModalComments',
  props: {
    title: String
  },
  components: {
    CommentsInput
  },
  computed: {
    ...mapGetters([
      'currentJobApplication'
    ]),
    displayedComments () {
      const comments = this.currentJobApplication.Comments.slice()
      comments.sort(function (a, b) {
        return new Date(a.Date) - new Date(b.Date)
      })
      comments.reverse()
      return comments
    }
  },
  methods: {
    ...mapActions([
      'addComment'
    ]),
    close () {
      this.$emit('close')
    },
    handleSubmitNewComment (comment, event) {
      if ((event.key === 'Enter' && !event.shiftKey) || event.type === 'click') {
        if (comment.trim()) {
          this.addComment(comment)
          // Réinitialiser la valeur de newComment dans le composant CommentsInput
          this.$refs.commentsInput.newComment = ''
        }
      }
    }
  }
}
</script>
