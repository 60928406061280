<template>
    <section id="emailTracking" >
      <h1 class="title">Adresses de tracking</h1>
      <div class="row">
        <label>Adresse mail</label>
        <input type="text" class="input-text-default" v-model="emailTrackingAddress" readonly disabled>
      </div>
      <div class="row">
        <label>Adresse URL</label>
        <input type="text" class="input-text-default" v-model="emailTrackingUrl" readonly disabled>
      </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'EmailTracking',
  computed: {
    ...mapGetters([
      'emailTrackingAddress',
      'emailTrackingUrl'
    ])
  }
}
</script>
