<template>
  <div class="container">
    <MasterHeader></MasterHeader>
    <MasterMenu/>
    <MasterBody></MasterBody>
    <MasterFooter></MasterFooter>
  </div>
</template>

<script>
import MasterHeader from '@/layouts/parts/MasterHeader.vue'
import MasterBody from '@/layouts/parts/MasterBody.vue'
import MasterFooter from '@/layouts/parts/MasterFooter.vue'
import MasterMenu from '@/layouts/parts/MasterMenu'

export default {
  name: 'Layout',
  components: {
    MasterHeader,
    MasterBody,
    MasterFooter,
    MasterMenu
  }
}
</script>
