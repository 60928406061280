<template>
  <section id="administration_view">
    <h1 class="title" tabindex="-1">Gérer les notifications</h1>
    <Notifications/>
    <DuplicatesAdmin/>
    <CustomEmailModels/>
    <Stakeholders/>
    <CleaningApplications/>
    <EmailTracking/>
    <TalentApplyAdmin/>
  </section>
</template>

<script>
import Notifications from '@/components/Administration/Notifications.vue'
import DuplicatesAdmin from '@/components/Administration/DuplicatesAdmin.vue'
import Stakeholders from '@/components/Administration/Stakeholders.vue'
import CustomEmailModels from '@/components/Administration/CustomEmailModels.vue'
import EmailTracking from '@/components/Administration/EmailTracking.vue'
import CleaningApplications from '@/components/Administration/CleaningApplications.vue'
import TalentApplyAdmin from '@/components/Administration/TalentApplyAdmin.vue'

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Administration',
  components: {
    Notifications,
    DuplicatesAdmin,
    Stakeholders,
    CustomEmailModels,
    EmailTracking,
    CleaningApplications,
    TalentApplyAdmin
  },
  methods: {
    ...mapActions([
      'getTamConfiguration'
    ])
  },
  created () {
    this.getTamConfiguration()
  }
}
</script>
