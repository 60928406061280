import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import JobApplications from '@/views/JobApplications.vue'
import JobApplication from '@/views/JobApplication.vue'
import Administration from '@/views/Administration.vue'
import UserProfile from '@/views/UserProfile.vue'
import Statistics from '@/views/Statistics.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/job-applications'
  },
  {
    path: '/job-applications',
    name: 'JobApplications',
    component: JobApplications
  },
  {
    path: '/job-applications/jobOfferId/:jobOfferId',
    name: 'JobApplications',
    component: JobApplications
  },
  {
    path: '/job-applications/jobOfferPublicationId/:jobOfferPublicationId',
    name: 'JobApplications',
    component: JobApplications
  },
  {
    path: '/job-application/:id',
    name: 'JobApplication',
    component: JobApplication
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/admin',
    name: 'Administration',
    component: Administration
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile
  }
]

const router = new VueRouter({
  routes
})

export default router
