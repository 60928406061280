export class Stakeholder {
    public Id: number
    public CompanyId: number
    public Name: string
    public Email: string

    constructor () {
      this.Id = -1
      this.CompanyId = -1
      this.Name = ''
      this.Email = ''
    }

    initialize (stakeholderResult: any) {
      this.Id = stakeholderResult.id
      this.CompanyId = stakeholderResult.companyId
      this.Name = stakeholderResult.name
      this.Email = stakeholderResult.email
      return this
    }
}
