<template>
  <div id="tag_panel" class="panel">
    <h1>Tags</h1>
    <div v-if="tags.length > 0">
      <ul>
        <li v-for="tag in tags" :key="tag">
          <Chip :value="addMissingHashtag(tag)" @deleteChip="deleteTag" @onChipClick="clickOnTag(tag)"></Chip>
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Pas de tag</p>
    </div>
    <div class="input-tag">
      <InputAutocomplete
        :autoCompleteValues='filterReferential.Tags'
        :minimumCharsBeforeAutoComplete="0"
        className='filter-input'
        placeholder='Ajouter un tag ...'
        @valueSelected='addTags'
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getNewTagsToAdd } from '@/mixins/tagsHelper'
import Chip from '@/components/global/Chip.vue'
import InputAutocomplete from '@/components/_generic/InputAutocomplete'

export default {
  name: 'Tags',
  components: {
    Chip,
    InputAutocomplete
  },
  props: {
    tags: Array
  },
  methods: {
    addTags (rawTags) {
      const tagsToAdd = getNewTagsToAdd(rawTags, this.tags)
      if (tagsToAdd && tagsToAdd.length) {
        tagsToAdd.forEach(tagToAdd => {
          this.$emit('addTag', tagToAdd)
        })
      }
    },
    addMissingHashtag (tag) {
      if (tag && tag.length > 0 && tag[0] !== '#') {
        tag = '#' + tag
      }
      return tag
    },
    removeHashtag (tag) {
      if (tag && tag.startsWith('#')) {
        tag = tag.substring(1)
      }
      return tag
    },
    deleteTag (tag) {
      this.$emit('deleteTag', this.removeHashtag(tag))
    },
    clickOnTag (tag) {
      this.$router.push({ name: 'JobApplications', params: { tag: this.removeHashtag(tag) } })
    }
  },
  computed: {
    ...mapGetters([
      'filterReferential'
    ])
  }
}
</script>
