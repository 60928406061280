<template>
  <section class="modal">
    <div class="modal-content" :style="this.modalSize">
      <h1>Configurer le nettoyage de candidatures</h1>
      <label>Entrer le nombre de mois (entre 12 et 24 ) <v-icon name="info-circle" v-tooltip.top-center="'<p>Les candidatures seront archivées automatiquement </br>en fonction du nombre de mois renseigné</p>'"/> :</label>
      <input class="input-text-default" type="number" v-model="newDefaultCleaningApplications" min="12" max="24">
      <p v-if="messageDefaultCleaningApplicationsError" class="error-message">{{messageDefaultCleaningApplicationsError}}</p>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @mousedown="editDefaultCleaningApplications">Valider</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ModalEditCleaningApplications',
  data () {
    return {
      newDefaultCleaningApplications: 24,
      messageDefaultCleaningApplicationsError: ''
    }
  },
  props: {
    width: Number,
    height: Number,
    defaultCleaningApplications: Number
  },
  mounted () {
    if (this.defaultCleaningApplications !== undefined) {
      this.newDefaultCleaningApplications = this.defaultCleaningApplications
    } else {
      this.newDefaultCleaningApplications = 24
    }
  },
  computed: {
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  methods: {
    ...mapActions([
      'updateDefaultCleaningApplications'
    ]),
    editDefaultCleaningApplications () {
      if (this.validDefaultCleaningApplications()) {
        this.updateDefaultCleaningApplications(this.newDefaultCleaningApplications)
        this.close()
      }
    },
    close () {
      this.messageDefaultCleaningApplicationsError = ''
      this.$emit('close')
    },
    validDefaultCleaningApplications () {
      this.messageDefaultCleaningApplicationsError = ''
      if (!this.newDefaultCleaningApplications) {
        this.messageDefaultCleaningApplicationsError = 'Veuillez saisir un nombre de mois '
      } else if (this.newDefaultCleaningApplications < 12 || this.newDefaultCleaningApplications > 24) {
        this.messageDefaultCleaningApplicationsError = 'Le nombre de mois doit être compris entre 12 et 24'
      }
      return !this.messageDefaultCleaningApplicationsError
    }
  }
}
</script>
