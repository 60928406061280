<template>
  <div>
    <v-popover trigger="hover" @show="loadJobApplicationCounter" placement="left-start" offset="10">
        <div class="result">
          <v-icon name="address-card" />
          <span v-if="jobApplicationCount.Unread" class="notify-badge" />
        </div>
        <div slot="popover" id="popover_recruitment_counts">
            <ul>
              <li>Non lu : <span>{{ this.jobApplicationCount.Unread }}</span></li>
              <li>En cours : <span>{{ this.jobApplicationCount.Open }}</span></li>
              <li>Recruté : <span>{{ this.jobApplicationCount.Hired }}</span></li>
              <li>Non recruté : <span>{{ this.jobApplicationCount.Rejected }}</span></li>
              <li>Sélectionné : <span>{{ this.jobApplicationCount.Selected }}</span></li>
              <li class="total-count">Total : <span>{{ this.jobApplicationCount.Total }}</span></li>
              <li class="archived-count">Supprimé : <span>{{ this.jobApplicationCount.Archived }}</span></li>
            </ul>
        </div>
    </v-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JobApplicationCount',
  computed: {
    ...mapGetters(['jobApplicationCount'])
  },
  methods: {
    ...mapActions([
      'loadJobApplicationCounter'
    ])
  }
}
</script>
