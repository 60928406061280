<template>
    <section id="customEmailModels" >
      <div class="headTitle">
        <h1 class="title" tabindex="-1">Gestion des modèles d'email</h1>
        <button class= "button-default" @click="showUpsertModal(undefined)"><v-icon name="plus"/></button>
      </div>
      <table class="clickable-table">
          <thead>
          <tr>
              <th>Nom</th>
              <th>Sujet</th>
              <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="messageModel in customMessageModels" :key="messageModel.Id">
              <td>{{messageModel.Title}}</td>
              <td>{{messageModel.Subject}}</td>
              <td class="action">
                  <v-icon class="icon" @click="showUpsertModal(messageModel)" name="pencil-alt"/>
                  <v-icon class="icon" @click="showDeleteModal(messageModel)" name="trash" />
              </td>
          </tr>
          </tbody>
      </table>
      <div class="no-result" v-if="customMessageModels.length === 0">Pas de résultat</div>
      <transition name="modal-fade">
        <ModalConfirmation v-show="isModalDeleteVisible" title="Confirmation de suppression" message="Voulez vous confirmer la suppression de ce modèle ?"
        :width="500" :height="200" @confirm="validateConfirmationDelete" @close="closeConfirmationDelete"/>
      </transition>
      <transition name="modal-fade">
        <ModalMessageModel v-if="isModalNotificationModelVisible" :type="10" :messageModel="selectedMessageModel" :isFromCustom="true" @close="closeUpsertModal()"/>
      </transition>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalMessageModel from '@/components/modals/ModalMessageModel'
import ModalConfirmation from '@/components/modals/ModalConfirmation'
import MessageModel from '@/models/MessageModel.ts'
export default {
  name: 'CustomEmailModels',
  components: {
    ModalConfirmation,
    ModalMessageModel
  },
  data () {
    return {
      isModalDeleteVisible: false,
      isModalNotificationModelVisible: false,
      selectedMessageModel: MessageModel
    }
  },
  computed: {
    ...mapGetters([
      'customMessageModels'
    ])
  },
  mounted () {
    this.selectedMessageModel = this.customMessageModels
  },
  methods: {
    ...mapActions([
      'deleteCustomMessageModel'
    ]),
    showDeleteModal (selectedMessageModel) {
      this.selectedMessageModel = selectedMessageModel
      this.isModalDeleteVisible = true
    },
    closeConfirmationDelete () {
      this.isModalDeleteVisible = false
    },
    showUpsertModal (selectedMessageModel) {
      this.selectedMessageModel = selectedMessageModel
      this.isModalNotificationModelVisible = true
    },
    closeUpsertModal () {
      this.isModalNotificationModelVisible = false
    },
    validateConfirmationDelete () {
      this.deleteCustomMessageModel(this.selectedMessageModel.Id)
      this.isModalDeleteVisible = false
    }
  }
}
</script>
