export class MultiApplicationDuplicates {
  public Id: string
  public Fullname: string
  public Date: Date
  public RecruitmentStepId: string
  public Status: number
  public DuplicatesCount: number

  constructor () {
    this.Id = ''
    this.Fullname = ''
    this.Date = new Date()
    this.RecruitmentStepId = ''
    this.Status = -1
    this.DuplicatesCount = -1
  }

  initialize (multiApplicationDuplicatesJsonResult: any) {
    this.Id = multiApplicationDuplicatesJsonResult.id
    this.Fullname = multiApplicationDuplicatesJsonResult.fullname
    this.Date = multiApplicationDuplicatesJsonResult.date
    this.RecruitmentStepId = multiApplicationDuplicatesJsonResult.recruitmentStepId
    this.Status = multiApplicationDuplicatesJsonResult.status
    this.DuplicatesCount = multiApplicationDuplicatesJsonResult.duplicatesCount
    return this
  }
}
