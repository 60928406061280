import { StatisticsCompany } from './StatisticsCompany'

export class StatisticsCompaniesUserFilter {
    public Companies: StatisticsCompany[]

    constructor () {
      this.Companies = new Array<StatisticsCompany>()
    }

    initialize (data: any) {
      this.Companies = data.companies.map((c: any) => new StatisticsCompany().initialize(c))
      return this
    }
}
