const MIN_TAG_LENGTH = 2

function isMultipleInputTagsValues (rawTags: string): boolean {
  return rawTags.trim().indexOf(' ') > -1
}

function splitMultipleTagValues (originalValue: string): string[] {
  const resultValues: string[] = []
  const trimedOriginalValue = originalValue.trim()
  if (trimedOriginalValue.indexOf(' ') > -1) {
    const splittedValues = trimedOriginalValue.split(' ')
    if (splittedValues) {
      splittedValues.forEach(value => {
        const trimedValue = value.trim()
        if (trimedValue.length >= MIN_TAG_LENGTH && !resultValues.includes(trimedValue)) {
          resultValues.push(trimedValue)
        }
      })
    }
  }
  return resultValues
}

function extractTagsToAdd (rawTags: string): string[] {
  let tagsToAdd = []
  if (rawTags && isMultipleInputTagsValues(rawTags)) {
    tagsToAdd = splitMultipleTagValues(rawTags)
  } else if (rawTags && rawTags.length >= MIN_TAG_LENGTH) {
    tagsToAdd.push(rawTags)
  }
  return tagsToAdd
}

function replaceAccentedChars (str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function removeSpecialChars (str: string): string {
  return str.replace(/[^a-zA-Z0-9-_ ]/g, '')
}

function cleanTagValue (tag: string): string {
  tag = tag.trim()
  tag = replaceAccentedChars(tag)
  tag = removeSpecialChars(tag)
  return tag
}

function getNewTagsToAdd (rawInput: string, existingTags: string[]): string[] {
  const newTagsToAdd: string[] = []
  const tagsToAdd = extractTagsToAdd(rawInput)
  if (tagsToAdd && tagsToAdd.length) {
    tagsToAdd.forEach((tagToAdd: string) => {
      const cleanedTag = cleanTagValue(tagToAdd)
      const isAlreadyExist = existingTags.find(tag => tag === cleanedTag)
      if (cleanedTag !== '' && cleanedTag.length >= MIN_TAG_LENGTH && !isAlreadyExist) {
        newTagsToAdd.push(cleanedTag)
      }
    })
  }
  return newTagsToAdd
}

function getTagsToAdd (rawInput: string): string[] {
  return getNewTagsToAdd(rawInput, [])
}

export { getTagsToAdd, getNewTagsToAdd, MIN_TAG_LENGTH }
