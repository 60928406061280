export class Applicant {
  public Email: string
  public Fullname: string
  public FirstName: string
  public LastName: string

  constructor () {
    this.Email = ''
    this.Fullname = ''
    this.FirstName = ''
    this.LastName = ''
  }

  initialize (applicantJsonResult: any) {
    this.Email = applicantJsonResult.email
    this.Fullname = applicantJsonResult.fullname
    this.FirstName = applicantJsonResult.firstName
    this.LastName = applicantJsonResult.lastName
    return this
  }

  toCsv (separator: string | null): string {
    if (separator == null) {
      separator = ';'
    }
    return this.Fullname + separator + this.Email
  }
}
