<template>
  <section id='advanced_filter_panel'>
    <div class='filters-container'>
      <SelectList
        name="'entreprisesSelect'"
        :options='companiesSelectOptions'
        :headerOptionLabel="'Entreprises'"
        :resetValueAtSelection='true'
        @onChange='filterOnCompany'
      />
    </div>
    <div class='filters-container'>
      <div class='filter-input date-selectors'>
        <v-date-picker
          class='date-selector'
          :locale='locale'
          :input-props="{
            class: 'input-text-default',
            placeholder: 'À partir du',
            readonly: true}"
          v-model='fromDateValue'
          @popoverWillHide="filterOnFromDate(fromDateValue)"
          :popover="{ placement: 'bottom', visibility: 'click' }"
        >
          <input
            slot-scope="{ inputProps, inputEvents }"
            :class="'input-text-default'"
            v-bind='inputProps'
            v-on='inputEvents'/>
        </v-date-picker>
        <v-date-picker
          class='date-selector'
          :locale='locale'
          :input-props="{
            class: 'input-text-default',
            placeholder: 'Jusqu\'au',
            readonly: true}"
          v-model='toDateValue'
          @popoverWillHide="filterOnToDate(toDateValue)"
          :popover="{ placement: 'bottom', visibility: 'click' }"
        >
          <input
            slot-scope="{ inputProps, inputEvents }"
            :class="'input-text-default'"
            v-bind='inputProps'
            v-on='inputEvents'/>
        </v-date-picker>
      </div>
      <span width="50% "></span>
    </div>
  </section>
</template>

<script>
import SelectList from '@/components/_generic/SelectList'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdvancedStatisticsFilters',
  components: {
    SelectList
  },
  data () {
    return {
      toDateValue: '',
      fromDateValue: ''
    }
  },
  methods: {
    ...mapActions(['filterStatistics']),
    filterOnFromDate (date) {
      const changedFilters = this.statisticsFilter
      changedFilters.FromDate = date
      this.filterStatistics(changedFilters)
      this.fromDateValue = ''
    },
    filterOnToDate (date) {
      const changedFilters = this.statisticsFilter
      changedFilters.ToDate = date
      this.filterStatistics(changedFilters)
      this.toDateValue = ''
    },
    filterOnCompany (option) {
      const changedFilters = this.statisticsFilter
      changedFilters.CompanyId = option.currentSelectedValue
      this.filterStatistics(changedFilters)
    }
  },
  computed: {
    ...mapGetters(['statisticsCompaniesUserFilter', 'statisticsFilter']),
    locale () {
      return this.$appSettings.locale
    },
    companiesSelectOptions () {
      const selectOptions = []
      this.statisticsCompaniesUserFilter.Companies.forEach(c => selectOptions.push({ Value: c.Id, Label: c.Name }))
      return selectOptions
    }
  }
}
</script>
