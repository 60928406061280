export class RecruitmentStep {
  public Id: string
  public Name: string
  public Order: number

  constructor () {
    this.Id = ''
    this.Name = ''
    this.Order = -1
  }

  initialize (step: any) {
    if (step) {
      this.Id = step.id
      this.Name = step.name
      this.Order = step.order
    }
    return this
  }
}
