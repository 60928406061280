export class TalentApplyConfiguration {
  public TargetAts: string
  public FiabilityThreshold: number
  public TalentApplyActive: boolean
  public IsInit: boolean

  constructor () {
    this.TargetAts = ''
    this.FiabilityThreshold = 0
    this.TalentApplyActive = false
    this.IsInit = false
  }

  initialize (talentApplyConfigurationJsonResult: any) {
    this.TargetAts = talentApplyConfigurationJsonResult.targetAts
    this.FiabilityThreshold = talentApplyConfigurationJsonResult.fiabilityThreshold
    this.TalentApplyActive = talentApplyConfigurationJsonResult.talentApplyActive
    this.IsInit = true
    return this
  }
}
