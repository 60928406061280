<template>
  <section id="job_application_activities_panel" class="panel">
    <div class="activity-header">
      <h1>Activités</h1>
      <v-icon class="expand-icon" @click="openModalActivities" name="expand-alt"/>
    </div>
    <ul class="activity-list">
      <li v-for="(activity, index) in reverseActivities" :key="index">
        <div class="activity-info">{{ activity.UserName }}, le {{ activity.Date | moment('LL') }}</div>
        <div>{{ activity.Name }}</div>
      </li>
    </ul>
    <transition name="modal-fade">
      <ModalActivities v-if="isModalActivitiesVisible"
      :title="'Activités'"
      @close="closeModalActivities()"/>
    </transition>
  </section>
</template>

<script>
import ModalActivities from '@/components/modals/ModalActivities'
export default {
  name: 'Activities',
  data () {
    return {
      isModalActivitiesVisible: false
    }
  },
  components: {
    ModalActivities
  },
  props: {
    activities: Array
  },
  computed: {
    reverseActivities () {
      const activities = this.activities.slice().sort(function (a, b) {
        return a.value - b.value
      }).reverse()

      return activities.slice(0, 3)
    }
  },
  methods: {
    openModalActivities () {
      this.isModalActivitiesVisible = true
    },
    closeModalActivities () {
      this.isModalActivitiesVisible = false
    }
  }
}
</script>
