<template>
  <section>
  <div class="comment-input">
    <textarea class="text-area-default" v-model="newComment" @input="onInput" placeholder="Ajouter un commentaire ..." @keydown.enter.exact.prevent="handleEnterKey" @keydown.shift.enter.prevent="handleShiftEnterKey" :maxlength="maxCommentLength"/>
    <button class="button-default" @click="sendEnventToParent"><v-icon name="plus" /></button>
  </div>
  <p v-if="userStartedTyping && newComment.length > 0" class="comment-error-alert">{{ charactersRemaining }} caractère(s) restant(s)</p>
</section>
</template>

<script>
export default {
  name: 'CommentsInput',
  props: {
    initialComment: String
  },
  data () {
    return {
      newComment: this.initialComment || '',
      placeholderText: 'Ajouter un commentaire ...',
      userStartedTyping: false,
      maxCommentLength: 300
    }
  },
  computed: {
    charactersRemaining () {
      return this.maxCommentLength - this.newComment.length
    }
  },
  methods: {
    sendEnventToParent (event) {
      // Émettre un événement pour indiquer que le bouton a été cliqué
      this.$emit('commentInputButtonWasClicked', this.newComment, event)
      this.newComment = ''
    },
    onInput () {
      // Émettre un événement dès que l'utilisateur commence à taper
      this.userStartedTyping = true
      this.$emit('commentInput', this.newComment.trim())
    },
    handleEnterKey (event) {
      // Gérer la pression de la touche "Enter"
      this.$emit('commentInputButtonWasClicked', this.newComment, event)
    },
    handleShiftEnterKey () {
      // Gérer la pression simultanée de la touche "Enter" et "Shift"
      this.newComment += '\n'
    }
  }
}
</script>
