<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content" :style="this.modalSize">
      <h1>{{ this.title }}</h1>
      <p v-if="this.message">{{ this.message }}</p>
      <span v-if="errorMessage" class="error-message">{{ this.errorMessage }}</span>
      <div class="content">
        <div class="selects">
          <section>
            <h1>
              <label for="change_status_radio_btn">
                <input type="radio" name="changeSelectListRadioBtn" id="change_status_radio_btn" v-model="isModifyingStatus" :value="true">
                Modifier le statut
              </label>
            </h1>
            <SelectList :name="'statusSelectList'" ref="statusSelectList"
              :options="statusSelectListOptions"
              :headerOptionLabel="'Statut de recrutement'"
              :isDisabled="!isModifyingStatus"
              @onChange="onStatusSelectListChange"/>
          </section>
          <section>
            <h1>
              <label for="change_step_radio_btn">
                <input type="radio" name="changeSelectListRadioBtn" id="change_step_radio_btn" v-model="isModifyingStatus">
                Modifier l'étape
              </label>
            </h1>
            <SelectList :name="'recruitmentStepSelectList'" ref="recruitmentStepSelectList"
              :options="recruitmentStepsSelectListOptions"
              :headerOptionLabel="'Étape de recrutement'"
              :isDisabled="isModifyingStatus"
              @onChange="onRecruitmentStepsSelectListChange"/>
          </section>
        </div>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" :class="{ disabled: !isUpdatePermitted }" @mousedown="apply">Appliquer</button>
      </div>
    </div>
  </section>
</template>

<script>
import SelectList from '@/components/_generic/SelectList'
import { mapGetters, mapActions } from 'vuex'

// TODO : modal a revoir entierement lors de la refacto des steps/status
export default {
  name: 'ModalEdit',
  components: {
    SelectList
  },
  data () {
    return {
      currentSelectedStatus: '',
      currentSelectedStep: '',
      isModifyingStatus: true
    }
  },
  props: {
    title: String,
    message: String,
    width: Number,
    height: Number
  },
  computed: {
    ...mapGetters([
      'recruitmentSteps',
      'statuses',
      'selectedJobApplicationsIds',
      'currentSelectedStatusList'
    ]),
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    },
    statusSelectListOptions () {
      const options = []
      this.statuses.forEach(status => {
        options.push({ Value: status.Id, Label: status.Label })
      })
      return options
    },
    recruitmentStepsSelectListOptions () {
      const options = []
      this.recruitmentSteps.forEach(step => {
        options.push({ Value: step.Id, Label: step.Name })
      })
      return options
    },
    validationResult () {
      // 0 = Non lu
      // 1 = Réouvert
      // 2 = Ouvert
      // 3 = Recruté
      // 4 = Non recruté
      // 5 = Redirigé
      let errorMessage = ''
      let isUpdatePermitted = false
      if (this.currentSelectedStatus !== '') {
        if (this.currentSelectedStatusList.every((x) => x === 5)) {
          errorMessage = 'Aucune candidature ne peut être traitée car toutes les candidatures sélectionnées ont été redirigées.'
        } else {
          if (this.currentSelectedStatusList.includes(5)) {
            errorMessage = 'Certaines candidatures seront ignorées car celles-ci ont été redirigées.'
          }
          if ((this.currentSelectedStatus === 1) && this.currentSelectedStatusList.every((x) => x === 1 || x === 2)) {
            errorMessage = 'Aucune candidature ne peut être réouverte car toutes les candidatures sélectionnées sont déjà ouvertes.'
          } else if ((this.currentSelectedStatus === 2) && this.currentSelectedStatusList.every((x) => x !== 0)) {
            errorMessage = 'Aucune candidature ne peut être ouverte car il n\'y a aucune candidature non-lue dans la sélection.'
          } else if ((this.currentSelectedStatus === 3 || this.currentSelectedStatus === 4) && this.currentSelectedStatusList.every((x) => x === 3 || x === 4)) {
            errorMessage = 'Aucune candidature ne peut être cloturée car toutes les candidatures sélectionnées sont déjà cloturées.'
          } else if (this.currentSelectedStatus === 1 && (this.currentSelectedStatusList.includes(1) || this.currentSelectedStatusList.includes(2))) {
            errorMessage = 'Certaines candidatures sélectionnées sont déjà ouvertes, seules les candidatures cloturées seront réouvertes.'
            isUpdatePermitted = true
          } else if ((this.currentSelectedStatus === 1) &&
            (!this.currentSelectedStatusList.every((x) => x === 3 || x === 4)) &&
            (this.currentSelectedStatusList.includes(1) ||
            this.currentSelectedStatusList.includes(2) ||
            this.currentSelectedStatusList.includes(3) ||
            this.currentSelectedStatusList.includes(4))) {
            errorMessage = 'Certaines candidatures sélectionnées sont déjà ouvertes ou cloturées, seules les candidatures non-lues seront traitées.'
            isUpdatePermitted = true
          } else if ((this.currentSelectedStatus === 3 || this.currentSelectedStatus === 4) && (this.currentSelectedStatusList.includes(3, 4))) {
            errorMessage = 'Certaines candidatures sélectionnées sont déjà cloturées, seules les candidatures ouvertes seront cloturées.'
            isUpdatePermitted = true
          } else {
            isUpdatePermitted = true
          }
        }
      }
      if (this.currentSelectedStep !== '') {
        if (this.currentSelectedStatusList.every((x) => x === 3 || x === 4)) {
          errorMessage = 'Aucune candidature ne sera traité car aucune candidature ouverte n\'est sélectionnée actuellement. Il n\'est possible de modifier l\'état de recrutement que sur des candidatures ouvertes uniquement.'
        } else {
          if (this.currentSelectedStatusList.includes(3) || this.currentSelectedStatusList.includes(4)) {
            errorMessage = 'Certaines candidatures cloturées ont été sélectionnées, celles-ci ne seront pas traitées car il n\'est possible de modifier l\'état de recrutement que sur des candidatures ouvertes uniquement.'
          }
          isUpdatePermitted = true
        }
      }
      return { isUpdatePermitted, errorMessage }
    },
    errorMessage () {
      return this.validationResult.errorMessage
    },
    isUpdatePermitted () {
      return this.validationResult.isUpdatePermitted
    }
  },
  methods: {
    ...mapActions([
      'hireSelectedApplicants',
      'rejectSelectedApplicants',
      'openSelectedJobApplications',
      'reopenSelectedJobApplications',
      'moveSelectedJobApplicationsToNextRecruitmentStep'
    ]),
    onStatusSelectListChange (data) {
      this.currentSelectedStatus = data.currentSelectedValue
    },
    onRecruitmentStepsSelectListChange (data) {
      this.currentSelectedStep = data.currentSelectedValue
    },
    apply () {
      if (this.isUpdatePermitted) {
        if (this.currentSelectedStatus !== '') {
          this.updateJobApplicationStatus(this.currentSelectedStatus)
        }
        if (this.currentSelectedStep !== '') {
          this.updateRecruitmentStep(this.currentSelectedStep)
        }
        this.close()
      }
    },
    close () {
      this.$emit('close')
      this.currentSelectedStatus = ''
      this.currentSelectedStep = ''
      this.$refs.statusSelectList.reset()
      this.$refs.recruitmentStepSelectList.reset()
      this.isModifyingStatus = true
    },
    updateJobApplicationStatus (statusNumber) {
      if (statusNumber === 2) {
        this.openSelectedJobApplications()
      } else if (statusNumber === 1) {
        this.reopenSelectedJobApplications()
      } else if (statusNumber === 3) {
        this.hireSelectedApplicants()
      } else if (statusNumber === 4) {
        this.rejectSelectedApplicants()
      }
    },
    updateRecruitmentStep (stepId) {
      const step = this.recruitmentSteps.find(rs => rs.Id === stepId)
      this.moveSelectedJobApplicationsToNextRecruitmentStep(step)
    }
  },
  watch: {
    isModifyingStatus () {
      if (this.isModifyingStatus) {
        this.$refs.recruitmentStepSelectList.reset()
        this.currentSelectedStep = ''
      } else {
        this.$refs.statusSelectList.reset()
        this.currentSelectedStatus = ''
      }
    }
  }
}
</script>
