<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content" :style="this.modalSize">
      <h1>{{ this.title }}</h1>
      <p v-if="this.message">{{ this.message }}</p>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @mousedown="confirm">Confirmer</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ModalConfirmation',
  props: {
    width: Number,
    height: Number,
    message: String,
    title: String
  },
  computed: {
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
