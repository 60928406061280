export class JobApplicationActivity {
  public Date: Date
  public Name: string
  public UserId: string
  public UserName: string

  constructor () {
    this.Date = new Date()
    this.Name = ''
    this.UserId = ''
    this.UserName = ''
  }

  initialize (jobApplicationActivityJsonResult: any) {
    this.Date = jobApplicationActivityJsonResult.date
    this.Name = jobApplicationActivityJsonResult.action
    this.UserId = jobApplicationActivityJsonResult.userId
    this.UserName = jobApplicationActivityJsonResult.userName
    return this
  }
}
