<template>
  <tr @click="redirectToJobApplication">
    <td @click.stop>
      <input type="checkbox"
        :name="'chk_' + jobApplication.Id"
        v-model="isSelected">
    </td>
    <td v-for="column in headerColumns" :key="column.Name">
      <AttachmentButton v-if="column.Name === 'attachments' && attachments && attachments.length > 0"
        :attachment="resume"
        :jobApplicationId="jobApplicationId" />
      <Rating v-else-if="column.Name === 'rating'"
        :rating="Number(jobApplication.Rating)"
        :readonly="true"
        :name="jobApplication.Id" /><!-- TODO : a voir a quoi le name sert -->
      <TalentApplyFiabilityComponent v-else-if="column.Name === 'fiability' && isTalentApplyEnabled"
        :jobApplication="jobApplication"
        @fiability-change="FiabilityChange"
        :key="fiabilityKey" />
      <ATSPostingStatus v-else-if="column.Name === 'ATSPostingStatus' && isTalentApplyEnabled"
        :jobApplication="jobApplication"
        @ats-posting-status-change="ATSPostingStatusChange"
        :key="atsPostingStatusKey" />
      <DuplicatesColumnInfos v-else-if="column.Name === 'duplicates'"
      :jobApplication="jobApplication" />
      <JobApplicationQuickView v-else-if="column.Name === 'quickview'"
        :jobApplication="jobApplication" />
      <p v-else-if="displayedJobApplication[column.Name] && displayedJobApplication[column.Name].length">{{ displayedJobApplication[column.Name] }}</p>
    </td>
  </tr>
</template>

<script>
import AttachmentButton from '@/components/global/AttachmentButton.vue'
import Rating from '@/components/global/Rating.vue'
import TalentApplyFiabilityComponent from '@/components/jobApplication/TalentApplyFiabilityComponent.vue'
import ATSPostingStatus from '@/components/jobApplication/ATSPostingStatus.vue'
import DuplicatesColumnInfos from '@/components/jobApplication/DuplicatesColumnInfos.vue'
import JobApplicationQuickView from '@/components/jobApplication/JobApplicationQuickView.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JobApplicationRow',
  props: {
    jobApplicationId: String,
    headerColumns: Array
  },
  components: {
    AttachmentButton,
    Rating,
    TalentApplyFiabilityComponent,
    ATSPostingStatus,
    DuplicatesColumnInfos,
    JobApplicationQuickView
  },
  data: function () {
    return {
      fiabilityKey: 0,
      atsPostingStatusKey: 0
    }
  },
  methods: {
    ...mapActions([
      'selectJobApplication',
      'unselectJobApplication'
    ]),
    redirectToJobApplication () {
      this.$router.push({ name: 'JobApplication', params: { id: this.jobApplication.Id } })
    },
    ATSPostingStatusChange (status) {
      this.jobApplication.TalentApply.IsSent = status
      this.fiabilityKey++
    },
    FiabilityChange (fiability) {
      this.jobApplication.TalentApply.Fiability = fiability
      this.atsPostingStatusKey++
    }
  },
  computed: {
    ...mapGetters([
      'selectedJobApplicationsIds',
      'jobApplications',
      'statuses',
      'recruitmentSteps',
      'isTalentApplyEnabled'
    ]),
    jobApplication () {
      return this.jobApplications.find(x => x.Id === this.jobApplicationId)
    },
    displayedJobApplication () {
      return {
        id: this.jobApplication.Id,
        date: this.$moment(this.jobApplication.Date).format('DD/MM/YYYY'),
        applicantName: this.jobApplication.Applicant.Fullname,
        status: this.jobApplication.Status === 1 || this.jobApplication.Status === 2 ? this.recruitmentSteps.find(s => s.Id === this.jobApplication.RecruitmentStepId).Name : this.statuses.find(s => s.Id === this.jobApplication.Status).Label,
        jobTitle: this.jobApplication.JobOffer.JobTitle,
        source: this.jobApplication.Source,
        location: this.jobApplication.JobOffer.JobLocation,
        rating: this.jobApplication.Rating,
        attachments: this.jobApplication.Attachments
      }
    },
    isSelected: {
      get () {
        return this.selectedJobApplicationsIds.includes(this.jobApplication.Id)
      },
      set (value) {
        if (value) {
          this.selectJobApplication(this.jobApplication.Id)
        } else {
          this.unselectJobApplication(this.jobApplication.Id)
        }
      }
    },
    attachments () {
      return this.jobApplication.Attachments
    },
    resume () {
      let resume = this.attachments.find(x => x.Type === 2) // Type CV
      if (resume) {
        return resume
      }
      resume = this.attachments.find(x => x.Type === 0) // Type untyped
      if (resume) {
        return resume
      }
      return this.attachments[0]
    }
  }
}
</script>
