export class ApplicantJobApplication {
  public JobApplicationId: string
  public JobApplicationDate: Date
  public JobOfferTitle: string

  constructor () {
    this.JobApplicationId = ''
    this.JobApplicationDate = new Date()
    this.JobOfferTitle = ''
  }

  initialize (applicantJobApplicationJsonResult: any) {
    this.JobApplicationId = applicantJobApplicationJsonResult.jobApplicationId
    this.JobApplicationDate = applicantJobApplicationJsonResult.jobApplicationDate
    this.JobOfferTitle = applicantJobApplicationJsonResult.jobOfferTitle
    return this
  }
}
