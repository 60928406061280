<template>
    <section id="statistics_steps" >
        <h1 class="title" tabindex="-1">Candidatures reçues par étape</h1>
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <td rowspan="2">Source</td>
                        <td colspan="2" class="align-center" v-for="(step, index) in sortedRecruitmentSteps" :key="index">{{step.Name}}</td>
                    </tr>
                      <tr>
                        <template v-for="(step, index) in sortedRecruitmentSteps">
                            <td class="align-right" :key="'quantity-' + index">Qté</td>
                            <td class="align-left" :key="'percent-' + index">%</td>
                        </template>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(source, index) in sortedSources" :key="index">
                    <td>{{source.Source}}</td>
                      <template v-for="(step, stepIndex) in sortedRecruitmentSteps">
                        <td class="align-right" :key="'count-' + stepIndex">{{source.Steps[stepIndex].Count}}</td>
                        <td class="align-left" :key="'percent-' + stepIndex">{{source.Steps[stepIndex].Percentage}}%</td>
                      </template>
                  </tr>
                </tbody>
                <tfoot>
                    <tr>
                      <td>Total</td>
                      <template v-for="(step, stepIndex) in sortedRecruitmentSteps">
                        <td class="align-right" :key="'count-' + stepIndex">{{getTotal(step.Id)}}</td>
                        <td class="align-left" :key="'percent-' + stepIndex">100%</td>
                      </template>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>
</template>
<script>
import EventBus from '@/types/EventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'StatisticsByRecruitmentSteps',
  methods: {
    getCount (source, stepId) {
      let count = this.statistics.RecruitmentSteps.find(c => c.Source === source && c.RecruitmentStepId === stepId)?.Count ?? 0
      const step = this.recruitmentSteps.find(rs => rs.Order === 0)
      if (stepId === step.Id) {
        count += this.statistics.Unread.find(c => c.Source === source)?.Count ?? 0
        count += this.statistics.Deleted.find(c => c.Source === source)?.Count ?? 0
      }
      return count
    },
    getPercentage (source, stepId) {
      return Math.round((this.getCount(source, stepId) / (this.getTotal(stepId) === 0 ? 1 : this.getTotal(stepId))) * 100)
    },
    getTotal (stepId) {
      let total = 0
      this.statistics.RecruitmentSteps.forEach(element => {
        if (element.RecruitmentStepId === stepId) {
          total += element.Count
        }
      })
      if (this.recruitmentSteps.find(rs => rs.Id === stepId)?.Order === 0) {
        this.statistics.Unread.forEach(element => {
          total += element.Count
        })
        this.statistics.Deleted.forEach(element => {
          if (element.RecruitmentStepId === null) {
            total += element.Count
          }
        })
      }

      return total
    },
    getRecruitmentStepsStatisticsData () {
      let csv = 'Source'
      this.sortedRecruitmentSteps.forEach(rs => {
        csv += (';' + rs.Name)
      })
      csv += '\n'
      this.sortedSources.forEach(s => {
        csv += s.Source + ';'
        s.Steps.forEach(step => {
          csv += step.Count + ' (' + step.Percentage + '%);'
        })
        csv += '\n'
      })
      csv += 'Total;'
      this.sortedRecruitmentSteps.forEach(s => {
        csv += this.getTotal(s.Id) + ';'
      })
      csv += '\n'
      this.$emit('gotCSVData', csv)
    }
  },
  computed: {
    ...mapGetters(['statistics', 'recruitmentSteps']),
    sortedRecruitmentSteps () {
      return this.recruitmentSteps.slice(0).sort((a, b) => a.Order - b.Order)
    },
    sortedSources () {
      const recruitmentStepsStatistics = []
      const lastStepIndex = this.recruitmentSteps.length - 1
      this.statistics.Sources.forEach(s => {
        const statisticRow = { Source: s, Steps: [] }
        this.sortedRecruitmentSteps.forEach(rs => statisticRow.Steps.push({ Count: this.getCount(s, rs.Id), Percentage: this.getPercentage(s, rs.Id) }))
        recruitmentStepsStatistics.push(statisticRow)
      })
      recruitmentStepsStatistics.sort((a, b) => b.Steps[lastStepIndex].Count - a.Steps[lastStepIndex].Count)
      return recruitmentStepsStatistics
    }
  },
  mounted () {
    EventBus.$on('getRecruitmentStepsStatisticsData', this.getRecruitmentStepsStatisticsData)
  }
}
</script>
