export class JobApplicationFilter {
    public Global: string
    public ApplicantFullname: string
    public RecruitmentStep: string
    public Source: string
    public Ids: string[]
    public FromDate: Date | null
    public ToDate: Date | null
    public JobOfferReference: string
    public JobTitle: string
    public Status: number | null
    public Tags: string[]
    public JobOfferPublicationId: string
    public JobOfferId: string
    public JobLocation: string

    constructor () {
      this.Global = ''
      this.ApplicantFullname = ''
      this.FromDate = null
      this.ToDate = null
      this.Ids = new Array<string>()
      this.JobOfferReference = ''
      this.JobTitle = ''
      this.RecruitmentStep = ''
      this.Source = ''
      this.Status = null
      this.Tags = new Array<string>()
      this.JobOfferPublicationId = ''
      this.JobOfferId = ''
      this.JobLocation = ''
    }

    public IsActive (): boolean {
      const isActive = this.Global !== '' ||
                this.ApplicantFullname !== '' ||
                this.FromDate !== null ||
                this.ToDate !== null ||
                this.Ids.length > 0 ||
                this.JobOfferReference !== '' ||
                this.JobTitle !== '' ||
                this.RecruitmentStep !== '' ||
                this.Source !== '' ||
                this.Status !== null ||
                this.Tags.length > 0 ||
                this.JobOfferPublicationId !== '' ||
                this.JobOfferId !== '' ||
                this.JobLocation !== ''
      return isActive
    };
}

// const propertiesToExclude = [ // TODO : essayer de passer les propriétés à inclure plutot qu'à exclure
//     'Activities', 'Attachments', 'Comments',
//     'Date', 'Id', 'Rating', 'Number', 'UserAccesses'
//   ]
