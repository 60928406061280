<template>
  <section id="job_application_comments_panel" class="panel">
    <div class="comments-header">
      <h1>Commentaires</h1>
      <v-icon class="expand-icon" @click="openModalComments" name="expand-alt"/>
    </div>
    <CommentsList :comments="comments"/>
    <CommentsInput ref="commentsInput" @commentInputButtonWasClicked="handleSubmitNewComment" />
    <transition name="modal-fade">
      <ModalComments v-if="isModalCommentsVisible"
      :title="'Commentaires'"
      @close="closeModalComments()"/>
    </transition>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import ModalComments from '@/components/modals/ModalComments'
import CommentsList from '@/components/jobApplication/CommentsList'
import CommentsInput from '@/components/jobApplication/CommentsInput'
export default {
  name: 'Comments',
  props: {
    comments: Array
  },
  components: {
    ModalComments,
    CommentsList,
    CommentsInput
  },
  data () {
    return {
      isModalCommentsVisible: false
    }
  },
  methods: {
    ...mapActions([
      'addComment'
    ]),
    openModalComments () {
      this.isModalCommentsVisible = true
    },
    closeModalComments () {
      this.isModalCommentsVisible = false
    },
    handleSubmitNewComment (comment, event) {
      if ((event.key === 'Enter' && !event.shiftKey) || event.type === 'click') {
        if (comment.trim()) {
          this.addComment(comment)
          // Réinitialiser la valeur de newComment dans le composant CommentsInput
          this.$refs.commentsInput.newComment = ''
        }
      }
    }
  }
}
</script>
