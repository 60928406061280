import { MessageModel } from './MessageModel'
import { Stakeholder } from './Stakeholder'

export class TamConfiguration {
    public Id: string
    public CompanyId: number
    public IsNewJobApplicationNotificationEnabled: boolean
    public IsApplicationAcknowledgmentEnabled: boolean
    public IsNegativeAnswerNotificationEnabled: boolean
    public IsPositiveAnswerNotificationEnabled: boolean
    public ApplicationAcknowledgmentMessage: MessageModel
    public NegativeAnswerNotificationMessage: MessageModel
    public PositiveAnswerNotificationMessage: MessageModel
    public CustomMessageModels: MessageModel[]
    public Stakeholders: Stakeholder[]
    public OpenApplicationEmailAddress: string
    public OpenApplicationUrl: string
    public DefaultCleaningApplications: number
    public IsErrorDuplicatesEnabled: boolean
    public IsMultiApplicationDuplicatesEnabled: boolean
    public IsTalentApplyEnabled: boolean

    constructor () {
      this.Id = ''
      this.CompanyId = 0
      this.IsNewJobApplicationNotificationEnabled = false
      this.IsApplicationAcknowledgmentEnabled = false
      this.IsNegativeAnswerNotificationEnabled = false
      this.IsPositiveAnswerNotificationEnabled = false
      this.ApplicationAcknowledgmentMessage = new MessageModel()
      this.NegativeAnswerNotificationMessage = new MessageModel()
      this.PositiveAnswerNotificationMessage = new MessageModel()
      this.CustomMessageModels = new Array<MessageModel>()
      this.Stakeholders = new Array<Stakeholder>()
      this.OpenApplicationEmailAddress = ''
      this.OpenApplicationUrl = ''
      this.DefaultCleaningApplications = 0
      this.IsErrorDuplicatesEnabled = false
      this.IsMultiApplicationDuplicatesEnabled = false
      this.IsTalentApplyEnabled = false
    }

    initialize (tamconfigurationJsonResult: any) {
      this.Id = tamconfigurationJsonResult.id
      this.CompanyId = tamconfigurationJsonResult.companyId
      this.IsNewJobApplicationNotificationEnabled = tamconfigurationJsonResult.isNewJobApplicationNotificationEnabled
      this.IsApplicationAcknowledgmentEnabled = tamconfigurationJsonResult.isApplicationAcknowledgmentEnabled
      this.IsNegativeAnswerNotificationEnabled = tamconfigurationJsonResult.isNegativeAnswerNotificationEnabled
      this.IsPositiveAnswerNotificationEnabled = tamconfigurationJsonResult.isPositiveAnswerNotificationEnabled
      this.ApplicationAcknowledgmentMessage = tamconfigurationJsonResult.applicationAcknowledgmentMessage === null ? new MessageModel() : new MessageModel().initialize(tamconfigurationJsonResult.applicationAcknowledgmentMessage)
      this.NegativeAnswerNotificationMessage = tamconfigurationJsonResult.negativeAnswerNotificationMessage === null ? new MessageModel() : new MessageModel().initialize(tamconfigurationJsonResult.negativeAnswerNotificationMessage)
      this.PositiveAnswerNotificationMessage = tamconfigurationJsonResult.positiveAnswerNotificationMessage === null ? new MessageModel() : new MessageModel().initialize(tamconfigurationJsonResult.positiveAnswerNotificationMessage)
      this.CustomMessageModels = tamconfigurationJsonResult.customMessageModels.map((c: any) => new MessageModel().initialize(c))
      this.Stakeholders = tamconfigurationJsonResult.stakeholders.map((s: any) => new Stakeholder().initialize(s))
      this.OpenApplicationEmailAddress = tamconfigurationJsonResult.openApplicationEmailAddress
      this.OpenApplicationUrl = tamconfigurationJsonResult.openApplicationUrl
      this.DefaultCleaningApplications = tamconfigurationJsonResult.defaultCleaningApplications
      this.IsErrorDuplicatesEnabled = tamconfigurationJsonResult.isErrorDuplicatesEnabled
      this.IsMultiApplicationDuplicatesEnabled = tamconfigurationJsonResult.isMultiApplicationDuplicatesEnabled
      this.IsTalentApplyEnabled = tamconfigurationJsonResult.isTalentApplyEnabled
      return this
    }
}
