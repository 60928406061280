<template>
  <section v-if="currentJobApplication.Id" id="job_application">
    <Sidebar
      :elementToPushId="'job_application_container'"
      :width="180"
      :isAlwaysDisplayed="true"
      :isCollapsedOnStart="false"
      :links="sideBarLinks"
      :startingStickyElementIds="['master_header']"
      :redirectionAfterDelete="'/job-applications'"/>
    <section id="job_application_container">
      <section>
        <Cartouche v-if="currentJobApplication" :jobApplication="currentJobApplication"
          @updateApplicantNames="updateApplicantNames"
          @updateApplicantEmail="updateApplicantEmail"
          @updateRating="updateJobApplicationRating"
          @updateStatus="updateJobApplicationStatus"/>
        <JobApplicationAttachments v-if="currentJobApplication" :attachments="currentJobApplication.Attachments" :jobApplicationId="currentJobApplication.Id"/>
      </section>
      <aside>
        <RecruitmentSteps v-if="currentJobApplication.RecruitmentStepId" :currentRecruitmentStepId="currentJobApplication.RecruitmentStepId"
          @updateRecruitmentStep='updateRecruitmentStep' />
        <Tags v-if="currentJobApplication" :tags="currentJobApplication.Tags" @addTag="addTag" @deleteTag="deleteTag"/>
        <Comments v-if="currentJobApplication" :comments="currentJobApplication.Comments"/>
        <Activities v-if="currentJobApplication" :activities="currentJobApplication.Activities"/>
        <talent-apply-card v-if="currentJobApplication && isTalentApplyEnabled" :jobApplication="currentJobApplication"/>
        <other-job-applications v-if="currentJobApplication" :jobApplication="currentJobApplication"/>
      </aside>
    </section>
  </section>
</template>

<script>
import Cartouche from '@/components/jobApplication/Cartouche.vue'
import Comments from '@/components/jobApplication/Comments.vue'
import Tags from '@/components/jobApplication/Tags.vue'
import Activities from '@/components/jobApplication/Activities.vue'
import RecruitmentSteps from '@/components/jobApplication/RecruitmentSteps.vue'
import Sidebar from '@/components/global/Sidebar'
import JobApplicationAttachments from '@/components/jobApplication/JobApplicationAttachments.vue'
import TalentApplyCard from '@/components/jobApplication/TalentApplyCard.vue'
import OtherJobApplications from '@/components/jobApplication/OtherJobApplications.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JobApplication',
  components: {
    Cartouche,
    Comments,
    Tags,
    Activities,
    RecruitmentSteps,
    JobApplicationAttachments,
    TalentApplyCard,
    Sidebar,
    OtherJobApplications
  },
  watch: {
    '$route.params.id' () {
      this.initJobApplicationVue()
    }
  },
  computed: {
    ...mapGetters([
      'currentJobApplication',
      'isRecruitmentStepLocked',
      'jobApplications',
      'isTalentApplyEnabled',
      'talentApplyConfiguration'
    ]),
    sideBarLinks () {
      const links = [
        { id: 1, iconName: 'envelope', text: 'Contacter', method: 'sendEmail' },
        { id: 2, iconName: 'trash-alt', text: 'Supprimer', method: 'delete' },
        { id: 3, iconName: 'share', text: 'Transférer', method: 'transfer' }]
      const returnBackLink = { id: 6, iconName: 'arrow-circle-left', text: 'Retour', method: 'returnBack' }

      if (this.jobApplications.length === 1) {
        links.push(returnBackLink)
        return links
      }

      const previousJobApplicationLink = { id: 4, iconName: 'arrow-left', text: 'Précédente', method: 'goToPreviousJobApplication' }
      const nextJobApplicationLink = { id: 5, iconName: 'arrow-right', text: 'Suivante', method: 'goToNextJobApplication' }

      const indexOfCurrentJobApplication = this.jobApplications.map(ja => ja.Id).indexOf(this.currentJobApplication.Id)

      if (indexOfCurrentJobApplication === 0) {
        links.push(nextJobApplicationLink)
      } else if (indexOfCurrentJobApplication === this.jobApplications.length - 1) {
        links.push(previousJobApplicationLink)
      } else if (indexOfCurrentJobApplication !== -1) {
        links.push(previousJobApplicationLink)
        links.push(nextJobApplicationLink)
      }

      links.push(returnBackLink)
      return links
    }
  },
  methods: {
    ...mapActions([
      'clearCurrentJobApplication',
      'initCurrentJobApplication',
      'updateJobApplicationRatingById',
      'addJobApplicationTag',
      'deleteJobApplicationTag',
      'moveToNextRecruitmentStep',
      'hireApplicant',
      'rejectApplicant',
      'reopenJobApplication',
      'setRecruitmentStepLock',
      'updateApplicantNames',
      'updateApplicantEmail',
      'getTalentApplyConfiguration',
      'loadJobApplications'
    ]),
    async initJobApplicationVue () {
      this.initCurrentJobApplication(this.$route.params.id)

      if (!this.talentApplyConfiguration || !this.talentApplyConfiguration.IsInit) {
        this.getTalentApplyConfiguration()
      }

      if (!this.jobApplications || this.jobApplications.length === 0) {
        await this.loadJobApplications()
      }
    },
    addTag (tag) {
      this.addJobApplicationTag({ jobApplicationId: this.currentJobApplication.Id, tag: tag })
    },
    deleteTag (tag) {
      this.deleteJobApplicationTag({ jobApplicationId: this.currentJobApplication.Id, tag: tag })
    },
    updateJobApplicationRating (rating) {
      this.updateJobApplicationRatingById({ jobApplicationId: this.currentJobApplication.Id, rating: rating })
    },
    updateJobApplicationStatus (state) {
      let isLocked
      if (state === 'pending') {
        this.reopenJobApplication(this.currentJobApplication.Id)
        isLocked = false
      } else if (state === 'recruited') {
        this.hireApplicant(this.currentJobApplication.Id)
        isLocked = true
      } else if (state === 'notRecruited') {
        this.rejectApplicant(this.currentJobApplication.Id)
        isLocked = true
      }
      this.setRecruitmentStepLock(isLocked) // TODO : déplacer la gestion du locked dans le store
    },
    updateRecruitmentStep (step) {
      if (!this.isRecruitmentStepLocked) {
        this.moveToNextRecruitmentStep({ jobApplicationId: this.currentJobApplication.Id, step })
      }
    }
  },
  created () {
    this.initJobApplicationVue()
  },
  destroyed () {
    this.clearCurrentJobApplication()
  }
}
</script>
