<template>
  <div id="other_job_applications_panel" class="panel">
    <div class="other-job-applications-header">
      <h1>Autres candidatures</h1>
    </div>
    <div v-if="otherJobApplications.length > 0" class="other-job-applications-list">
      <ul>
        <li v-for="otherJobApplication in otherJobApplications" :key="otherJobApplication.JobApplicationId">
            <router-link :to="'/job-application/' + otherJobApplication.JobApplicationId" class="job-offer-info">
              {{ otherJobApplication.JobOfferTitle }}
            </router-link>
          <div class="date-info">Le {{ otherJobApplication.JobApplicationDate | moment('LL')  }}</div>
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Aucune autre candidature</p>
    </div>
  </div>
</template>

<script>
import { JobApplication } from '@/models/JobApplication'
import { mapActions } from 'vuex'

export default {
  name: 'OtherJobApplications',
  props: {
    jobApplication: { type: JobApplication, required: true }
  },
  data () {
    return {
      otherJobApplications: []
    }
  },
  watch: {
    'jobApplication' () {
      this.initOtherJobApplications()
    }
  },
  methods: {
    ...mapActions([
      'getApplicantJobApplications'
    ]),
    initOtherJobApplications () {
      this.getApplicantJobApplications(this.jobApplication.Applicant.Email).then(data => {
        this.otherJobApplications = (data || []).filter(ja => ja.JobApplicationId !== this.jobApplication.Id)
      })
    }
  },
  created () {
    this.initOtherJobApplications()
  }
}
</script>
