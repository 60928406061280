export class JobApplicationCountBySource {
    public FromDate: Date|null
    public ToDate: Date|null
    public RecruitmentStepId: string|null
    public Status: number|null
    public Source: string
    public Count: number

    constructor () {
      this.FromDate = null
      this.ToDate = null
      this.RecruitmentStepId = null
      this.Status = null
      this.Source = ''
      this.Count = 0
    }

    initialize (data: any) {
      this.FromDate = data.fromDate
      this.ToDate = data.toDate
      this.RecruitmentStepId = data.recruitmentStepId
      this.Status = data.status
      this.Source = data.source
      this.Count = data.count
      return this
    }
}
