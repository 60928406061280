<template>
  <aside id="selected_filters_bar">
    <ul class="chips-default" v-if="activeFilters && activeFilters.length > 0">
      <li v-for="(filter, index) in activeFilters" :key="index">
        <Chip :name="filter.Name" :displayName="filter.DisplayName" :value="filter.Value" @deleteChip="removeFilter"/>
      </li>
    </ul>
  </aside>
</template>

<script>
import Chip from '@/components/global/Chip'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SelectedFilters',
  components: {
    Chip
  },
  computed: {
    ...mapGetters([
      'filters',
      'statuses',
      'recruitmentSteps'
    ]),
    activeFilters () {
      const chipFilters = []
      if (this.filters.ApplicantFullname) {
        chipFilters.push({ Name: 'ApplicantFullname', DisplayName: 'Nom', Value: this.filters.ApplicantFullname })
      }
      if (this.filters.JobTitle) {
        chipFilters.push({ Name: 'JobTitle', DisplayName: 'Poste', Value: this.filters.JobTitle })
      }
      if (this.filters.JobOfferReference) {
        chipFilters.push({ Name: 'JobOfferReference', DisplayName: 'Référence', Value: this.filters.JobOfferReference })
      }
      if (this.filters.Source) {
        chipFilters.push({ Name: 'Source', DisplayName: 'Source', Value: this.filters.Source })
      }
      if (this.filters.FromDate) {
        chipFilters.push({ Name: 'FromDate', DisplayName: 'À partir du', Value: this.$moment(this.filters.FromDate).format('LL') })
      }
      if (this.filters.ToDate) {
        chipFilters.push({ Name: 'ToDate', DisplayName: 'Jusqu\'au', Value: this.$moment(this.filters.ToDate).format('LL') })
      }
      if (this.filters.RecruitmentStep) {
        chipFilters.push({ Name: 'Step', DisplayName: 'Etape', Value: this.recruitmentSteps.find(s => s.Id === this.filters.RecruitmentStep).Name })
      }
      if (this.filters.Status !== null) {
        chipFilters.push({ Name: 'Status', DisplayName: 'Statut', Value: this.statuses.find(s => s.Id === this.filters.Status).Label })
      }
      if (this.filters.JobLocation) {
        chipFilters.push({ Name: 'JobLocation', DisplayName: 'Localisation', Value: this.filters.JobLocation })
      }
      this.filters.Tags.forEach(tag => {
        chipFilters.push({ Name: 'Tag', DisplayName: '', Value: '#' + tag }) // TODO : si displayname pas bon, remplacer par undefined
      })
      return chipFilters
    }
  },
  methods: {
    ...mapActions(['filterJobApplications']),
    removeFilter (filter) {
      const changedFilters = this.filters
      switch (filter.name) {
        case 'ApplicantFullname':
          changedFilters.ApplicantFullname = ''
          break
        case 'JobTitle':
          changedFilters.JobTitle = ''
          break
        case 'JobOfferReference':
          changedFilters.JobOfferReference = ''
          break
        case 'Source':
          changedFilters.Source = ''
          break
        case 'FromDate':
          changedFilters.FromDate = null
          break
        case 'ToDate':
          changedFilters.ToDate = null
          break
        case 'Step':
          changedFilters.RecruitmentStep = ''
          break
        case 'Status':
          changedFilters.Status = null
          break
        case 'JobLocation':
          changedFilters.JobLocation = ''
          break
        case 'Tag': {
          const index = changedFilters.Tags.indexOf(filter.value.substring(1))
          if (index > -1) {
            changedFilters.Tags.splice(index, 1)
          }
          break
        }
      }
      this.filterJobApplications(changedFilters)
    }
  }
}
</script>
