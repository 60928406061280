<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content" :style="this.modalSize">
      <h1>{{ this.title }}</h1>
      <p v-if="this.message">{{ this.message }}</p>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @mousedown="deleteJobApplications">Supprimer</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ModalDelete',
  props: {
    width: Number,
    height: Number,
    redirection: String
  },
  computed: {
    ...mapGetters([
      'selectedJobApplicationsIds'
    ]),
    message () {
      let message = ''
      if (this.selectedJobApplicationsIds.length > 1) {
        message = 'Voulez-vous vraiment supprimer ces candidatures ?' // TODO : faire des constantes dans un fichier de ressources externe et centralisé
      } else {
        message = 'Voulez-vous vraiment supprimer cette candidature ?'
      }
      return message
    },
    title () {
      let title = ''
      if (this.selectedJobApplicationsIds.length > 1) {
        title = 'Supprimer les candidatures' // TODO : faire des constantes dans un fichier de ressources externe et centralisé
      } else {
        title = 'Supprimer la candidature'
      }
      return title
    },
    modalSize () {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  },
  methods: {
    ...mapActions(['deleteSelectedJobApplications']),
    deleteJobApplications () {
      this.deleteSelectedJobApplications()
      this.close()
      if (this.redirection) {
        this.$router.push(this.redirection)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
