<template>
  <section class="modal">
    <!-- TODO : créer une modale générique qui implémente les différentes modales existantes -->
    <div class="modal-content">
      <h1>{{ this.title }}</h1>
      <div class="content">
        <div class="selects selectMessageModelList">
          <SelectList
            class="selectMessageModelList"
            v-if="!isEmptyMessageModelList"
            :name="'messageModelSelectList'"
            ref="messageModelSelectList"
            :options="messageModelOptions"
            :headerOptionLabel="'Modèles'"
            :isDisabled="isEmptyMessageModelList"
            @onChange="onMessageModelChange"
          />
        </div>
        <input v-model="messageObject" class="input-text-default" type="text" placeholder="Sujet"/>
        <p v-if="!isObjectValid" class="error-message">Le sujet est obligatoire</p>
        <jodit-editor v-model="messageContent" placeholder="Message" height= "400"/>
        <p v-if="!isMessageValid" class="error-message">Un message est obligatoire</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button
          type="button"
          class="button-default button-confirm"
          @mousedown="sendMessageToApplicant"
        >
          Envoyer
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SelectList from '@/components/_generic/SelectList'
import MessageModelService from '@/services/customerSettings.service'
import { JoditEditor } from 'jodit-vue'

export default {
  name: 'ModalContactApplicant',
  components: {
    SelectList,
    JoditEditor
  },
  data () {
    return {
      messageObject: '',
      messageContent: '',
      isObjectValid: true,
      isMessageValid: true
      // messageModels: Array // TODO: a-t-on besoin du store ?
    }
  },
  props: {
    title: String
  },
  computed: {
    ...mapGetters(['customMessageModelList']),
    messageModelOptions () {
      const options = []
      this.customMessageModelList.forEach(messageModel => {
      // this.messageModels.forEach((messageModel) => { // TODO: a-t-on besoin du store ?
        options.push({ Value: messageModel.Id, Label: messageModel.Title })
      })
      return options
    },
    isEmptyMessageModelList () {
      return this.customMessageModelList === null ||
        this.customMessageModelList === undefined
        ? false
        : this.customMessageModelList.length === 0
    }
  },
  methods: {
    ...mapActions(['contactApplicant']),

    sendMessageToApplicant () {
      if (this.messageObject && this.messageContent) {
        this.contactApplicant({
          messageObject: this.messageObject,
          messageContent: this.messageContent
        })
        this.close()
      } else {
        this.isObjectValid = this.messageObject
        this.isMessageValid = this.messageContent
      }
      // TODO : Prévoir de mettre les champs en rouge ou autre témoin visuel
    },
    close () {
      this.$emit('close')
      this.isObjectValid = true
      this.isMessageValid = true
      this.messageObject = '' // TODO : mettre un setTimeout (court) pour vider les champs ?
      this.messageContent = ''
      this.$refs.messageModelSelectList.reset()
    },
    onMessageModelChange (data) {
      this.messageObject = this.customMessageModelList.find(
        (x) => x.Id === data.currentSelectedValue
      ).Subject
      this.messageContent = this.customMessageModelList.find(
        (x) => x.Id === data.currentSelectedValue
      ).Body
    }
  },
  created () {
    MessageModelService.getCustomMessageModels()
      .then(response => {
        // this.messageModels = response // TODO: a-t-on besoin du store ?
        this.$store.commit('setCustomMessageModelList', response)
      })
      .catch(console.error)
  }
}
</script>
