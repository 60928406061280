<template>
  <section class="modal modal-add-new-attachement">
    <div class="modal-content">
      <h1>{{ this.title }}</h1>
      <div class="content">
        <DropFile @files-list-was-updated="handleAddedFiles" @max-size-error="handleMaxSizeError"/>
      </div>
      <div class="modal-buttons">
        <button type="button" class="button-default" @mousedown="close">Annuler</button>
        <button type="button" class="button-default" @mousedown="handleAddNewAttachement" :disabled="maxSizeError">Ajouter</button>
      </div>
    </div>
  </section>
</template>
<script>

import DropFile from '@/components/jobApplication/DropFile'
import { mapGetters, mapActions } from 'vuex'
import { AttachmentsInformation } from '@/models/AttachmentsInformation'

export default {
  name: 'ModalNewAttachment',
  props: {
    title: String
  },
  components: {
    DropFile
  },
  data () {
    return {
      filesToAdd: [], // Garder une trace des fichiers à ajouter
      maxSizeError: false
    }
  },
  computed: {
    ...mapGetters([
      'currentJobApplication'
    ])
  },
  methods: {
    ...mapActions([
      'addNewAttachments'
    ]),
    handleMaxSizeError (error) {
      this.maxSizeError = error
    },
    close () {
      this.$emit('close')
    },
    handleAddedFiles (files) {
      const chunkSize = 1024 * 1024 // 1 Mo
      const attachments = []

      const addFile = (index) => {
        if (index >= files.length) {
          this.filesToAdd = attachments
          return this.filesToAdd
        }

        const file = files[index]
        const attachment = new AttachmentsInformation()
        attachment.FileName = file.name
        attachments.push(attachment)

        let currentChunk = 0
        const reader = new FileReader()

        function loadNextChunk () {
          const start = currentChunk * chunkSize
          const end = Math.min(start + chunkSize, file.size)
          const blob = file.slice(start, end)
          reader.readAsDataURL(blob)
        }

        reader.onload = function () {
          attachments[index].File = (attachments[index].File || '') + reader.result.split(',')[1] // Ajouter le chunk base64 à la propriété File de l'objet attachment
          currentChunk++
          if (currentChunk < Math.ceil(file.size / chunkSize)) {
            loadNextChunk()
          } else {
            // Lorsque tout le fichier a été lu
            addFile(index + 1) // Pour passer au fichier suivant
          }
        }

        loadNextChunk()
      }

      // Commencer à ajouter des fichiers à partir de l'index 0
      return addFile(0)
    },
    handleAddNewAttachement () {
      if (!this.filesToAdd || this.filesToAdd.length <= 0) {
        this.$emit('added-attachments')
        return
      }

      this.addNewAttachments({ id: this.currentJobApplication.Id, files: this.filesToAdd })
        .then(() => {
        // Ajout réussi, émettre un événement pour indiquer la fermeture de la modal
          this.$emit('added-attachments')
        })
    },
    handleFilesUpdated (newFiles) {
      this.filesToAdd = newFiles
    }
  }
}
</script>
