import Vue from 'vue'
import Axios from 'axios'
import appSettings from '@/plugins/appSettings'
import { JobApplication } from '@/models/JobApplication'
import { JobApplicationFilter } from '@/models/JobApplicationFilter'
import { JobApplicationSort } from '@/models/JobApplicationSort'
import { JobApplicationRange } from '@/models/JobApplicationRange'
import { ApplicantJobApplication } from '@/models/ApplicantJobApplication'
import { MultiApplicationDuplicates } from '@/models/MultiApplicationDuplicates'
import { AttachmentsInformation } from '@/models/AttachmentsInformation'

Vue.use(appSettings)
const ROUTE = `${Vue.appSettings.jamApiBaseUrl}/jobapplication`
const FILTERING_PREFIX = 'filter.'
const SORTING_PREFIX = 'sort.'
const PAGING_PREFIX = 'range.'

function getQueryParams (filters: JobApplicationFilter, sorts: JobApplicationSort, range: JobApplicationRange) {
  const params = new URLSearchParams()
  const filterProperties = Object.entries(filters)
  const sortProperties = Object.entries(sorts)
  const rangeProperties = Object.entries(range)

  filterProperties.forEach(property => {
    const propertyName = property[0]
    const propertyValue = property[1]
    if (propertyValue !== undefined && propertyValue !== null && propertyValue !== '') {
      if (Array.isArray(propertyValue)) {
        propertyValue.forEach(elem => params.append(FILTERING_PREFIX + propertyName, elem))
      } else {
        params.append(FILTERING_PREFIX + propertyName, propertyValue)
      }
    }
  })

  sortProperties.forEach(property => {
    const propertyName = property[0]
    const propertyValue = property[1]
    if (propertyValue !== undefined && propertyValue !== null && propertyValue !== 0) {
      params.append(SORTING_PREFIX + propertyName, propertyValue)
    }
  })

  rangeProperties.forEach(property => {
    const propertyName = property[0]
    const propertyValue = property[1]
    if (propertyValue !== undefined && propertyValue !== null && propertyValue !== 0) {
      params.append(PAGING_PREFIX + propertyName, propertyValue)
    }
  })
  return params
}

export default {
  getAll (filters: JobApplicationFilter, sorts: JobApplicationSort, range: JobApplicationRange) {
    const params = {
      params: getQueryParams(filters, sorts, range)
    }
    return Axios.get(`${ROUTE}/getall`, params)
      .then(response => response.data.jobApplications.map((ja: any) => new JobApplication().initialize(ja)))
  },
  get (id: any) {
    return Axios.get(`${ROUTE}/get/${id}`)
      .then(response => new JobApplication().initialize(response.data.jobApplication))
  },
  addComment (id: any, comment: string) {
    const params = {
      JobApplicationId: id,
      Comment: comment
    }
    return Axios.post(`${ROUTE}/AddComment`, params)
  },
  modifyComment (id: string, comment: string, commentId: string) {
    const params = {
      JobApplicationId: id,
      Comment: comment,
      CommentId: commentId
    }
    return Axios.put(`${ROUTE}/ModifyComment`, params)
  },
  deleteComment (id: string, commentId: string) {
    const params = {
      data: {
        JobApplicationId: id,
        CommentId: commentId
      }
    }
    return Axios.delete(`${ROUTE}/DeleteComment`, params)
  },
  delete (id: string) {
    return Axios.delete(`${ROUTE}/delete/${id}`)
  },
  rate (id: any, rating: number) {
    const params = {
      Id: id,
      Rating: rating
    }
    return Axios.put(`${ROUTE}/rate`, params)
  },
  modifyApplicantFullname (id: any, fullname: string) {
    const params = {
      JobApplicationId: id,
      ApplicantFullname: fullname
    }
    return Axios.put(`${ROUTE}/modifyapplicantfullname`, params)
  },
  modifyApplicantNames (id: any, firstName: string, lastName: string) {
    const params = {
      JobApplicationId: id,
      ApplicantFirstName: firstName,
      ApplicantLastName: lastName
    }
    return Axios.put(`${ROUTE}/modifyapplicantnames`, params)
  },
  addTag (id: any, tag: string) {
    const params = {
      JobApplicationId: id,
      Tag: tag
    }
    return Axios.put(`${ROUTE}/addtag`, params)
  },
  deleteTag (id: any, tag: string) {
    const params = {
      JobApplicationId: id,
      Tag: tag
    }
    return Axios.put(`${ROUTE}/deletetag`, params)
  },
  transfer (ids: any, message: any) {
    const params = {
      Receivers: message.receivers,
      Subject: message.messageObject,
      Message: message.messageContent,
      JobApplicationIds: ids
    }
    return Axios.post(`${ROUTE}/transfer`, params)
  },
  contact (id: any, message: any) {
    const params = {
      Receiver: message.receiver,
      Subject: message.messageObject,
      Message: message.messageContent,
      JobApplicationId: id
    }
    return Axios.post(`${ROUTE}/contact`, params)
  },
  getApplicantJobApplications (email: string) {
    return Axios.get(`${ROUTE}/applicantjobapplications/${email}`)
      .then(response => response?.data?.data?.applicantJobApplications.map((aja: any) => new ApplicantJobApplication().initialize(aja)))
  },
  addTagToJobApplication (jobApplicationId: any, tag: string) {
    return Axios.put(`${ROUTE}/addtag`, { jobApplicationId, tag })
  },
  addTagsToJobApplications (jobApplicationIds: [], tags: string[]) {
    return Axios.put(`${ROUTE}/addtagstojobapplications`, { jobApplicationIds, tags })
  },
  getMultiApplicationDuplicates (jobOfferId: string, applicantEmail: string): Promise<MultiApplicationDuplicates[]> {
    const searchParams = new URLSearchParams()
    searchParams.append('jobOfferId', jobOfferId)
    searchParams.append('applicantEmail', applicantEmail)

    const params = {
      params: searchParams
    }

    return Axios.get(`${ROUTE}/getmultiapplicationduplicates`, params)
      .then(response => response?.data?.data?.multiApplicationDuplicates.map((mad: any) => new MultiApplicationDuplicates().initialize(mad)))
  },
  addToJobApplicationDuplicatesCount (jobApplicationId: string, nbToAdd: number) {
    return Axios.put(`${ROUTE}/addtojobapplicationduplicatescount/${jobApplicationId}`, { nbToAdd })
  },
  addNewAttachments (jobApplicationId: any, attachments: AttachmentsInformation[]) {
    const params = {
      JobApplicationId: jobApplicationId,
      Attachments: attachments
    }
    return Axios.post(`${ROUTE}/AddNewAttachments`, params)
  }
}
