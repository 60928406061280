<template>
  <div id="sidebar_and_modals">
    <aside class="sidebar">
      <Slider v-scroll="handleScroll" ref="slider"
        :class="{ sticky: this.isSticked }"
        :isSticked="this.isSticked"
        :elementToPushId="this.elementToPushId"
        :width="this.width"
        direction="left"
        :links="this.links"
        :isAlwaysDisplayed="this.isAlwaysDisplayed"
        :isHiddenOnStart="this.isHiddenOnStart"
        :isCollapsedOnStart="this.isCollapsedOnStart"
        @triggerMethod="this.triggerMethod"
        :style="{top: sliderTopOffset + 'px'}"/>
      </aside>
      <transition name="modal-fade">
        <ModalContactApplicant v-if="isModalEmailVisible" :title="'Contacter le candidat'" @close="closeModal('email')"/>
      </transition>
      <transition name="modal-fade">
        <ModalDelete v-show="isModalDeleteVisible"
        :redirection="redirectionAfterDelete"
        @close="closeModal('delete')" :width="400" :height="200" />
      </transition>
      <transition name="modal-fade">
        <ModalTransfer v-if="isModalTransferVisible"
        :title="'Transférer la candidature'"
        @close="closeModal('transfer')"/>
      </transition>
      <transition name="modal-fade">
        <ModalEdit v-show="isModalEditVisible"
          :title="'Modifier la candidature'"
          :message="'Veuillez sélectionner le nouveau statut ou la nouvelle étape à appliquer aux candidatures sélectionnées.'"
          @close="closeModal('edit')"
          :height="470"
          :width="480"/>
      </transition>
      <transition name="modal-fade">
        <ModalMultiJobApplicationTag v-show="isModalTagsVisible"
          :title="'Ajouter un tag à la candidature'"
          @close="closeModal('multi-job-application-tag')"
          :height="470"
          :width="480"/>
      </transition>
      <transition name="modal-fade">
        <ModalDeduplicateApplications v-if="isModalDeduplicateVisible"
        @close="closeModal('deduplicate-applications')"
        :title="'Choix de la candidature'"
        :clickedJobApplication="modalDeduplicateClickedJobApplication"/>
    </transition>
    </div>
</template>

<script>
import ModalContactApplicant from '@/components/modals/ModalContactApplicant'
import ModalDelete from '@/components/modals/ModalDelete'
import ModalTransfer from '@/components/modals/ModalTransfer'
import ModalEdit from '@/components/modals/ModalEdit'
import ModalMultiJobApplicationTag from '@/components/modals/ModalMultiJobApplicationTag'
import ModalDeduplicateApplications from '@/components/modals/ModalDeduplicateApplications'
import EventBus from '@/types/EventBus'
import Slider from '@/components/_generic/Slider'
import ScrollDirective from '@/directives/scroll.ts'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  components: {
    ModalContactApplicant,
    ModalDelete,
    ModalTransfer,
    ModalEdit,
    ModalMultiJobApplicationTag,
    ModalDeduplicateApplications,
    Slider
  },
  data () {
    return {
      isModalEmailVisible: false,
      isModalDeleteVisible: false,
      isModalTransferVisible: false,
      isModalEditVisible: false,
      isModalTagsVisible: false,
      isModalDeduplicateVisible: false,
      modalDeduplicateClickedJobApplication: null,
      isSticked: false,
      sliderTopOffset: 0
    }
  },
  props: {
    elementToPushId: String,
    links: Array,
    width: Number,
    isAlwaysDisplayed: Boolean,
    isHiddenOnStart: Boolean, // TODO : inverser le terme (isVisibleOnStart) et la logique dépendante
    isCollapsedOnStart: Boolean,
    startingStickyElementIds: Array,
    redirectionAfterDelete: String // TODO : optimiser cette fonctionnalité : préférer faire une mise à jour de la page courante, en signalant qu'elle a été supprimée, et éventuellement mettre en place une redirection auto
  },
  directives: {
    scroll: ScrollDirective
  },
  computed: {
    ...mapGetters([
      'jobApplications',
      'currentJobApplication',
      'selectedJobApplicationsIds',
      'recruitmentSteps'
    ])
  },
  methods: {
    showModal (type) {
      if (type === 'email') {
        this.isModalEmailVisible = true
      } else if (type === 'delete') {
        this.isModalDeleteVisible = true
      } else if (type === 'transfer') {
        this.isModalTransferVisible = true
      } else if (type === 'edit') {
        this.isModalEditVisible = true
      } else if (type === 'multi-job-application-tag') {
        this.isModalTagsVisible = true
      } else if (type === 'deduplicate-applications') {
        this.isModalDeduplicateVisible = true
      }
    },
    closeModal (type) {
      if (type === 'email') {
        this.isModalEmailVisible = false
      } else if (type === 'delete') {
        this.isModalDeleteVisible = false
      } else if (type === 'transfer') {
        this.isModalTransferVisible = false
      } else if (type === 'edit') {
        this.isModalEditVisible = false
      } else if (type === 'multi-job-application-tag') {
        this.isModalTagsVisible = false
      } else if (type === 'deduplicate-applications') {
        this.isModalDeduplicateVisible = false
        this.modalDeduplicateClickedJobApplication = null
      }
    },
    checkIfSticked (scrollPos) {
      if (this.startingStickyElementIds) {
        let totalOffsetHeight
        this.startingStickyElementIds.forEach(elementId => {
          const element = document.getElementById(elementId)
          if (element) {
            if (!totalOffsetHeight) totalOffsetHeight = 0
            totalOffsetHeight += element.offsetHeight
          }
        })
        this.isSticked = totalOffsetHeight + scrollPos > totalOffsetHeight

        if (this.isSticked) {
          const headerHeight = document.getElementById('master_header').offsetHeight
          const headerVisibleHeight = (headerHeight - scrollPos) < 0 ? 0 : (headerHeight - scrollPos)

          this.sliderTopOffset = totalOffsetHeight + headerVisibleHeight - headerHeight
        } else {
          this.sliderTopOffset = 0
        }
      }
    },
    handleScroll (e) {
      const scrollPosition = e.target.scrollingElement.scrollTop
      this.checkIfSticked(scrollPosition)
    },
    triggerMethod (methodName) {
      switch (methodName) {
        case 'sendEmail':
          this.showModal('email')
          break
        case 'delete':
          this.showModal('delete')
          break
        case 'transfer':
          this.showModal('transfer')
          break
        case 'edit':
          this.showModal('edit')
          break
        case 'export':
          this.export()
          break
        case 'multiJobApplicationTag':
          this.showModal('multi-job-application-tag')
          break
        case 'deduplicateApplications':
          this.showModal('deduplicate-applications')
          break
        case 'returnBack':
          this.$router.push({ name: 'Home' })
          break
        case 'goToPreviousJobApplication':
          this.goToPreviousJobApplication()
          break
        case 'goToNextJobApplication':
          this.goToNextJobApplication()
          break
      }
    },
    showDeduplicateModal (clickedJobApplication) {
      if (clickedJobApplication == null) {
        return
      }
      this.modalDeduplicateClickedJobApplication = clickedJobApplication
      this.showModal('deduplicate-applications')
    },
    delete () {
      console.log('deleted')
    },
    export () {
      let csv = 'Fullname;Email;Date;JobTitle;JobOfferReference;JobLocation;Source;Status;Step;Rating;Tags\n'

      this.selectedJobApplicationsIds.forEach(id => {
        const jobApplication = this.jobApplications.find(ja => ja.Id === id)
        csv += jobApplication.toCsv(this.recruitmentSteps)
        csv += '\n'
      })

      const fileLink = document.createElement('a')
      fileLink.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
      fileLink.target = '_blank'
      fileLink.download = 'TAMExport.csv'
      fileLink.click()
    },
    showSlider (isVisible) {
      if (isVisible) {
        this.$refs.slider.open()
      } else {
        this.$refs.slider.close()
      }
    },
    goToPreviousJobApplication () {
      if (!this.currentJobApplication) {
        return
      }

      const indexOfCurrentJobApplication = this.jobApplications.map(ja => ja.Id).indexOf(this.currentJobApplication.Id)

      if (indexOfCurrentJobApplication === -1 || indexOfCurrentJobApplication === 0) {
        return
      }

      this.$router.push({ name: 'JobApplication', params: { id: this.jobApplications[indexOfCurrentJobApplication - 1].Id } })
    },
    goToNextJobApplication () {
      if (!this.currentJobApplication) {
        return
      }

      const indexOfCurrentJobApplication = this.jobApplications.map(ja => ja.Id).indexOf(this.currentJobApplication.Id)

      if (indexOfCurrentJobApplication === -1 || indexOfCurrentJobApplication === (this.jobApplications.length - 1)) {
        return
      }

      this.$router.push({ name: 'JobApplication', params: { id: this.jobApplications[indexOfCurrentJobApplication + 1].Id } })
    }
  },
  mounted () {
    const scrollPosition = this.$el.scrollTop
    this.$nextTick(this.checkIfSticked(scrollPosition))
    EventBus.$on('showDeduplicateModal', this.showDeduplicateModal)
  }
}
</script>
