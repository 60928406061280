export default {
  inserted: function (el: any, binding: any) {
    const f = function (evt: any) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
}
