export class JobApplicationAttachment {
  public ContentType: string
  public Date: Date
  public File: File
  public FileExtension: string
  public FileName: string
  public Id: string
  public Type: number

  constructor () {
    this.ContentType = ''
    this.Date = new Date()
    this.File = new File([''], '')
    this.FileExtension = ''
    this.FileName = ''
    this.Id = ''
    this.Type = 0
  }

  initialize (jobApplicationAttachmentJsonResult: any) {
    this.ContentType = jobApplicationAttachmentJsonResult.contentType
    this.Date = jobApplicationAttachmentJsonResult.date
    this.File = jobApplicationAttachmentJsonResult.file
    this.FileExtension = jobApplicationAttachmentJsonResult.fileExtension
    this.FileName = jobApplicationAttachmentJsonResult.fileName
    this.Id = jobApplicationAttachmentJsonResult.id
    this.Type = jobApplicationAttachmentJsonResult.type
    return this
  }
}
