<template>
  <div class="filter-input">
    <select class="input-select-default" :disabled="isDisabled" :name="name" @change="onChange($event)" v-model="currentSelectedValue">
      <option value="" disabled hidden selected>{{ headerOptionLabel }}</option>
      <option v-for="option in options" :key="option.Value" :value="option.Value">{{ option.Label }}</option>
    </select>
    <button type="button" class="button-filter-small select-list-btn"><v-icon name="caret-down" /></button>
  </div>
</template>

<script>
export default {
  name: 'SelectList',
  data () {
    return {
      currentSelectedValue: ''
    }
  },
  props: {
    options: Array,
    headerOptionLabel: String,
    name: String,
    resetValueAtSelection: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onChange (event) {
      this.$emit('onChange', { currentSelectedValue: this.currentSelectedValue, event: event })
      if (this.resetValueAtSelection) {
        this.reset()
      }
    },
    reset () {
      this.currentSelectedValue = ''
    }
  }
}
</script>
