<template>
    <div>
        <Menu v-bind="this.$attrs">
          <ul>
            <li><router-link to="/job-applications"><v-icon name="address-card" /><span>Candidatures</span></router-link></li>
            <li><router-link to="/statistics"><v-icon name="chart-line"/><span>Statistiques</span></router-link></li>
            <li v-if='isAdministrator'><router-link to="/admin"><v-icon name="cog"/><span>Administration</span></router-link></li>
            <li><a :href="this.$appSettings.broadcastPlatformUrl"><v-icon name="rocket" />Multidiffusion</a></li>
          </ul>
        </Menu>
    </div>
</template>

<script>
import Menu from '@/components/_generic/Menu'
import { mapGetters } from 'vuex'
export default {
  name: 'MasterMenu',
  computed: {
    ...mapGetters(['currentUser']),
    // TODO : Créer un composant pour le profil de l'utilisateur
    isAdministrator () {
      return this.currentUser.Roles.indexOf('ClientAdmin') > -1
    }
  },
  components: {
    Menu
  }
}
</script>
