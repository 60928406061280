<template>
  <div class="floating-container" :class="{ 'hide': this.hidden }">
    <button class="floating-button"
      :style="floatingButtonStyles"
      @click="action">
      <v-icon :name="this.iconName"></v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FloatingActionButton',
  props: {
    iconName: { type: String, default: 'plus' },
    iconColor: { type: String, default: 'white' },
    bgColor: { type: String, default: '#2cb3f0' },
    hidden: { type: Boolean, default: false }
  },
  computed: {
    floatingButtonStyles () {
      return {
        'background-color': this.bgColor,
        color: this.iconColor
      }
    }
  },
  methods: {
    action () {
      this.$emit('onFloatingActionButtonClick')
    }
  }
}
</script>
