<template>
  <section>
    <ul class="comment-list" v-if="displayedComments.length > 0">
      <li v-for="comment in displayedComments" :key="comment.Id">
        <div class="comment-info">{{ comment.UserName }}, le {{ comment.Date | moment('LL') }}</div>
        <div class="comment-row">
          <div class="comment-content">{{ comment.Content }}</div>
          <div class="comment-actions" v-if="showCommentActions">
            <div class="edit-comment-icon">
              <v-icon class="iconModify" @click="openModalEditComment(comment)" name="pencil-alt" title="Edit"/>
            </div>
            <div class="delete-comment-icon">
              <v-icon class="icon" @click="openModalDeleteComment(comment)" name="trash-alt" title="Delete"/>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <p v-else>Pas de commentaire</p>
    <transition name="modal-fade">
      <ModalEditComment v-if="isModalEditVisible"
        :title="'Modifier votre commentaire'"
        :comment= "selectedComment"
        @close="closeModalEditComment()"/>
    </transition>
    <transition name="modal-fade">
      <ModalDeleteComment v-if="isModalDeleteVisible"
      :width="450"
      :height="200"
      :jobApplicationComment="selectedComment"
      @close="closeModalDeleteComment()"/>
    </transition>
  </section>
</template>

<script>
import ModalEditComment from '@/components/modals/ModalEditComment'
import ModalDeleteComment from '@/components/modals/ModalDeleteComment'
import { JobApplicationComment } from '@/models/JobApplicationComment'
export default {
  name: 'CommentsList',
  props: {
    comments: { type: Array, required: true },
    commentsToDisplay: { type: Number, default: -1 },
    showCommentActions: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ModalEditComment,
    ModalDeleteComment
  },
  data () {
    return {
      isModalEditVisible: false,
      isModalDeleteVisible: false,
      comment: JobApplicationComment,
      selectedComment: null
    }
  },
  computed: {
    displayedComments () {
      const comments = this.comments.slice()
      comments.sort(function (a, b) {
        return new Date(a.Date) - new Date(b.Date)
      })
      comments.reverse()

      if (this.commentsToDisplay === -1) {
        return comments
      }

      return comments.slice(0, this.commentsToDisplay)
    }
  },
  methods: {
    openModalEditComment (comment) {
      this.selectedComment = comment
      this.isModalEditVisible = true
    },
    closeModalEditComment () {
      this.isModalEditVisible = false
    },
    openModalDeleteComment (comment) {
      this.selectedComment = comment
      this.isModalDeleteVisible = true
    },
    closeModalDeleteComment () {
      this.isModalDeleteVisible = false
    }
  }
}
</script>
